<div class="m-header">
  <a (click)="returnToHome()" class="b-brand">
    <!-- ========   change your logo hear   ============ -->
    @if (themeMode === false) {
    <img src="assets/images/logo/thynk-colour.svg" width="150" alt="logo-img" class="logo" />
    } @else {
    <img src="assets/images/logo/thynk-colour.svg" width="150" alt="darkMode-logo" class="logo" />
    }
  </a>
  <!-- ======= Menu collapse Icon ===== -->
  <!-- <div class="pc-h-item">
    <a
      href="javascript:"
      class="pc-head-link head-link-secondary m-0 mobile-menu"
      [ngClass]="{ on: navCollapsed }"
      id="sidebar-hide mobile-collapse"
      (click)="navCollapse()"
      title="Click to Menu Collapse"
    >
      <i class="ti ti-menu-2"></i>
    </a>
  </div> -->
</div>