export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  children?: NavigationItem[];
}

export const NavigationItems: NavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Analytics',
    type: 'item',
    url: '/default',
    icon: 'ti ti-device-analytics'
  },
  {
    id: 'customer-list',
    title: 'Customer List',
    type: 'item',
    url: '/customer/list',
    icon: 'ti ti-users'
  },
  {
    id: 'customer-add',
    title: 'Create Customer',
    type: 'item',
    url: '/create/new/customer',
    icon: 'ti ti-user-plus'
  },
  {
    id: 'logs',
    title: 'Logs',
    type: 'item',
    url: '/log/list',
    icon: 'ti ti-clipboard'
  },
  {
    id: 'help-docs',
    title: 'Help Docs',
    type: 'item',
    url: '/help/document',
    icon: 'ti ti-help'
  }
];
