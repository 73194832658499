// Angular import
import { Component, OnInit, effect } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { BerryConfig } from 'src/app/app-config';
import { ThemeService } from 'src/app/theme/shared/service/theme.service';
import { first, tap } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/theme/shared/service/authentication.service';

@Component({
  selector: 'app-v2-login',
  standalone: true,
  imports: [RouterModule, SharedModule],
  templateUrl: './v2-login.component.html',
  styleUrls: ['./v2-login.component.scss']
})
export class V2LoginComponent implements OnInit {
  // public method
  usernameValue = '';
  userPassword = '';
  error = '';

  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  returnUrl!: string;
  classList!: { toggle: (arg0: string) => void };
  themeMode!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private themeService: ThemeService
  ) {
    // redirect to home if user already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/default']);
    }
    effect(() => {
      this.isDarkTheme(this.themeService.isDarkTheme());
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.themeMode = BerryConfig.isDarkMode;

    const togglePassword = document.querySelector('#togglePassword');
    const password = document.querySelector('#Password'); // Change this to match your actual input ID

    togglePassword?.addEventListener('click', () => {
      // Toggle the type attribute
      const type = password?.getAttribute('type') === 'password' ? 'text' : 'password';
      password?.setAttribute('type', type);

      // Toggle the icon class directly on `togglePassword`
      togglePassword.classList.toggle('ti-eye-off');
    });

    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  // private method
  private isDarkTheme(isDark: boolean) {
    this.themeMode = isDark;
  }

  // convenience getter for easy access to form fields
  get formValues() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.error = '';
    this.loading = true;

    this.authenticationService
      .login(this.formValues['userName'].value, this.formValues['password'].value)
      .pipe(
        first(),
        tap({
          next: (response) => {
            // console.log('Successful Response:', response); // Log success response
          },
          error: (error) => {
            console.log('Error Response:', error); // Log error response
            this.error = error;
          }
        })
      )
      .subscribe({
        next: () => {
          // Navigate to the default route upon successful login
          this.router.navigate(['/default']);
        },
        error: (error: any) => {
          if (error && error.error) {
            const errorResponse = error.error;
            // this.error = errorResponse.message || 'An unexpected error occurred. Please try again.';
          } else {
            // this.error = 'Unable to connect to the server. Please check your network connection.';
          }
          this.loading = false;
        }
      });
  }
}
