import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FetchSalesforceTokenService {
  serverUrl = environment.apiUrl;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  generateToken(authUrl: string, clientId: string, clientSecret: string, grantType: string): Observable<any> {
    const url = this.serverUrl + 'generateSalesforceToken';
    
    const body = {
      authUrl: authUrl,
      clientId: clientId,
      clientSecret: clientSecret,
      grantType: grantType
    };

    return this.http.post<any>(url, body, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    console.error('An error occurred', error);
    return throwError(error.message || error);
  }
}
