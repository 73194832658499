import { CommonModule } from '@angular/common';;
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef   } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { InputValidation } from 'app/shared/inputValidation';
import { MatSelectModule } from '@angular/material/select';
import { CapitalizePipe } from 'app/capitalize.pipe';
import { MewsCredentials } from 'app/interface/i-updateSecret-interface';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { generateToken } from 'app/shared/utility';

@Component({
  selector: 'app-app-mews-fields',
  standalone: true,
  imports: [MatButton, MatInputModule, ReactiveFormsModule, FormsModule ,CommonModule, MatIconModule, MatTooltip, MatSelectModule, AppMewsFieldsComponent, CapitalizePipe, MatFormFieldModule, MatCheckboxModule,],
   templateUrl: './app-mews-fields.component.html',
  styleUrl: './app-mews-fields.component.scss'
})
export class AppMewsFieldsComponent implements OnInit, OnChanges {

  @Input() appType: string;
  @Input() appName: string;
  @Input() appBool: string;
  @Output() credentialsChanged = new EventEmitter<MewsCredentials[]>();
  @Output() showSyncReadFieldsChange = new EventEmitter<boolean>();

  addSecretForm: FormGroup;
  mewsForm: FormGroup;
  isDisabled: boolean = true;
  inputValidation = new InputValidation();
  isAppCredentails: boolean = false;
  environments: { key: string, value: string }[];
  showSyncReadFields = false;

  constructor(private formBuilder: FormBuilder,
    public splashScreen: FuseSplashScreenService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.mewsForm = this.formBuilder.group({
      mewsCredentials: this.formBuilder.array([]),
    });
    this.addAppCredentialsField();
  }

  get mewsCredentials(): FormArray {
    return this.mewsForm.get('mewsCredentials') as FormArray;
  }

  getMewsCredentialsControls(): AbstractControl[] {
    return this.mewsCredentials.controls;
  }

  onCredentialsChanged(): void {
    this.enableDisabledFields();
    this.credentialsChanged.emit(this.mewsCredentials.value);
    this.disableWebhookSecretKeyFields();
  }

  addAppCredentialsField(): void {
    let generatedToken = generateToken(25);
    const mewsGroup = this.formBuilder.group({
      client_name: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      client_token: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      access_token: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      api_url: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      pmsDb: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      propertyId: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      objectType: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      apiSource: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      syncStatus: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      webhookSecretKey: [{ value: generatedToken, disabled: true }, InputValidation.removeTrailingSpaceValidator()],
      showSyncReadFields: [false] 
    });  

    mewsGroup.valueChanges.subscribe(() => {
      this.mewsCredentials.updateValueAndValidity();
      this.onCredentialsChanged();
    });

    this.mewsCredentials.push(mewsGroup);
    this.onCredentialsChanged();
  }


  removeAppCredentialsField(index: number): void {
    this.mewsCredentials.removeAt(index);
    this.onCredentialsChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appBool'] && changes['appBool'].currentValue !== undefined) {
      this.updateValidationBasedOnAppBool(changes['appBool'].currentValue);
      this.cdr.markForCheck();
    }
  }

  private updateValidationBasedOnAppBool(appBool: string): void {
    this.mewsCredentials.controls.forEach(control => {
        const clientNameControl = control.get('client_name');
        const clientTokenControl = control.get('client_token');
        const accessTokenControl = control.get('access_token');
        const apiUrlControl = control.get('api_url');
        const pmsDbControl = control.get('pmsDb');
        const propertyIdControl = control.get('propertyId');
        const objectTypeControl = control.get('objectType');
        const apiSourceControl = control.get('apiSource');
        const syncStatusControl = control.get('syncStatus');
        const webhookSecretKeyControl = control.get('webhookSecretKey');
        if (appBool === 'Webhook') {
            pmsDbControl.clearValidators();
            propertyIdControl.clearValidators();
            objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            if(this.showSyncReadFields) {
              clientNameControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
              clientTokenControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
              accessTokenControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
              apiUrlControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            } else {
              objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
              apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
              syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
              apiUrlControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
              webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            }
           
        } else if (appBool === 'Sync/Read') {
            objectTypeControl.clearValidators();
            apiSourceControl.clearValidators();
            syncStatusControl.clearValidators();
            webhookSecretKeyControl.clearValidators();
            clientNameControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            clientTokenControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            accessTokenControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            apiUrlControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            pmsDbControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            propertyIdControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }
        clientNameControl.updateValueAndValidity();
        clientTokenControl.updateValueAndValidity();
        accessTokenControl.updateValueAndValidity();
        apiUrlControl.updateValueAndValidity();
        pmsDbControl.updateValueAndValidity();
        propertyIdControl.updateValueAndValidity();
        objectTypeControl.updateValueAndValidity();
        apiSourceControl.updateValueAndValidity();
        syncStatusControl.updateValueAndValidity();
        webhookSecretKeyControl.updateValueAndValidity();
    });
    this.mewsCredentials.updateValueAndValidity();
  }

  toggleSyncReadFields(): void {
    this.showSyncReadFields = !this.showSyncReadFields;
    this.showSyncReadFieldsChange.emit(this.showSyncReadFields);
    this.updateValidationBasedOnAppBool(this.appBool); 
    this.cdr.markForCheck();
    if (!this.showSyncReadFields) {
      this.mewsCredentials.controls.forEach(control => {
        control.get('client_name')?.reset('');
        control.get('client_token')?.reset('');
        control.get('access_token')?.reset('');
        control.get('api_url')?.reset('');
      });
    }
    this.mewsCredentials.updateValueAndValidity();
  }

  private enableDisabledFields(): void {
    this.mewsCredentials.controls.forEach((control) => {
      const webhookSecretKeyControl = control.get('webhookSecretKey');
      if (webhookSecretKeyControl && webhookSecretKeyControl.disabled) {
        webhookSecretKeyControl.enable({ emitEvent: false });
      }
    });
  }

  private disableWebhookSecretKeyFields(): void {
    this.mewsCredentials.controls.forEach((control) => {
      const webhookSecretKeyControl = control.get('webhookSecretKey');
      if (webhookSecretKeyControl && webhookSecretKeyControl.enabled) {
        webhookSecretKeyControl.disable({ emitEvent: false });
      }
    });
  }
}