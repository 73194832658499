<div class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto addFormContainer" cdkScrollable>

  <!-- Header -->
  <div
    class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:pt-12 sm:px-10 bg-default dark:bg-transparent">
    <div class="flex-1 min-w-0">
      <!-- Breadcrumbs -->
      <div>
        <div class="hidden sm:flex flex-wrap items-center font-medium">
          <div>
            <a class="whitespace-nowrap text-primary-500">Secret Details</a>
          </div>
          <div class="flex items-center ml-1 whitespace-nowrap">
            <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
            <a class="ml-1 text-primary-500">Update</a>
          </div>
          <div class="flex items-center ml-1 whitespace-nowrap">
            <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
            <span class="ml-1 text-secondary">Secret</span>
          </div>
        </div>
      </div>
      <!-- Title -->
      <div class="mt-2">
        <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
          <mat-icon class="backButton" (click)="goBack()" svgIcon="heroicons_solid:chevron-double-left"
            matTooltip="Click to go back"></mat-icon>
          Update Secret
        </h2>
      </div>
    </div>

  </div>

  <!-- Main -->
  <div class="flex-auto p-6  rounded-t-2xl shadow bg-card">
    <div class="secretsDetailContainer">
      <div class="column text-2xl font-extrabold tracking-tight mb-4 flex justify-between">
        <div class="salesforceCred">
          Salesforce Credentials
          <mat-icon class="statusIcon ml-2"
            [ngClass]="{'text-green-500': salesforceTokenRetrieved, 'text-gray-500': !salesforceTokenRetrieved}">
            check_circle
          </mat-icon>
        </div>
        <div class="getToken">
          <button mat-flat-button [color]="'primary'" type="button" class="saleforceConnectivity"
            (click)="getSalesforceToken()" [disabled]="salesforceTokenRetrieved">Check Connectivity</button>
        </div>
      </div>
      <div class="column"></div>
      <hr>
      <form [formGroup]="updateSecretDetailsForm" (ngSubmit)="submitForm()" class="mat-table">
        <div class="column">
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Auth URL</mat-label>
            <input matInput formControlName="sfdc_auth_url" placeholder="Salesforce Auth URL"
              style="white-space: pre-wrap; overflow-x: auto;">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Object URL</mat-label>
            <input matInput formControlName="sfdc_object_url" placeholder="Salesforce Object URL">
            <mat-error *ngIf="updateSecretDetailsForm.get('sfdc_object_url').hasError('required')">Salesforce Object URL
              is
              required</mat-error>
            <mat-error *ngIf="updateSecretDetailsForm.get('sfdc_object_url').hasError('invalidUrl')">Invalid URL
              format</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Client ID</mat-label>
            <input matInput formControlName="sfdc_client_id" placeholder="Salesforce Client ID">
            <mat-error *ngIf="updateSecretDetailsForm.get('sfdc_client_id').hasError('required')">Salesforce Client ID
              is
              required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Client Secret</mat-label>
            <input matInput formControlName="sfdc_client_secret" placeholder="Salesforce Client Secret">
            <mat-error *ngIf="updateSecretDetailsForm.get('sfdc_client_secret').hasError('required')">Salesforce Client
              Secret is
              required</mat-error>
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Grant Type</mat-label>
            <input matInput formControlName="sfdc_grant_type" placeholder="Salesforce Grant Type" [readonly]="true"
              class="formInputNotAllowed">
            <mat-error *ngIf="updateSecretDetailsForm.get('sfdc_grant_type').hasError('required')">Salesforce Grant Type
              is
              required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Salesforce Token (optional)</mat-label>
            <input matInput formControlName="sfdc_token" placeholder="Salesforce Token">
          </mat-form-field>
        </div>
        <div class="column text-2xl font-extrabold tracking-tight mb-4 mt-6">App Credentials
          <div class="actionRowBtn" *ngIf="selectedType === 'Sync/Read'">
            <button class="ml-3 w-28 " mat-flat-button type="button" [color]="'primary'"
              (click)="addNewAppCredential()" [disabled]="appName === 'cvent' || appName === 'sertifi' || appName === 'stripe'">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="ml-2">Add New</span>
            </button>
          </div>
          <div *ngIf="selectedType === 'Webhook'"></div>
          <div>
            <mat-form-field appearance="fill" class="mr-2">
              <mat-select formControlName="type" (selectionChange)="onTypeChange($event.value)" placeholder="Select App Type*">
                <mat-option value="Webhook">Webhook</mat-option>
                <mat-option value="Sync/Read">Sync/Read</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="column"></div>
        <hr>
        <div formArrayName="mews">
          <div *ngFor="let mewsGroup of updateSecretDetailsForm.get('mews')?.controls; let i = index">
            <div class="column" *ngIf="appName === 'mews'">
              <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                {{ appName | capitalize }} {{ selectedType === 'Webhook' ? 'Webhook Information' : 'App Information' }}
              </div>
              <div class="actionRowBtn" *ngIf="selectedType === 'Sync/Read'">
                <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentials(i)">
                  Delete
                </button>
              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <div [formGroupName]="i">
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="objectType{{ i }}">Object Type</mat-label>
                    <input matInput id="objectType{{ i }}" formControlName="objectType" placeholder="Object Type">
                    <mat-error *ngIf="mewsGroup.get('objectType').hasError('required')">Object Type is 
                      required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="apiSource{{ i }}">API Source</mat-label>
                    <input matInput id="apiSource{{ i }}" formControlName="apiSource" placeholder="API Source">
                    <mat-error *ngIf="mewsGroup.get('apiSource')?.hasError('required')">API Source</mat-error>
                  </mat-form-field>
                </div>
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="syncStatus{{ i }}">Sync Status</mat-label>
                    <input matInput id="syncStatus{{ i }}" formControlName="syncStatus" placeholder="Webhook API Key">
                    <mat-error *ngIf="mewsGroup.get('syncStatus')?.hasError('required')">Sync Status</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="webhookSecretKey{{ i }}">Webhook API Key (Auto Generated)</mat-label>
                    <input matInput id="webhookSecretKey{{ i }}" formControlName="webhookSecretKey" placeholder="Webhook API Key" [ngStyle]="{ 'cursor': 'not-allowed' }"  [readonly]="true" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Sync/Read' || mewsGroup.get('showSyncReadFields').value">
              <div [formGroupName]="i">

                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="client_name{{ i }}">Client Name</mat-label>
                    <input matInput id="client_name{{ i }}" formControlName="client_name" placeholder="Client Name">
                    <mat-error *ngIf="mewsGroup.get('client_name')?.hasError('required')">Client Name is
                      required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="api_url{{ i }}">API URL</mat-label>
                    <input matInput id="api_url{{ i }}" formControlName="api_url" placeholder="API URL">
                    <mat-error *ngIf="mewsGroup.get('api_url')?.hasError('required')">API URL is required</mat-error>
                    <mat-error *ngIf="mewsGroup.get('api_url')?.hasError('pattern')">Invalid URL format</mat-error>
                  </mat-form-field>
                </div>
  
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="client_token{{ i }}">Client Token</mat-label>
                    <input matInput id="client_token{{ i }}" formControlName="client_token" placeholder="Client Token">
                    <mat-error *ngIf="mewsGroup.get('client_token')?.hasError('required')">Client Token is
                      required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="access_token{{ i }}">Access Token</mat-label>
                    <input matInput id="access_token{{ i }}" formControlName="access_token" placeholder="Access Token">
                    <mat-error *ngIf="mewsGroup.get('access_token')?.hasError('required')">Access Token is
                      required</mat-error>
                  </mat-form-field>
                </div>
  
                <div class="column" *ngIf="selectedType === 'Sync/Read'">
                  <mat-form-field appearance="fill" >
                    <mat-label for="pmsDb{{ i }}">PMS DB</mat-label>
                    <input matInput id="pmsDb{{ i }}" formControlName="pmsDb" placeholder="PMS DB">
                    <mat-error *ngIf="mewsGroup.get('pmsDb')?.hasError('required')">PMS DB is required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="propertyId{{ i }}">Property Id</mat-label>
                    <input matInput id="propertyId{{ i }}" formControlName="propertyId" placeholder="Property ID">
                    <mat-error *ngIf="mewsGroup.get('propertyId')?.hasError('required')">Property ID is
                      required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <mat-checkbox
              [checked]="mewsGroup.get('showSyncReadFields').value"
              (change)="toggleSyncReadFields(i, 'mews')"
          >
              Callback functionality
          </mat-checkbox>
          </div>
            <hr>
          </div>

        </div>

        <div formArrayName="stayntouch">
          <div *ngFor="let stayntouchGroup of updateSecretDetailsForm.get('stayntouch')?.controls; let i = index">
            <div class="column" *ngIf="appName === 'stayntouch'">
              <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                {{ appName | capitalize }} {{ selectedType === 'Webhook' ? 'Webhook Information' : 'App Information' }}
              </div>
              <div class="actionRowBtn" *ngIf="selectedType === 'Sync/Read'">
                <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentials(i)">
                  Delete
                </button>
              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <div [formGroupName]="i">
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="objectType{{ i }}">Object Type</mat-label>
                    <input matInput id="objectType{{ i }}" formControlName="objectType" placeholder="Object Type">
                    <mat-error *ngIf="stayntouchGroup.get('objectType').hasError('required')">Object Type is 
                      required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="apiSource{{ i }}">API Source</mat-label>
                    <input matInput id="apiSource{{ i }}" formControlName="apiSource" placeholder="API Source">
                    <mat-error *ngIf="stayntouchGroup.get('apiSource')?.hasError('required')">API Source</mat-error>
                  </mat-form-field>
                </div>
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="syncStatus{{ i }}">Sync Status</mat-label>
                    <input matInput id="syncStatus{{ i }}" formControlName="syncStatus" placeholder="Webhook API Key">
                    <mat-error *ngIf="stayntouchGroup.get('syncStatus')?.hasError('required')">Sync Status</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="webhookSecretKey{{ i }}">Webhook API Key (Auto Generated)</mat-label>
                    <input matInput id="webhookSecretKey{{ i }}" formControlName="webhookSecretKey" placeholder="Webhook API Key" [ngStyle]="{ 'cursor': 'not-allowed' }"  [readonly]="true" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Sync/Read' || stayntouchGroup.get('showSyncReadFields').value">
              <div [formGroupName]="i">
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="client_id{{ i }}">Client ID</mat-label>
                    <input matInput id="client_id{{ i }}" formControlName="client_id" placeholder="Client ID">
                    <mat-error *ngIf="stayntouchGroup.get('client_id')?.hasError('required')">Client ID is
                      required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="authUrl{{ i }}">Auth URL</mat-label>
                    <input matInput id="authUrl{{ i }}" formControlName="authUrl" placeholder="Auth URL">
                    <mat-error *ngIf="stayntouchGroup.get('authUrl')?.hasError('required')">Auth URL is
                      required</mat-error>
                  </mat-form-field>
                </div>
  
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="client_secret{{ i }}">Client Secret</mat-label>
                    <input matInput id="client_secret{{ i }}" formControlName="client_secret" placeholder="Client Secret">
                    <mat-error *ngIf="stayntouchGroup.get('client_secret')?.hasError('required')">Client Secret is
                      required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="grant_type{{ i }}">Grant Type</mat-label>
                    <input matInput id="grant_type{{ i }}" formControlName="grant_type" placeholder="Grant Type"
                      [readonly]="true" class="formInputNotAllowed">
                    <mat-error *ngIf="stayntouchGroup.get('grant_type')?.hasError('required')">Grant Type is
                      required</mat-error>
                  </mat-form-field>
                </div>
  
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="apiUrl{{ i }}">API URL</mat-label>
                    <input matInput id="apiUrl{{ i }}" formControlName="apiUrl" placeholder="API URL">
                    <mat-error *ngIf="stayntouchGroup.get('apiUrl')?.hasError('required')">API URL is required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="access_token{{ i }}">Access Token</mat-label>
                    <input matInput id="access_token{{ i }}" formControlName="access_token" placeholder="Access Token">
                    <mat-error *ngIf="stayntouchGroup.get('access_token')?.hasError('required')">Access Token is
                      required</mat-error>
                  </mat-form-field>
                </div>
                <div class="column" *ngIf="selectedType === 'Sync/Read'">
                  <mat-form-field appearance="fill">
                    <mat-label for="pmsDb{{ i }}">PMS DB</mat-label>
                    <input matInput id="pmsDb{{ i }}" formControlName="pmsDb" placeholder="PMS DB">
                    <mat-error *ngIf="stayntouchGroup.get('pmsDb')?.hasError('required')">PMS DB is required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="propertyId{{ i }}">Property ID</mat-label>
                    <input matInput id="propertyId{{ i }}" formControlName="propertyId" placeholder="Property ID">
                    <mat-error *ngIf="stayntouchGroup.get('propertyId')?.hasError('required')">Property ID is
                      required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <mat-checkbox
              [checked]="stayntouchGroup.get('showSyncReadFields').value"
              (change)="toggleSyncReadFields(i, 'stayntouch')"
          >
              Callback functionality
          </mat-checkbox>
          </div>
            
            <hr>
          </div>
        </div>

        <div formArrayName="ohip">
          <div *ngFor="let ohipGroup of updateSecretDetailsForm.get('ohip')?.controls; let i = index">
            <div class="column" *ngIf="appName === 'ohip'">
              <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                {{ appName | capitalize }} {{ selectedType === 'Webhook' ? 'Webhook Information' : 'App Information' }}
              </div>
              <div class="actionRowBtn" *ngIf="selectedType === 'Sync/Read'">
                <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentials(i)">
                  Delete
                </button>


              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <div [formGroupName]="i">
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="objectType{{ i }}">Object Type</mat-label>
                    <input matInput id="objectType{{ i }}" formControlName="objectType" placeholder="Object Type">
                    <mat-error *ngIf="ohipGroup.get('objectType').hasError('required')">Object Type is 
                      required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="apiSource{{ i }}">API Source</mat-label>
                    <input matInput id="apiSource{{ i }}" formControlName="apiSource" placeholder="API Source">
                    <mat-error *ngIf="ohipGroup.get('apiSource')?.hasError('required')">API Source</mat-error>
                  </mat-form-field>
                </div>
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="syncStatus{{ i }}">Sync Status</mat-label>
                    <input matInput id="syncStatus{{ i }}" formControlName="syncStatus" placeholder="Webhook API Key">
                    <mat-error *ngIf="ohipGroup.get('syncStatus')?.hasError('required')">Sync Status</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="webhookSecretKey{{ i }}">Webhook API Key (Auto Generated)</mat-label>
                    <input matInput id="webhookSecretKey{{ i }}" formControlName="webhookSecretKey" placeholder="Webhook API Key" [ngStyle]="{ 'cursor': 'not-allowed' }"  [readonly]="true" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Sync/Read' || ohipGroup.get('showSyncReadFields').value">
              <div [formGroupName]="i">
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="client_id{{ i }}">Client ID</mat-label>
                    <input matInput id="client_id{{ i }}" formControlName="client_id" placeholder="Client ID">
                    <mat-error *ngIf="ohipGroup.get('client_id')?.hasError('required')">Client ID is required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="client_secret{{ i }}">Client Secret</mat-label>
                    <input matInput id="client_secret{{ i }}" formControlName="client_secret" placeholder="Client Secret">
                    <mat-error *ngIf="ohipGroup.get('client_secret')?.hasError('required')">Client Secret is
                      required</mat-error>
                  </mat-form-field>
                </div>
  
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="grant_type{{ i }}">Grant Type</mat-label>
                    <input matInput id="grant_type{{ i }}" formControlName="grant_type" placeholder="Grant Type"
                      [readonly]="true" class="formInputNotAllowed">
                    <mat-error *ngIf="ohipGroup.get('grant_type')?.hasError('required')">Grant Type is
                      required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="x_app_key{{ i }}">X App Key</mat-label>
                    <input matInput id="x_app_key{{ i }}" formControlName="x_app_key" placeholder="X App key">
                    <mat-error *ngIf="ohipGroup.get('x_app_key')?.hasError('required')">X App Key is required</mat-error>
                  </mat-form-field>
                </div>
  
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="username{{ i }}">User Name</mat-label>
                    <input matInput id="username{{ i }}" formControlName="username" placeholder="User Name">
                    <mat-error *ngIf="ohipGroup.get('username')?.hasError('required')">User Name is required</mat-error>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill">
                    <mat-label for="password{{ i }}">Password</mat-label>
                    <input matInput id="password{{ i }}" formControlName="password" placeholder="Password">
                    <mat-error *ngIf="ohipGroup.get('password')?.hasError('required')">Password is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="extSystemCode{{ i }}">External System Code</mat-label>
                    <input matInput id="extSystemCode{{ i }}" formControlName="extSystemCode"
                      placeholder="External System ">
                    <mat-error *ngIf="ohipGroup.get('extSystemCode')?.hasError('required')">External System Code is
                      required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="url{{ i }}">Url</mat-label>
                    <input matInput id="url{{ i }}" formControlName="url" placeholder="Url">
                    <mat-error *ngIf="ohipGroup.get('url')?.hasError('required')">Url is required</mat-error>
                  </mat-form-field>
                 
                </div>
                <div class="column" *ngIf="selectedType === 'Sync/Read'">
                  <mat-form-field appearance="fill">
                    <mat-label for="propertyId{{ i }}">Property ID</mat-label>
                    <input matInput id="propertyId{{ i }}" formControlName="propertyId" placeholder="property ID">
                    <mat-error *ngIf="ohipGroup.get('propertyId')?.hasError('required')">Property ID is
                      required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="pmsDb{{ i }}">PMS DB</mat-label>
                    <input matInput id="pmsDb{{ i }}" formControlName="pmsDb" placeholder="PMS DB">
                    <mat-error *ngIf="ohipGroup.get('pmsDb')?.hasError('required')">PMS DB is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <mat-checkbox
                  [checked]="ohipGroup.get('showSyncReadFields').value"
                  (change)="toggleSyncReadFields(i, 'ohip')"
              >
                  Callback functionality
              </mat-checkbox>
          </div>
            <hr>
          </div>
        </div>

        <div formArrayName="hconnect">
          <div *ngFor="let hconnectGroup of updateSecretDetailsForm.get('hconnect')?.controls; let i = index">
            <div class="column" *ngIf="appName === 'hconnect'">
              <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                {{appName | capitalize }} App Information
              </div>
              <div class="actionRowBtn">
                <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentials(i)">
                  Delete
                </button>
              </div>
            </div>
            <div [formGroupName]="i">
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label for="client_id{{ i }}">Client ID</mat-label>
                  <input matInput id="client_id{{ i }}" formControlName="client_id" placeholder="Client ID">
                  <mat-error *ngIf="hconnectGroup.get('client_id')?.hasError('required')">Client ID is
                    required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label for="client_secret{{ i }}">Client Secret</mat-label>
                  <input matInput id="client_secret{{ i }}" formControlName="client_secret" placeholder="Client Secret">
                  <mat-error *ngIf="hconnectGroup.get('client_secret')?.hasError('required')">Client Secret is
                    required</mat-error>
                </mat-form-field>
              </div>

              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label for="grant_type{{ i }}">Grant Type</mat-label>
                  <input matInput id="grant_type{{ i }}" formControlName="grant_type" placeholder="Grant Type"
                    [readonly]="true" class="formInputNotAllowed">
                  <mat-error *ngIf="hconnectGroup.get('grant_type')?.hasError('required')">Grant Type is
                    required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label for="scope{{ i }}">Scope</mat-label>
                  <input matInput id="scope{{ i }}" formControlName="scope" placeholder="Scope">
                  <mat-error *ngIf="hconnectGroup.get('scope')?.hasError('required')">Scope is required</mat-error>
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label for="access_token{{ i }}">Access Token</mat-label>
                  <input matInput id="access_token{{ i }}" formControlName="access_token" placeholder="Access Token">
                  <mat-error *ngIf="hconnectGroup.get('access_token')?.hasError('required')">Access Token is
                    required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label for="authUrl{{ i }}">Auth URL</mat-label>
                  <input matInput id="authUrl{{ i }}" formControlName="authUrl" placeholder="Auth URL">
                  <mat-error *ngIf="hconnectGroup.get('authUrl')?.hasError('required')">Auth URL is required</mat-error>
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label for="pmsDb{{ i }}">PMS DB</mat-label>
                  <input matInput id="pmsDb{{ i }}" formControlName="pmsDb" placeholder="PMS DB">
                  <mat-error *ngIf="hconnectGroup.get('pmsDb').hasError('required')">PMS DB is
                    required</mat-error>
                  <mat-error
                    *ngIf="hconnectGroup.get('pmsDb').hasError('nonNumeric') && !hconnectGroup.get('pmsDb').hasError('required')">PMS
                    DB must contain only numeric values</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label for="propertyId{{ i }}">Property ID</mat-label>
                  <input matInput id="propertyId{{ i }}" formControlName="propertyId" placeholder="Property ID">
                  <mat-error *ngIf="hconnectGroup.get('propertyId')?.hasError('required')">Property ID is
                    required</mat-error>
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label for="url{{ i }}">Url</mat-label>
                  <input matInput id="url{{ i }}" formControlName="url" placeholder="url">
                  <mat-error *ngIf="hconnectGroup.get('url')?.hasError('required')">Url is required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label for="appKey{{ i }}">App Key</mat-label>
                  <input matInput id="appKey{{ i }}" formControlName="appKey" placeholder="App Key">
                  <mat-error *ngIf="hconnectGroup.get('appKey')?.hasError('required')">App Key is required</mat-error>
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label for="hotelId{{ i }}">Hotel ID</mat-label>
                  <input matInput id="hotelId{{ i }}" formControlName="hotelId" placeholder="Hotel ID">
                  <mat-error *ngIf="hconnectGroup.get('hotelId')?.hasError('required')">Hotel ID is required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label for="externalSystem{{ i }}">External System</mat-label>
                  <input matInput id="externalSystem{{ i }}" formControlName="externalSystem"
                    placeholder="External System">
                  <mat-error *ngIf="hconnectGroup.get('externalSystem')?.hasError('required')">External System is
                    required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div formArrayName="hotelkey">
          <div *ngFor="let hotelkeyGroup of updateSecretDetailsForm.get('hotelkey')?.controls; let i = index">
            <div class="column" *ngIf="appName === 'hotelkey'">
              <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                {{appName | capitalize }} App Information
              </div>
              <div class="actionRowBtn">
                <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentials(i)">
                  Delete
                </button>
              </div>
            </div>
            <div [formGroupName]="i">
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label for="hostName{{ i }}">Host Name</mat-label>
                  <input matInput id="hostName{{ i }}" formControlName="hostName" placeholder="Host Name">
                  <mat-error *ngIf="hotelkeyGroup.get('hostName')?.hasError('required')">Host Name is
                    required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label for="pmsDb{{ i }}">PMS DB</mat-label>
                  <input matInput id="pmsDb{{ i }}" formControlName="pmsDb" placeholder="PMS DB">
                  <mat-error *ngIf="hotelkeyGroup.get('pmsDb')?.hasError('required')">PMS DB is
                    required</mat-error>
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label for="propertyId{{ i }}">Property ID</mat-label>
                  <input matInput id="propertyId{{ i }}" formControlName="propertyId" placeholder="Property ID">
                  <mat-error *ngIf="hotelkeyGroup.get('propertyId')?.hasError('required')">Property ID is
                    required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label for="propertyCode{{ i }}">Property Code</mat-label>
                  <input matInput id="propertyCode{{ i }}" formControlName="propertyCode" placeholder="Property Code">
                  <mat-error *ngIf="hotelkeyGroup.get('propertyCode')?.hasError('required')">Property Code is
                    required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div formArrayName="stripe">
          <div *ngFor="let stripeGroup of updateSecretDetailsForm.get('stripe')?.controls; let i = index">
            <div class="column" *ngIf="appName === 'stripe'">
              <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                {{ appName | capitalize }} {{ selectedType === 'Webhook' ? 'Webhook Information' : 'App Information' }}
              </div>
              <div class="actionRowBtn" *ngIf="selectedType === 'noFields'">
                <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentials(i)">
                  Delete
                </button>
              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <div [formGroupName]="i">
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="objectType{{ i }}">Object Type</mat-label>
                    <input matInput id="objectType{{ i }}" formControlName="objectType" placeholder="Object Type">
                    <mat-error *ngIf="stripeGroup.get('objectType').hasError('required')">Object Type is 
                      required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="apiSource{{ i }}">API Source</mat-label>
                    <input matInput id="apiSource{{ i }}" formControlName="apiSource" placeholder="API Source">
                    <mat-error *ngIf="stripeGroup.get('apiSource')?.hasError('required')">API Source</mat-error>
                  </mat-form-field>
                </div>
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="syncStatus{{ i }}">Sync Status</mat-label>
                    <input matInput id="syncStatus{{ i }}" formControlName="syncStatus" placeholder="Webhook API Key">
                    <mat-error *ngIf="stripeGroup.get('syncStatus')?.hasError('required')">Sync Status</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="webhookSecretKey{{ i }}">Webhook API Key (Auto Generated)</mat-label>
                    <input matInput id="webhookSecretKey{{ i }}" formControlName="webhookSecretKey" placeholder="Webhook API Key" [ngStyle]="{ 'cursor': 'not-allowed' }"  [readonly]="true" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Sync/Read'">
              <h2> Fields are not available for Sync/Read</h2>
            </div>
            <hr>
          </div>
        </div>

        <div formArrayName="sertifi">
          <div *ngFor="let sertifiGroup of updateSecretDetailsForm.get('sertifi')?.controls; let i = index">
            <div class="column" *ngIf="appName === 'sertifi'">
              <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                {{ appName | capitalize }} {{ selectedType === 'Webhook' ? 'Webhook Information' : 'App Information' }}
              </div>
              <div class="actionRowBtn" *ngIf="selectedType === 'noFields'">
                <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentials(i)">
                  Delete
                </button>
              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <div [formGroupName]="i">
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="objectType{{ i }}">Object Type</mat-label>
                    <input matInput id="objectType{{ i }}" formControlName="objectType" placeholder="Object Type">
                    <mat-error *ngIf="sertifiGroup.get('objectType').hasError('required')">Object Type is 
                      required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="apiSource{{ i }}">API Source</mat-label>
                    <input matInput id="apiSource{{ i }}" formControlName="apiSource" placeholder="API Source">
                    <mat-error *ngIf="sertifiGroup.get('apiSource')?.hasError('required')">API Source</mat-error>
                  </mat-form-field>
                </div>
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="syncStatus{{ i }}">Sync Status</mat-label>
                    <input matInput id="syncStatus{{ i }}" formControlName="syncStatus" placeholder="Webhook API Key" [ngStyle]="{ 'cursor': 'not-allowed' }">
                    <mat-error *ngIf="sertifiGroup.get('syncStatus')?.hasError('required')">Sync Status</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="webhookSecretKey{{ i }}">Webhook API Key (Auto Generated)</mat-label>
                    <input matInput id="webhookSecretKey{{ i }}" formControlName="webhookSecretKey" placeholder="Webhook API Key" [ngStyle]="{ 'cursor': 'not-allowed' }"  [readonly]="true" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Sync/Read'">
              <h2> Fields are not available for Sync/Read</h2>
            </div>
            <hr>
          </div>
        </div>

        <div formArrayName="cvent">
          <div *ngFor="let cventGroup of updateSecretDetailsForm.get('cvent')?.controls; let i = index">
            <div class="column" *ngIf="appName === 'cvent'">
              <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                {{ appName | capitalize }} {{ selectedType === 'Webhook' ? 'Webhook Information' : 'App Information' }}
              </div>
              <div class="actionRowBtn" *ngIf="selectedType === 'noFields'">
                <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentials(i)" >
                  Delete
                </button>
              </div>
            </div>
            <div *ngIf="selectedType === 'Webhook'">
              <div [formGroupName]="i">
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="objectType{{ i }}">Object Type</mat-label>
                    <input matInput id="objectType{{ i }}" formControlName="objectType" placeholder="Object Type">
                    <mat-error *ngIf="cventGroup.get('objectType').hasError('required')">Object Type is 
                      required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="apiSource{{ i }}">API Source</mat-label>
                    <input matInput id="apiSource{{ i }}" formControlName="apiSource" placeholder="API Source">
                    <mat-error *ngIf="cventGroup.get('apiSource')?.hasError('required')">API Source</mat-error>
                  </mat-form-field>
                </div>
                <div class="column">
                  <mat-form-field appearance="fill">
                    <mat-label for="syncStatus{{ i }}">Sync Status</mat-label>
                    <input matInput id="syncStatus{{ i }}" formControlName="syncStatus" placeholder="Webhook API Key" [ngStyle]="{ 'cursor': 'not-allowed' }">
                    <mat-error *ngIf="cventGroup.get('syncStatus')?.hasError('required')">Sync Status</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label for="webhookSecretKey{{ i }}">Webhook API Key (Auto Generated)</mat-label>
                    <input matInput id="webhookSecretKey{{ i }}" formControlName="webhookSecretKey" placeholder="Webhook API Key" [ngStyle]="{ 'cursor': 'not-allowed' }"  [readonly]="true" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType === 'Sync/Read'">
              <h2> Fields are not available for Sync/Read</h2>
            </div>
            
            <hr>
          </div>
        </div>

    <div class="submitBtn">
      <button mat-raised-button type="submit" [disabled]="updateSecretDetailsForm.invalid">Update</button>
    </div>

    </form>

  </div>

</div>

</div>