<div class="row">
  <div class="col-xl-12 col-md-12 text-center mb-4">
    <h1>Introducing the Help documentation</h1>
    <p>Find All the Guides and resources you need to develop customer credentials and Salesforce connected app.</p>
  </div>
</div>

<div class="row">
  <div class="col-sm-6">
    <app-card cardTitle="Connect Middleware to Salesforce Environment">
      <p>
        Connect middleware with salesforce environment by creating new Customer Id and use JWT Token , API URL and X-API key.
      </p>
      <div class="col-sm-12 text-center">
        <a href="../../../../../assets/pdf/connect-middleware-salesforce.pdf" download class="btn btn-secondary">Download</a>
      </div>

    </app-card>
  </div>
  <div class="col-sm-6">
    <app-card cardTitle="How to create salesforce connected app">
      <p>
        Learn how to create connected app in salesforce and link with middleware utility.
      </p>
      <div class="col-sm-12 text-center">
        <a href="../../../../../assets/pdf/salesforce-connected-app-guide.pdf" download class="btn btn-secondary">Download</a>
      </div>
    </app-card>
  </div>
</div>