<form [formGroup]="pmsForm" (ngSubmit)="onSubmit()">
    <app-card cardTitle="ETL Credentials">
        <div *ngIf="applicationType == 'webhook'">
            The webhook functionality has not been implemented. Please select an
            application type (Sync/Read) to proceed with creating an ETL
            customer.
        </div>
        <div class="d-flex justify-content-center">
            <button
                class="btn btn-dribbble"
                style="margin-right: 10px"
                (click)="onCancel()"
            >
                Cancel
            </button>
            <button
                type="submit"
                class="btn btn-primary"
                *ngIf="applicationType != 'webhook'"
                [disabled]="pmsForm.invalid"
            >
                Submit
            </button>
        </div>
    </app-card>
</form>
