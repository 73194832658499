export class BerryConfig {
  static layout = 'horizontal'; // vertical, horizontal, compact
  static isCollapse_menu = false; // true, false
  static isDarkMode = false; // true, false;
  static sidebar_caption_hide = false; // true, false
  static theme_color = 'preset-7'; // present-1, present-2, present-3, present-4, present-5, present-6, present-7
  static font_family = 'Roboto'; // Roboto, Poppins, Inter
  static isRtl_layout = false; // true, false
  static isBox_container = false; // true, false
  static isLanding = false; // true
  static i18n = 'en'; // en, fr, ro, cn
}
