import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    Validators,
    ReactiveFormsModule,
    FormArray,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { UpdateSecretsService } from 'app/services/update-secrets.service';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APP_CONSTANTS } from 'app/constants/constants';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputValidation } from 'app/shared/inputValidation';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { CapitalizePipe } from 'app/capitalize.pipe';
import { FetchSalesforceTokenService } from 'app/services/fetch-salesforce-token.service';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { generateToken } from 'app/shared/utility';

interface SecretItem {
    secretName: string;
    organizationName: string;
    customerId: string;
    appName: string;
    environment: string;
    createdDate: string;
}

@Component({
    selector: 'app-update-secret-details',
    standalone: true,
    imports: [
        FormsModule,
        MatInputModule,
        MatButtonModule,
        HttpClientModule,
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatTooltipModule,
        MatCardModule,
        MatExpansionModule,
        CapitalizePipe,
        MatSelectModule,
        MatCheckboxModule,
        MatFormFieldModule

    ],
    templateUrl: './update-secret-details.component.html',
    styleUrl: './update-secret-details.component.scss',
})
export class UpdateSecretDetailsComponent {
    @ViewChild('tokenTextarea') tokenTextarea: ElementRef;
    inputValidation = new InputValidation();

    updateSecretDetailsForm: FormGroup;
    secretDetails: any;
    secretName: string;
    createdDate: string;
    appName: string;
    customerId: string;
    environment: string;
    salesforceTokenRetrieved: boolean = false;
    salesforceToken: string;
    selectedType: string;
    showSyncReadFields: boolean = false;

    constructor(
        private route: ActivatedRoute,
        public updateSecrets: UpdateSecretsService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        public splashScreen: FuseSplashScreenService,
        private router: Router,
        public fetchSalesforceToken: FetchSalesforceTokenService
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.secretDetails = JSON.parse(params['secretData'] || '{}');
            this.selectedType = this.secretDetails.type;
            this.secretName = params['secretName'];
            this.createdDate = params['createdDate'];
        });

        const { customerId, appName, environment } =
            this.parseSecretName(this.secretName);
        this.customerId = customerId;
        this.appName = appName;
        this.environment = environment;

        this.updateSecretDetailsForm = this.formBuilder.group({
            sfdc_auth_url: [
                this.secretDetails.sfdc_auth_url,
                [Validators.required, InputValidation.removeTrailingSpaceValidator()]
            ],
            sfdc_client_id: [
                this.secretDetails.sfdc_client_id,
                [Validators.required, InputValidation.removeTrailingSpaceValidator()]
            ],
            sfdc_client_secret: [
                this.secretDetails.sfdc_client_secret,
                [Validators.required, InputValidation.removeTrailingSpaceValidator()]
            ],
            sfdc_grant_type: [
                this.secretDetails.sfdc_grant_type,
                Validators.required,
            ],
            sfdc_object_url: [
                this.secretDetails.sfdc_object_url,
                [Validators.required, InputValidation.urlValidator(), InputValidation.removeTrailingSpaceValidator()]
            ],
            sfdc_token: [this.secretDetails.sfdc_token, [InputValidation.removeTrailingSpaceValidator()]],
            mews: this.formBuilder.array([]),
            stayntouch: this.formBuilder.array([]),
            ohip: this.formBuilder.array([]),
            hconnect: this.formBuilder.array([]),
            hotelkey: this.formBuilder.array([]),
            stripe: this.formBuilder.array([]),
            sertifi: this.formBuilder.array([]),
            cvent: this.formBuilder.array([]),
            type: this.selectedType
        });

        this.parseIncomingData(this.secretDetails);
        this.renderFormBasedOnAppName();
        this.onTypeChange(this.selectedType);
    }

    renderFormAndValuesForMews() {
        const mewsArray = this.updateSecretDetailsForm.get('mews') as FormArray;
        this.secretDetails.mews.forEach((mewsItem) => {
            
            const isFieldsPopulated = !!(mewsItem.client_name && mewsItem.api_url && mewsItem.client_token);
            const mewsGroup = this.formBuilder.group({
                client_name: [mewsItem.client_name, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                api_url: [mewsItem.api_url, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                client_token: [mewsItem.client_token, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                access_token: [mewsItem.access_token, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                pmsDb: [mewsItem.pmsDb, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                propertyId: [mewsItem.propertyId, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                objectType: [mewsItem.objectType, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                apiSource: [mewsItem.apiSource, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                syncStatus: [mewsItem.syncStatus, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                webhookSecretKey: [mewsItem.webhookSecretKey, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                showSyncReadFields: [isFieldsPopulated]
            });
            mewsArray.push(mewsGroup);
        });
    }

    renderFormAndValuesForStayNTouch() {
        const stayntouchArray = this.updateSecretDetailsForm.get('stayntouch') as FormArray;
        this.secretDetails.stayntouch.forEach((stayntouchItem) => {
            const isFieldsPopulated = !!(stayntouchItem.client_id && stayntouchItem.authUrl && stayntouchItem.client_secret);
    
            const stayntouchGroup = this.formBuilder.group({
                client_id: [stayntouchItem.client_id, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                authUrl: [stayntouchItem.authUrl, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                client_secret: [stayntouchItem.client_secret, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                grant_type: [stayntouchItem.grant_type, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                apiUrl: [stayntouchItem.apiUrl, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                access_token: [stayntouchItem.access_token, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                pmsDb: [stayntouchItem.pmsDb, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                propertyId: [stayntouchItem.propertyId, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                objectType: [stayntouchItem.objectType, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                apiSource: [stayntouchItem.apiSource, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                syncStatus: [stayntouchItem.syncStatus, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                webhookSecretKey: [stayntouchItem.webhookSecretKey, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                showSyncReadFields: [isFieldsPopulated]
            });
    
            stayntouchArray.push(stayntouchGroup);
        });
    }

    renderFormAndValuesForOhip() {
        const ohipArray = this.updateSecretDetailsForm.get('ohip') as FormArray;
        this.secretDetails.ohip.forEach((ohipItem) => {
            const isFieldsPopulated = !!(ohipItem.client_id && ohipItem.client_secret && ohipItem.x_app_key);
            const ohipGroup = this.formBuilder.group({
                client_id: [ohipItem.client_id, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                client_secret: [ohipItem.client_secret, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                x_app_key: [ohipItem.x_app_key, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                username: [ohipItem.username, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                password: [ohipItem.password, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                grant_type: [ohipItem.grant_type, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                extSystemCode: [ohipItem.extSystemCode, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                propertyId: [ohipItem.propertyId, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                url: [ohipItem.url, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                pmsDb: [ohipItem.pmsDb, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                objectType: [ohipItem.objectType, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                apiSource: [ohipItem.apiSource, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                syncStatus: [ohipItem.syncStatus, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                webhookSecretKey: [ohipItem.webhookSecretKey, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                showSyncReadFields: [isFieldsPopulated]
            });
            ohipArray.push(ohipGroup);
        });
    }

    renderFormAndValuesForHconnect() {
        const hconnectArray = this.updateSecretDetailsForm.get('hconnect') as FormArray;
        this.secretDetails.hconnect.forEach((hconnectItem) => {
            const hconnectGroup = this.formBuilder.group({
                client_id: [hconnectItem.client_id, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                client_secret: [hconnectItem.client_secret, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                scope: [hconnectItem.scope, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                grant_type: [hconnectItem.grant_type, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                access_token: [hconnectItem.access_token, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                propertyId: [hconnectItem.propertyId, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                pmsDb: [hconnectItem.pmsDb, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                url: [hconnectItem.url, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                authUrl: [hconnectItem.authUrl, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                appKey: [hconnectItem.appKey, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                hotelId: [hconnectItem.hotelId, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                externalSystem: [hconnectItem.externalSystem, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            });
            hconnectArray.push(hconnectGroup);
        });
    }

    renderFormAndValuesForStripe() {
        const stripeArray = this.updateSecretDetailsForm.get('stripe') as FormArray;
        this.secretDetails.stripe.forEach((stripeItem) => {
            const stripeGroup = this.formBuilder.group({
                objectType: [stripeItem.objectType, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                apiSource: [stripeItem.apiSource, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                syncStatus: [stripeItem.syncStatus, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                webhookSecretKey: [stripeItem.webhookSecretKey, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            });
            stripeArray.push(stripeGroup);
        });
    }

    renderFormAndValuesForHotelkey(): void {
        const hotelkeyArray = this.updateSecretDetailsForm.get('hotelkey') as FormArray;

        this.secretDetails.hotelkey.forEach((hotelkeyItem) => {
            const hotelkeyGroup = this.formBuilder.group({
                hostName: [hotelkeyItem.hostName, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                propertyCode: [hotelkeyItem.propertyCode, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                propertyId: [hotelkeyItem.propertyId, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                pmsDb: [hotelkeyItem.pmsDb, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            });

            hotelkeyArray.push(hotelkeyGroup);
        });
    }

    renderFormAndValuesForSertifi() {
        const sertifiArray = this.updateSecretDetailsForm.get('sertifi') as FormArray;
        this.secretDetails.sertifi.forEach((sertifiItem) => {
            const sertifiGroup = this.formBuilder.group({
                objectType: [sertifiItem.objectType, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                apiSource: [sertifiItem.apiSource, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                syncStatus: [sertifiItem.syncStatus, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                webhookSecretKey:  [sertifiItem.webhookSecretKey, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            });
            sertifiArray.push(sertifiGroup);
        });
    }

    renderFormAndValuesForCvent() {
        const cventArray = this.updateSecretDetailsForm.get('cvent') as FormArray;
        this.secretDetails.cvent.forEach((cventItem) => {
            const cventGroup = this.formBuilder.group({
                objectType: [cventItem.objectType, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                apiSource: [cventItem.apiSource, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                syncStatus: [cventItem.syncStatus, this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
                webhookSecretKey: [cventItem.webhookSecretKey, InputValidation.removeTrailingSpaceValidator()],
            });
            cventArray.push(cventGroup);
        });
    }

    addNewMews(): void {
        let generatedToken = generateToken(25);
        const mewsArray = this.updateSecretDetailsForm.get('mews') as FormArray;
        const newMewsGroup = this.formBuilder.group({
            
            client_name: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            api_url: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            client_token: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            access_token: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            pmsDb: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            propertyId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            objectType: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            apiSource: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            syncStatus: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            webhookSecretKey: [{ value: generatedToken, disabled: true }, InputValidation.removeTrailingSpaceValidator()],
            showSyncReadFields: [false]
        });
        mewsArray.push(newMewsGroup);
    }

    addNewStayNTouch(): void {
        let generatedToken = generateToken(25);
        const stayntouchArray = this.updateSecretDetailsForm.get('stayntouch') as FormArray;
        const newStayNTouchGroup = this.formBuilder.group({
            client_id: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            authUrl: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            client_secret: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            grant_type: ['client_credentials', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            apiUrl: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            access_token: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            pmsDb: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            propertyId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            objectType: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            apiSource: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            syncStatus: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            webhookSecretKey: [{ value: generatedToken, disabled: true }, InputValidation.removeTrailingSpaceValidator()],
            showSyncReadFields: [false]
        });
        stayntouchArray.push(newStayNTouchGroup);
    }

    addNewOhip(): void {
        let generatedToken = generateToken(25);
        const ohipArray = this.updateSecretDetailsForm.get('ohip') as FormArray;
        const newOhipGroup = this.formBuilder.group({
            client_id: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            client_secret: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            x_app_key: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            username: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            password: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            grant_type: ['password', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            extSystemCode: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            propertyId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            url: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            pmsDb: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            objectType: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            apiSource: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            syncStatus: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            webhookSecretKey: [{ value: generatedToken, disabled: true }, InputValidation.removeTrailingSpaceValidator()],
            showSyncReadFields: [false]
        });
        ohipArray.push(newOhipGroup);
    }

    addNewHconnect(): void {
        
        const hconnectArray = this.updateSecretDetailsForm.get('hconnect') as FormArray;
        const newHconnectGroup = this.formBuilder.group({
            client_id: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            client_secret: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            scope: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            grant_type: ['client_credentials', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            access_token: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            propertyId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            pmsDb: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            url: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            authUrl: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            appKey: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            hotelId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            externalSystem: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
        });
        hconnectArray.push(newHconnectGroup);
    }

    addNewHotelkey(): void {
        const hotelkeyArray = this.updateSecretDetailsForm.get('hotelkey') as FormArray;

        const newHotelkeyGroup = this.formBuilder.group({
            hostName: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            propertyCode: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            propertyId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            pmsDb: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
        });

        hotelkeyArray.push(newHotelkeyGroup);
    }

    addNewStripe(): void {
        const stripeArray = this.updateSecretDetailsForm.get('stripe') as FormArray;
        const newStripeGroup = this.formBuilder.group({
            apiUrl: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            apiKey: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            propertyId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            pmsDb: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            webhookSecretKey: ['', InputValidation.removeTrailingSpaceValidator()],
        });
        stripeArray.push(newStripeGroup);
    }

    addNewSertifi(): void {
        const sertifiArray = this.updateSecretDetailsForm.get('sertifi') as FormArray;
        const newSertifiGroup = this.formBuilder.group({
            username: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            password: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            propertyId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            pmsDb: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            webhookSecretKey: ['', InputValidation.removeTrailingSpaceValidator()],

        });
        sertifiArray.push(newSertifiGroup);
    }

    addNewCvent(): void {
        const cventArray = this.updateSecretDetailsForm.get('cvent') as FormArray;
        const newCventGroup = this.formBuilder.group({
            propertyId: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            pmsDb: ['', this.selectedType === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
            webhookSecretKey: ['', InputValidation.removeTrailingSpaceValidator()],

        });
        cventArray.push(newCventGroup);
    }

    addNewAppCredential(): void {
        const formArrayName = this.getFormArrayNameForApp();
        const fieldNames = this.getFieldNamesForApp();
    
        const formArray = this.updateSecretDetailsForm.get(formArrayName) as FormArray;
        
        if (this.appName === 'mews') {
            this.addNewMews();
            this.updateValidationBasedOnType(formArray, fieldNames);
        } else if (this.appName === 'stayntouch') {
            this.addNewStayNTouch();
            this.updateValidationBasedOnType(formArray, fieldNames);
        } else if (this.appName === 'ohip') {
            this.addNewOhip();
            this.updateValidationBasedOnType(formArray, fieldNames);
        } else if (this.appName === 'hconnect') {
            this.addNewHconnect();
        } else if (this.appName === 'hotelkey') {
            this.addNewHotelkey()
        } else if (this.appName === 'stripe') {
            // this.addNewStripe();
            this.updateValidationBasedOnType(formArray, fieldNames);
        } else if (this.appName === 'sertifi') {
            // this.addNewSertifi();
            this.updateValidationBasedOnType(formArray, fieldNames);
        } else if (this.appName === 'cvent') {
            // this.addNewCvent();
            this.updateValidationBasedOnType(formArray, fieldNames);
        }
    }

    private getFieldNamesForApp(): string[] {
        switch (this.appName) {
            case 'mews':
                return ['client_name', 'api_url', 'client_token', 'access_token', 'pmsDb', 'propertyId', 'objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'];
            case 'stayntouch':
                return ['client_id', 'authUrl', 'client_secret', 'grant_type', 'apiUrl', 'access_token', 'pmsDb', 'propertyId', 'objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'];
            case 'ohip':
                return ['client_id', 'client_secret', 'x_app_key', 'username', 'password', 'grant_type', 'extSystemCode', 'propertyId', 'url', 'pmsDb', 'objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'];
            case 'stripe':
                return ['objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'];
            case 'sertifi':
                return ['objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'];
            case 'cvent':
                return ['objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'];
            default:
                return [];
        }
    }

    private getFormArrayNameForApp(): string {
        switch (this.appName) {
            case 'mews': return 'mews';
            case 'stayntouch': return 'stayntouch';
            case 'ohip': return 'ohip';
            case 'stripe': return 'stripe';
            case 'sertifi': return 'sertifi';
            case 'cvent': return 'cvent';
            default: return '';
        }
    }

    removeAppCredentials(index: number) {
        if (this.appName === 'mews') {
            (this.updateSecretDetailsForm.get('mews') as FormArray).removeAt(index);
        } else if (this.appName === 'stayntouch') {
            (this.updateSecretDetailsForm.get('stayntouch') as FormArray).removeAt(index);
        } else if (this.appName === 'ohip') {
            (this.updateSecretDetailsForm.get('ohip') as FormArray).removeAt(index);
        } else if (this.appName === 'hconnect') {
            (this.updateSecretDetailsForm.get('hconnect') as FormArray).removeAt(index);
        } else if (this.appName === 'hotelkey') {
            (this.updateSecretDetailsForm.get('hotelkey') as FormArray).removeAt(index);
        } else if (this.appName === 'stripe') {
            (this.updateSecretDetailsForm.get('stripe') as FormArray).removeAt(index);
        } else if (this.appName === 'sertifi') {
            (this.updateSecretDetailsForm.get('sertifi') as FormArray).removeAt(index);
        } else if (this.appName === 'cvent') {
            (this.updateSecretDetailsForm.get('cvent') as FormArray).removeAt(index);
        }
    }

    parseIncomingData(data) {
        if (data && data.mews) {
            this.secretDetails.mews = JSON.parse(data.mews);
        }

        if (data && data.stayntouch) {
            this.secretDetails.stayntouch = JSON.parse(data.stayntouch);
        }

        if (data && data.ohip) {
            this.secretDetails.ohip = JSON.parse(data.ohip);
        }

        if (data && data.hconnect) {
            this.secretDetails.hconnect = JSON.parse(data.hconnect);
        }

        if (data && data.hotelkey) {
            this.secretDetails.hotelkey = JSON.parse(data.hotelkey);
            this.secretDetails.type = data.type
        }
        if (data && data.stripe) {
            this.secretDetails.stripe = JSON.parse(data.stripe);
        }
        if (data && data.sertifi) {
            this.secretDetails.sertifi = JSON.parse(data.sertifi);
        }
        if (data && data.cvent) {
            this.secretDetails.cvent = JSON.parse(data.cvent);
        }
    }

    parseSecretName(secretName: string) {

        const parts = secretName.split('_');

        if (parts.length < 4) {
            console.error('Unexpected secret name format:', secretName);
            return {
                secretName: secretName,
                organizationName: '',
                customerId: '',
                appName: '',
                environment: '',
            };
        }

        const organizationName = parts[0];
        const customerId = parts.slice(1, -2).join('_');
        const appName = parts[parts.length - 2];
        const environment = parts[parts.length - 1];

        return {
            secretName: secretName,
            organizationName: organizationName,
            customerId: customerId,
            appName: appName,
            environment: environment,
        };
    }

    renderFormBasedOnAppName(): void {
        if (this.appName === 'mews') {
            this.renderFormAndValuesForMews();
        }

        if (this.appName === 'stayntouch') {
            this.renderFormAndValuesForStayNTouch();
        }

        if (this.appName === 'ohip') {
            this.renderFormAndValuesForOhip();
        }

        if (this.appName === 'hconnect') {
            this.renderFormAndValuesForHconnect();
        }
        if (this.appName === 'hotelkey') {
            this.renderFormAndValuesForHotelkey();
        }
        if (this.appName === 'stripe') {
            this.renderFormAndValuesForStripe();
        }
        if (this.appName === 'sertifi') {
            this.renderFormAndValuesForSertifi();
        }
        if (this.appName === 'cvent') {
            this.renderFormAndValuesForCvent();
        }
    }

    submitForm() {
        if (this.updateSecretDetailsForm.valid) {
            try {
                this.splashScreen.show();
                const formData = this.updateSecretDetailsForm.value;
                const mappedFormData = this.updateSecrets.mapFormDataToBody(formData, this.secretName);

                this.updateSecrets.updateSecretInformation(mappedFormData)
                    .subscribe(
                        (response) => {
                            this.splashScreen.hide();
                            this.openSnackBar('Secret information updated successfully');
                            this.showSecretDetails(response);
                        },
                        (error) => {
                            this.splashScreen.hide();
                            console.error('Failed to update secret information:', error);
                            this.openSnackBar('Failed to update secret information');
                        }
                    );
            } catch (error) {
                this.splashScreen.hide();
                console.error('An error occurred while updating secret information:', error);
                this.openSnackBar('An error occurred while updating secret information');
            }
        } else {
            this.splashScreen.hide();
            console.error('Form is invalid. Please fill all required fields.');
            this.openSnackBar('Form is invalid. Please fill all required fields.');
        }
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, APP_CONSTANTS.MAT_SNACKBAR_CLOSE, {
            duration: APP_CONSTANTS.MAT_SNACKBAR_DURATION,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    showSecretDetails(secret: SecretItem): void {
        this.router.navigate(['/secretsDetails', { secretName: this.secretName, createdDate: this.createdDate }]);
    }

    goBack() {
        this.showSecretDetails(this.secretDetails);
    }

    getSalesforceToken(): void {
        try {
            const authUrl = this.updateSecretDetailsForm.get('sfdc_auth_url').value;
            const clientId = this.updateSecretDetailsForm.get('sfdc_client_id').value;
            const clientSecret = this.updateSecretDetailsForm.get('sfdc_client_secret').value;
            const grantType = this.updateSecretDetailsForm.get('sfdc_grant_type').value;

            this.fetchSalesforceToken.generateToken(authUrl, clientId, clientSecret, grantType).subscribe(
                response => {
                    if (response && response.body && response.body.length > 0) {
                        this.salesforceToken = response.body[0];
                        this.updateSecretDetailsForm.get('sfdc_token').setValue(this.salesforceToken);
                        this.salesforceTokenRetrieved = true;
                    } else {
                        console.error('Failed to fetch Salesforce token: Invalid response body');
                        this.openSnackBar('Salesforce credentials are not valid');
                        this.salesforceTokenRetrieved = false;
                    }
                },
                (error: HttpErrorResponse) => {
                    console.error('Failed to fetch Salesforce token:', error);
                    this.openSnackBar('Salesforce credentials are not valid');
                    this.salesforceTokenRetrieved = false;
                }
            );
        } catch (error) {
            console.error('An error occurred while fetching Salesforce token:', error);
            this.salesforceTokenRetrieved = false;
        }
    }

    private updateValidationBasedOnType(formArray: FormArray, fieldNames: string[]): void {
        formArray.controls.forEach(control => {
            const isWebhook = this.selectedType === 'Webhook';
            const isSyncRead = this.selectedType === 'Sync/Read';
            const isCheckboxChecked = control.get('showSyncReadFields')?.value;
    
            fieldNames.forEach(fieldName => {
                const fieldControl = control.get(fieldName);
    
                if (!isCheckboxChecked && control.pristine && !['grant_type', 'objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'].includes(fieldName)) {
                    fieldControl?.setValue('');
                }
                if (isWebhook) {
                    if (['objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'].includes(fieldName)) {
                        fieldControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
                    } else if (isCheckboxChecked && !['pmsDb', 'propertyId'].includes(fieldName)) {
                        fieldControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
                    } else {
                        fieldControl?.clearValidators();
                    }
                } else if (isSyncRead) {
                    if (!['objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'].includes(fieldName)) {
                        fieldControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
                    } else if (this.appName === 'cvent' || this.appName === 'sertifi' || this.appName === 'stripe') {
                        if (['objectType'].includes(fieldName)) {
                            fieldControl?.setValue('');
                        }
                    } else {
                        fieldControl?.clearValidators();
                    } 
                   
                }  else {
                    fieldControl?.clearValidators();
                } 
    
                fieldControl?.updateValueAndValidity();
            });
        });
    }
    
    onTypeChange(selectedValue: string): void {
        this.selectedType = selectedValue;
    
        const arraysToUpdate = [
            { arrayName: 'mews', fields: ['client_name', 'api_url', 'client_token', 'access_token', 'pmsDb', 'propertyId', 'objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'] },
            { arrayName: 'stayntouch', fields: ['client_id', 'authUrl', 'client_secret', 'grant_type', 'apiUrl', 'access_token', 'pmsDb', 'propertyId', 'objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'] },
            { arrayName: 'ohip', fields: ['client_id', 'client_secret', 'x_app_key', 'username', 'password', 'grant_type', 'extSystemCode', 'propertyId', 'url', 'pmsDb', 'objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'] },
            { arrayName: 'stripe', fields: ['objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'] },
            { arrayName: 'sertifi', fields: ['objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'] },
            { arrayName: 'cvent', fields: ['objectType', 'apiSource', 'syncStatus', 'webhookSecretKey'] },
        ];
        arraysToUpdate.forEach(item => {
            const formArray = this.updateSecretDetailsForm.get(item.arrayName) as FormArray;
            this.updateValidationBasedOnType(formArray, item.fields);
        });
    
        this.updateSecretDetailsForm.updateValueAndValidity();
    }
    
    toggleSyncReadFields(groupIndex: number, formArrayName: string): void {
        const formArray = this.updateSecretDetailsForm.get(formArrayName) as FormArray;
        const group = formArray.at(groupIndex) as FormGroup;
        const currentValue = group.get('showSyncReadFields').value;
        group.get('showSyncReadFields').setValue(!currentValue);
        this.updateValidationBasedOnType(formArray, Object.keys(group.controls));
    }
}
