import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpRoutingModule } from './help-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, HelpRoutingModule]
})
export class HelpModule {}
