<div *ngIf="appType === 'hotelkey'" class='addFormContainer'>
    <div class="column text-2xl font-extrabold tracking-tight mt-6">
        <div class="appSelection">
            <div class="colHeading  mb-4">
                App Credentials
            </div>
        </div>
        <div class="actionRowBtn">
            <button (click)="addAppCredentialsField()" class="ml-3 w-28 " mat-flat-button type="button"
                [color]="'primary'">
                <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2">Add New</span>
            </button>
        </div>

    </div>

    <hr>

    <div [formGroup]="hotelkeyForm" class='addFormContainer'>
        <div formArrayName="hotelkeyCredentials">
            <div *ngFor="let credentialsGroup of getHotelkeyCredentialsControls(); let i = index" [formGroupName]="i">
                <div class="column">
                    <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                        {{appType | capitalize }} App Information
                    </div>
                    <div class="actionRowBtn">
                        <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentialsField(i)">
                            Delete
                        </button>
                    </div>
                </div>
                <div class="column">
                    <mat-form-field appearance="fill">
                        <mat-label>Host Name</mat-label>
                        <input matInput formControlName="hostName" placeholder="Host Name" >

                        <mat-error *ngIf="credentialsGroup.get('hostName').hasError('required')">Host Name is
                            required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>PMS DB</mat-label>
                        <input matInput formControlName="pmsDb" placeholder="PMS DB" >

                        <mat-error *ngIf="credentialsGroup.get('pmsDb').hasError('required')">PMS DB is
                            required</mat-error>
                    </mat-form-field>
                </div>
                <div class="column">
                    <mat-form-field appearance="fill">
                        <mat-label>Property ID</mat-label>
                        <input matInput formControlName="propertyId" placeholder="Property ID" >

                        <mat-error *ngIf="credentialsGroup.get('propertyId').hasError('required')">Property ID is
                            required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Property Code</mat-label>
                        <input matInput formControlName="propertyCode" placeholder="Property Code" >

                        <mat-error *ngIf="credentialsGroup.get('propertyCode').hasError('required')">Property Code is
                            required</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>