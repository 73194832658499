import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../../../../src/app/theme/shared/shared.module';
import { generateToken } from '../../../../../../src/app/theme/shared/utility';
import { getAppName, getProcessedEnvironments } from '../../../../../../src/app/theme/shared/utility';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-secret-form',
  templateUrl: './secret-form.component.html',
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule]
})
export class SecretFormComponent {
  @Input() secretForm!: FormGroup;
  @Output() secretFormObject = new EventEmitter<Object>();
  @Input() editMode: boolean = false;
  @Input() initialData: any;

  environments!: { key: string; value: string }[];
  appName: { key: string; value: string }[] | undefined;
  applicationType: string | undefined;
  isSecretKeyGenerated = false;
  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {
    this.environments = getProcessedEnvironments();
    this.appName = getAppName();

    if (this.editMode && this.initialData) {
      this.populateForm(this.initialData);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editMode'] && changes['editMode'].currentValue && this.initialData) {
      this.populateForm(this.initialData);
    }
  }

  populateForm(data: any): void {
    console.log(data);
    this.secretForm.patchValue({
      customerId: data[0] || '',
      appName: data[1] || '',
      appType: data.applicationType.toString().toLowerCase() || '',
      environment: data[2] || '',
      secretName: data.secretName || ''
    });
    this.generateSecret();
  }

  generateSecret(): void {
    const customerId = this.secretForm.get('customerId')?.value || '';
    const appName = this.secretForm.get('appName')?.value || '';
    const environment = this.secretForm.get('environment')?.value || '';
    this.applicationType = this.secretForm.get('appType')?.value || '';

    if (customerId && appName && environment && this.applicationType) {
      const secretName = `thynk_${customerId}_${appName}_${environment}`;
      this.secretForm.get('secretName')?.setValue(secretName);
    }

    // Check if secretName is not null or empty
    const secretNameValue = this.secretForm.get('secretName')?.value;
    this.isSecretKeyGenerated = secretNameValue != null && secretNameValue.trim() !== '';

    // Emit only if isSecretKeyGenerated is true
    if (this.isSecretKeyGenerated) {
      const secretObjects = {
        customerId: customerId,
        appName: appName,
        applicationType: this.applicationType,
        environment: environment,
        secretName: secretNameValue,
        isSecretKeyGenerated: this.isSecretKeyGenerated,
        editMode: this.editMode
      };
      this.toastr.success('Secret Key Generated Successfully', 'Success');
      this.secretFormObject.emit(secretObjects);
    }
  }
}
