import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AddSecretsService } from 'app/services/add-secrets.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APP_CONSTANTS } from 'app/constants/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { InputValidation } from 'app/shared/inputValidation';
import { MatSelectModule } from '@angular/material/select';
import { generateToken, getProcessedEnvironments } from 'app/shared/utility';
import { AppStayntouchFieldsComponent } from './app-stayntouch-fields/app-stayntouch-fields.component';
import { AppOhipFieldsComponent } from './app-ohip-fields/app-ohip-fields.component';
import { AppHconnectFieldsComponent } from './app-hconnect-fields/app-hconnect-fields.component';
import { AppHotelkeyFieldsComponent } from './app-hotelkey-fields/app-hotelkey-fields.component';
import { AddSecretManager, MewsCredentials, OhipCredentials, StayNTouchCredentials, hconnectCredentials, hotelkeyCredentials, stripeCredentials, sertifiCredentials, cventCredentials } from 'app/interface/i-addSecret-interface';
import { CapitalizePipe } from 'app/capitalize.pipe';
import { FetchSalesforceTokenService } from 'app/services/fetch-salesforce-token.service';
import { response } from 'express';
import { AppStripeFieldsComponent } from './app-stripe-fields/app-stripe-fields.component';
import { AppSertifiFieldsComponent } from './app-sertifi-fields/app-sertifi-fields.component';
import { AppMewsFieldsComponent } from './app-mews-fields/app-mews-fields.component';
import { AppCventFieldsComponent } from './app-cvent-fields/app-cvent-fields.component';


@Component({
  selector: 'app-add-secrets',
  standalone: true,
  imports: [MatButtonModule, MatInputModule, ReactiveFormsModule, CommonModule, MatIconModule, MatTooltipModule, MatSelectModule, AppStayntouchFieldsComponent, CapitalizePipe, AppMewsFieldsComponent, AppOhipFieldsComponent, AppHconnectFieldsComponent, AppHotelkeyFieldsComponent, AppStripeFieldsComponent, AppSertifiFieldsComponent, AppCventFieldsComponent],
  templateUrl: './add-secrets.component.html',
  styleUrls: ['./add-secrets.component.scss']
})
export class AddSecretsComponent implements OnInit {

  addSecretForm: FormGroup;
  isDisabled: boolean = true;
  inputValidation = new InputValidation();
  isAppCredentails: boolean = false;
  environments: { key: string, value: string }[];
  appName: string;
  appNames: { key: string, value: string }[];
  salesforceToken: string;
  salesforceTokenRetrieved: boolean = false;
  selectedType: string;
  isWebhook: string;
  appType: string;
  showSyncReadFields: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private addSecretService: AddSecretsService,
    private router: Router,
    private snackBar: MatSnackBar,
    public splashScreen: FuseSplashScreenService,
    public fetchSalesforceToken: FetchSalesforceTokenService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.environments = getProcessedEnvironments();
    this.addSecretForm = this.formBuilder.group({
      customerId: ['', [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      appName: ['', [Validators.required]],
      environment: ['', [Validators.required]],
      secretName: [{ value: '', disabled: this.isDisabled }, [InputValidation.underscorePatternValidator()]],
      sfdc_auth_url: ['', [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      sfdc_client_id: ['', [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      sfdc_client_secret: ['', [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      sfdc_grant_type: [{ value: 'client_credentials', disabled: this.isDisabled }, Validators.required],
      sfdc_object_url: ['', [Validators.required, InputValidation.urlValidator(), InputValidation.removeTrailingSpaceValidator()]],
      sfdc_token: ['', [InputValidation.removeTrailingSpaceValidator()]],
      mewsCredentials: this.formBuilder.array([]),
      stayNTouchCredentials: this.formBuilder.array([]),
      ohipCredentials: this.formBuilder.array([]),
      hconnectCredentials: this.formBuilder.array([]),
      hotelkeyCredentials: this.formBuilder.array([]),
      stripeCredentials: this.formBuilder.array([]),
      sertifiCredentials: this.formBuilder.array([]),
      cventCredentials: this.formBuilder.array([]),
    });

    this.addSecretForm.get('appName').valueChanges.subscribe(value => {
      this.generateSecret();
    });
    this.addSecretForm.statusChanges.subscribe(status => {
      this.isDisabled = status !== 'VALID';
    });

  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onTypeChange(selectedValue: string): void {
    this.selectedType = selectedValue;
    this.appType = selectedValue;
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges;
  }

  onShowSyncReadFieldsChange(showSyncReadFields: boolean): void {
    this.showSyncReadFields = showSyncReadFields;
  }

  onMewsCredentialsChanged(credentials: MewsCredentials[]): void {
    const mewsCredentialsFormArray = this.formBuilder.array(credentials.map(credential => {
      const group = this.formBuilder.group({
        client_name: [credential.client_name],
        client_token: [credential.client_token],
        access_token: [credential.access_token],
        api_url: [credential.api_url],
        pmsDb: [credential.pmsDb],
        propertyId: [credential.propertyId],
        objectType: [credential.objectType],
        apiSource: [credential.apiSource],
        syncStatus: [credential.syncStatus],
        webhookSecretKey: [credential.webhookSecretKey],
      });

      if (this.selectedType === 'Webhook') {
        group.get('pmsDb')?.clearValidators();
        group.get('propertyId')?.clearValidators();
        group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        if(this.showSyncReadFields) {
        group.get('client_name')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('access_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('api_url')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        this.addSecretForm.updateValueAndValidity();
        } else {
          group.get('pmsDb')?.clearValidators();
          group.get('propertyId')?.clearValidators();
          group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }
      } else if (this.selectedType === 'Sync/Read') {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
        group.get('client_name')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('access_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('api_url')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('pmsDb')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      } else {
        group.get('client_name')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('access_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('api_url')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('pmsDb')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
      }
      group.updateValueAndValidity();
      return group;
    }));
    this.addSecretForm.setControl('mewsCredentials', mewsCredentialsFormArray);
    mewsCredentialsFormArray.updateValueAndValidity();
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges
  }

  onStayntouchCredentialsChanged(credentials: StayNTouchCredentials[]): void {
    const stayntouchCredentialsFormArray = this.formBuilder.array(credentials.map(credential => {
      const group = this.formBuilder.group({
        client_id: [credential.client_id],
        client_secret: [credential.client_secret],
        grant_type: [credential.grant_type],
        authUrl: [credential.authUrl],
        apiUrl: [credential.apiUrl],
        access_token: [credential.access_token],
        propertyId: [credential.propertyId],
        pmsDb: [credential.pmsDb],
        objectType: [credential.objectType],
        apiSource: [credential.apiSource],
        syncStatus: [credential.syncStatus],
        webhookSecretKey: [credential.webhookSecretKey],
      });

      if (this.selectedType === 'Webhook') {
        group.get('pmsDb')?.clearValidators();
        group.get('propertyId')?.clearValidators();
        group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        if (this.showSyncReadFields) {
          group.get('client_id')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_secret')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('grant_type')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('authUrl')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiUrl')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('access_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        this.addSecretForm.updateValueAndValidity();
        } else {
          group.get('pmsDb')?.clearValidators();
          group.get('propertyId')?.clearValidators();
          group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }
      } else if (this.selectedType === 'Sync/Read') {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
        group.get('client_id')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_secret')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('grant_type')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('authUrl')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiUrl')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('access_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('pmsDb')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      } else {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
        group.get('client_id')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_secret')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('grant_type')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('authUrl')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiUrl')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('access_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('pmsDb')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      }

      group.updateValueAndValidity();
      return group;
    }));

    this.addSecretForm.setControl('stayNTouchCredentials', stayntouchCredentialsFormArray);
    stayntouchCredentialsFormArray.updateValueAndValidity();
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges
  }

  onOhipCredentialsChanged(ohipcredentials: OhipCredentials[]): void {
    const ohipCredentialsFormArray = this.formBuilder.array(ohipcredentials.map(ohipcredential => {
      const group = this.formBuilder.group({
        client_id: [ohipcredential.client_id],
        client_secret: [ohipcredential.client_secret],
        x_app_key: [ohipcredential.x_app_key],
        username: [ohipcredential.username],
        password: [ohipcredential.password],
        grant_type: [ohipcredential.grant_type],
        extSystemCode: [ohipcredential.extSystemCode],
        propertyId: [ohipcredential.propertyId],
        url: [ohipcredential.url],
        pmsDb: [ohipcredential.pmsDb],
        objectType: [ohipcredential.objectType],
        apiSource: [ohipcredential.apiSource],
        syncStatus: [ohipcredential.syncStatus],
        webhookSecretKey: [ohipcredential.webhookSecretKey],
      });

      if (this.selectedType === 'Webhook') {
        group.get('pmsDb')?.clearValidators();
        group.get('propertyId')?.clearValidators();
        group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        if(this.showSyncReadFields) {

          group.get('client_id')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('client_secret')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('x_app_key')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('username')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('password')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('grant_type')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('extSystemCode')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('url')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          this.addSecretForm.updateValueAndValidity();
        } else {
          group.get('pmsDb')?.clearValidators();
          group.get('propertyId')?.clearValidators();
          group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }

      }  else if (this.selectedType === 'Sync/Read') {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
        group.get('client_id')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_secret')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('x_app_key')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('username')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('password')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('grant_type')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('extSystemCode')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('url')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('pmsDb')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      } else {
        group.get('client_id')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_secret')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('x_app_key')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('username')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('password')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('grant_type')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('extSystemCode')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('url')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('pmsDb')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
      }

      group.updateValueAndValidity();
      return group;
    }));

    this.addSecretForm.setControl('ohipCredentials', ohipCredentialsFormArray);
    ohipCredentialsFormArray.updateValueAndValidity();
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges
  }

  onHconnectCredentialsChanged(hconnectCredentials: hconnectCredentials[]): void {
    const hconnectCredentialsFormArray = this.formBuilder.array(hconnectCredentials.map(hconnectCredential => {
      const group = this.formBuilder.group({
        client_id: [hconnectCredential.client_id],
        client_secret: [hconnectCredential.client_secret],
        scope: [hconnectCredential.scope],
        grant_type: [hconnectCredential.grant_type],
        access_token: [hconnectCredential.access_token],
        propertyId: [hconnectCredential.propertyId],
        pmsDb: [hconnectCredential.pmsDb],
        url: [hconnectCredential.url],
        authUrl: [hconnectCredential.authUrl],
        appKey: [hconnectCredential.appKey],
        hotelId: [hconnectCredential.hotelId],
        externalSystem: [hconnectCredential.externalSystem],
      });

      if (this.selectedType === 'Webhook') {
        group.get('client_id')?.clearValidators();
        group.get('client_secret')?.clearValidators();
        group.get('scope')?.clearValidators();
        group.get('grant_type')?.clearValidators();
        group.get('access_token')?.clearValidators();
        group.get('propertyId')?.clearValidators();
        group.get('pmsDb')?.clearValidators();
        group.get('url')?.clearValidators();
        group.get('authUrl')?.clearValidators();
        group.get('appKey')?.clearValidators();
        group.get('hotelId')?.clearValidators();
        group.get('externalSystem')?.clearValidators();
      } else {
        group.get('client_id')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('client_secret')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('scope')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('grant_type')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('access_token')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('pmsDb')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('url')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('authUrl')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('appKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('hotelId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('externalSystem')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      }

      group.updateValueAndValidity();
      return group;
    }));

    this.addSecretForm.setControl('hconnectCredentials', hconnectCredentialsFormArray);
    hconnectCredentialsFormArray.updateValueAndValidity();
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges
  }

  onHotelkeyCredentialsChanged(hotelkeyCredentials: hotelkeyCredentials[]): void {
    const hotelkeyCredentialsFormArray = this.formBuilder.array(hotelkeyCredentials.map(hotelkeyCredentials => {
      const group = this.formBuilder.group({
        hostName: [hotelkeyCredentials.hostName],
        propertyCode: [hotelkeyCredentials.propertyCode],
        propertyId: [hotelkeyCredentials.propertyId],
        pmsDb: [hotelkeyCredentials.pmsDb],
      });

      if (this.selectedType === 'Webhook') {
        group.get('hostName')?.clearValidators();
        group.get('propertyCode')?.clearValidators();
        group.get('propertyId')?.clearValidators();
        group.get('pmsDb')?.clearValidators();
      } else {
        group.get('hostName')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyCode')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('propertyId')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('pmsDb')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      }
      group.updateValueAndValidity();

      return group;
    }));
    this.addSecretForm.setControl('hotelkeyCredentials', hotelkeyCredentialsFormArray);
    hotelkeyCredentialsFormArray.updateValueAndValidity();
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges
  }

  onStripeCredentialsChanged(stripeCredentials: stripeCredentials[]): void {
    const stripeCredentialsFormArray = this.formBuilder.array(stripeCredentials.map(stripeCredential => {
      const group = this.formBuilder.group({
        objectType: [stripeCredential.objectType],
        apiSource: [stripeCredential.apiSource],
        syncStatus: [stripeCredential.syncStatus],
        webhookSecretKey: [stripeCredential.webhookSecretKey],
      });

      if (this.selectedType === 'Webhook') {
        group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        if(this.showSyncReadFields) {
        this.addSecretForm.updateValueAndValidity();
        } else {
          group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }
      } else if (this.selectedType === 'Sync/Read') {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.clearValidators();
      } else {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
      }
      group.updateValueAndValidity();
      return group;
    }));

    this.addSecretForm.setControl('stripeCredentials', stripeCredentialsFormArray);
    stripeCredentialsFormArray.updateValueAndValidity();
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges
  }

  onSertifiCredentialsChanged(sertifiCredentials: sertifiCredentials[]): void {
    const sertifiCredentialsFormArray = this.formBuilder.array(sertifiCredentials.map(sertifiCredential => {
      const group = this.formBuilder.group({
        objectType: [sertifiCredential.objectType],
        apiSource: [sertifiCredential.apiSource],
        syncStatus: [sertifiCredential.syncStatus],
        webhookSecretKey: [sertifiCredential.webhookSecretKey],
      });

      if (this.selectedType === 'Webhook') {
        group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        if(this.showSyncReadFields) {
        this.addSecretForm.updateValueAndValidity();
        } else {
          group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }
      } else if (this.selectedType === 'Sync/Read') {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.clearValidators();
      } else {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
      }
      group.updateValueAndValidity();
      return group;
    }));

    this.addSecretForm.setControl('sertifiCredentials', sertifiCredentialsFormArray);
    sertifiCredentialsFormArray.updateValueAndValidity();
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges
  }

  onCventCredentialsChanged(cventCredentials: cventCredentials[]): void {
    const cventCredentialsFormArray = this.formBuilder.array(cventCredentials.map(cventCredentials => {
      const group = this.formBuilder.group({
        objectType: [cventCredentials.objectType],
        apiSource: [cventCredentials.apiSource],
        syncStatus: [cventCredentials.syncStatus],
        webhookSecretKey: [cventCredentials.webhookSecretKey],
      });

      if (this.selectedType === 'Webhook') {
        group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        if(this.showSyncReadFields) {
        this.addSecretForm.updateValueAndValidity();
        } else {
          group.get('objectType')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('apiSource')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          group.get('webhookSecretKey')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }
      } else if (this.selectedType === 'Sync/Read') {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        group.get('webhookSecretKey')?.clearValidators();
      } else {
        group.get('objectType')?.clearValidators();
        group.get('apiSource')?.clearValidators();
        group.get('syncStatus')?.clearValidators();
        group.get('webhookSecretKey')?.clearValidators();
      }
      group.updateValueAndValidity();
      return group;
    }));

    this.addSecretForm.setControl('cventCredentials', cventCredentialsFormArray);
    cventCredentialsFormArray.updateValueAndValidity();
    this.addSecretForm.updateValueAndValidity();
    this.cdr.detectChanges
  }

  onSubmit(): void {
    if (this.addSecretForm.invalid) {
      return;
    }
    this.enableDisabledFields();
    this.splashScreen.show();
    const appName = this.addSecretForm.get('appName').value;
    const secretData: AddSecretManager = {
      secretName: this.addSecretForm.get('secretName').value,
      secretString: {
        sfdc_auth_url: this.addSecretForm.get('sfdc_auth_url').value,
        sfdc_client_id: this.addSecretForm.get('sfdc_client_id').value,
        sfdc_client_secret: this.addSecretForm.get('sfdc_client_secret').value,
        sfdc_grant_type: this.addSecretForm.get('sfdc_grant_type').value,
        sfdc_object_url: this.addSecretForm.get('sfdc_object_url').value,
        sfdc_token: this.addSecretForm.get('sfdc_token').value,
        mews: JSON.stringify(this.addSecretForm.get('mewsCredentials').value),
        stayntouch: JSON.stringify(this.addSecretForm.get('stayNTouchCredentials').value),
        ohip: JSON.stringify(this.addSecretForm.get('ohipCredentials').value),
        hconnect: JSON.stringify(this.addSecretForm.get('hconnectCredentials').value),
        hotelkey: JSON.stringify(this.addSecretForm.get('hotelkeyCredentials').value),
        stripe: JSON.stringify(this.addSecretForm.get('stripeCredentials').value),
        sertifi: JSON.stringify(this.addSecretForm.get('sertifiCredentials').value),
        cvent: JSON.stringify(this.addSecretForm.get('cventCredentials').value),
        type: this.selectedType,
      },
    };

    this.addSecretService.createSecret(secretData).subscribe(
      response => {
        if (response.status === APP_CONSTANTS.SUCCESS_STATUS) {
          this.splashScreen.hide();
          this.openSnackBar(APP_CONSTANTS.SUCCESS_MESSAGE);
          this.router.navigate([APP_CONSTANTS.SECRETS_PAGE]);
        } else {
          this.splashScreen.hide();
          this.openSnackBar(APP_CONSTANTS.FAILED_TO_ADD_SECRET);
        }
      },
      (error: HttpErrorResponse) => {
        this.splashScreen.hide();
        let errorMessage = APP_CONSTANTS.FAILED_TO_ADD_SECRET;
        if (error.error && error.error.message) {
          errorMessage = error.error.message;
        } else if (error.message) {
          errorMessage = error.message;
        }
        this.openSnackBar(errorMessage);
      }
    );
  }

  enableDisabledFields(): void {
    Object.keys(this.addSecretForm.controls).forEach(controlName => {
      const control = this.addSecretForm.get(controlName);
      if (control.disabled) {
        control.enable();
      }
    });
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, APP_CONSTANTS.MAT_SNACKBAR_CLOSE, {
      duration: APP_CONSTANTS.MAT_SNACKBAR_DURATION,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  generateSecret(): void {
    this.isAppCredentails = false;
    const customerId = this.addSecretForm.get('customerId').value;
    const appName = this.addSecretForm.get('appName').value;
    this.appName = appName;
    const environment = this.addSecretForm.get('environment').value;

    if (customerId || appName || environment) {
      const secretName = `thynk_${customerId}_${appName}_${environment}`;
      this.addSecretForm.get('secretName').setValue(secretName);
    }

    const appTypeActions = {
      'mews': 'mewsCredentials',
      'stayntouch': 'stayNTouchCredentials',
      'ohip': 'ohipCredentials',
      'hconnect': 'hconnectCredentials',
      'hotelkey': 'hotelkeyCredentials',
      'stripe': 'stripeCredentials',
      'sertifi': 'sertifiCredentials',
      'cvent': 'cventCredentials'
    };

    if (appName && appTypeActions[appName] && !this.isAppCredentails) {
      const methodName = `add${appTypeActions[appName].charAt(0).toUpperCase() + appTypeActions[appName].slice(1)}Field`;

      if (typeof this[methodName] === 'function') {
        this[methodName]();
        this.appType = appTypeActions[appName];
      }
      Object.keys(appTypeActions).forEach(type => {
        if (type !== appName) {
          const control = this.addSecretForm.get(`${appTypeActions[type]}`) as FormArray;
          if (control) {
            control.clear();
          }
        }
      });

      this.isAppCredentails = true;
    }
  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  getSalesforceToken(): void {
    try {
      const authUrl = this.addSecretForm.get('sfdc_auth_url').value;
      const clientId = this.addSecretForm.get('sfdc_client_id').value;
      const clientSecret = this.addSecretForm.get('sfdc_client_secret').value;
      const grantType = this.addSecretForm.get('sfdc_grant_type').value;

      this.fetchSalesforceToken.generateToken(authUrl, clientId, clientSecret, grantType).subscribe(
        response => {
          if (response && response.body && response.body.length > 0) {
            this.salesforceToken = response.body[0];
            this.addSecretForm.get('sfdc_token').setValue(this.salesforceToken);
            this.salesforceTokenRetrieved = true;
          } else {
            console.error('Failed to fetch Salesforce token: Invalid response body');
            this.openSnackBar('Salesforce credentials are not valid');
            this.salesforceTokenRetrieved = false;
          }
        },
        (error: HttpErrorResponse) => {
          console.error('Failed to fetch Salesforce token:', error);
          this.openSnackBar('Salesforce credentials are not valid');
          this.salesforceTokenRetrieved = false;
        }
      );
    } catch (error) {
      console.error('An error occurred while fetching Salesforce token:', error);
      this.salesforceTokenRetrieved = false;
    }
  }
}
