<div id="chart">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [responsive]="chartOptions.responsive"
    [stroke]="chartOptions.stroke"
    [colors]="ChartOptionsColor"
    [fill]="chartOptions.fill"
    [tooltip]="chartOptions.tooltip"
  ></apx-chart>
</div>
