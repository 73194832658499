import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SalesforceFormComponent } from './salesforce-form/salesforce-form.component';
import { SecretFormComponent } from './secret-form/secret-form.component';
import { MewsFormComponent } from './mews-form/mews-form.component';
import { OhipFormComponent } from './ohip-form/ohip-form.component';
import { HConnectFormComponent } from './hconnect-form/hconnect-form.component';
import { HotelKeyFormComponent } from './hotelkey-form/hotelkey-form.component';
import { SertifiFormComponent } from './sertifi-form/sertifi-form.component';
import { StayNTouchFormComponent } from './stayntouch-form/stayntouch-form.component';
import { StripeFormComponent } from './stripe-form/stripe-form.component';
import { CventFormComponent } from './cvent-form/cvent-form.component';
import { NotifierModule } from 'angular-notifier';
import { CommonModule } from '@angular/common';
import {
    generateToken,
    parseSecretName,
    getCustomerDataFromLocalCache,
} from '../../../../../src/app/theme/shared/utility';
import { AddCustomer } from '../../../../Interface/i-addCustomer-interface';
import { AddCustomerService } from '../../../../../src/services/add-customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateSecretsService } from '../../../../services/update-secrets.service';
import { UpdateSecretManager } from '../../../../Interface/i-updateCustomer-interface';
import { ToastrService } from 'ngx-toastr';
import { ETLFormComponent } from './etl-form/etl-form.component';

@Component({
    selector: 'app-mew-form',
    templateUrl: './create-customer.component.html',
    standalone: true,
    imports: [
        CommonModule,
        SalesforceFormComponent,
        MewsFormComponent,
        SecretFormComponent,
        NotifierModule,
        OhipFormComponent,
        StayNTouchFormComponent,
        HConnectFormComponent,
        HotelKeyFormComponent,
        SertifiFormComponent,
        StripeFormComponent,
        CventFormComponent,
        ETLFormComponent,
    ],
})
export class CreateCustomerComponent implements OnInit {
    formBuilder: any;
    secretForm!: FormGroup;
    salesforceForm!: FormGroup;

    isSecretKeyGenerated = false;
    isSalesforceTokenGenerated = false;
    applicationType = '';
    applicationName = '';

    secretFormObject: any;
    salesforceFormObject: any;
    mewsFormObject: any;
    ohipFormObject: any;
    stayntouchFormObject: any;
    hconnectFormObject: any;
    hotelkeyFormObject: any;
    sertifiFormObject: any;
    stripeFormObject: any;
    cventFormObject: any;
    etlFormObject: any;

    isEditMode: boolean = false;
    secretFormData: any = {};
    salesforceFormData: any = {};
    mewsFormData: any = {};
    ohipFormData: any = {};
    stayntouchFormData: any = {};
    hconnectFormData: any = {};
    hotelkeyFormData: any = {};
    stripeFormData: any = {};
    sertifiFormData: any = {};
    cventFormData: any = {};
    etlFormData: any = {};
    secretName: any;
    customerList: any[] = [];
    customerData: any;
    separatedValues: string[] = [];

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private addCustomerService: AddCustomerService,
        private upateCustomerService: UpdateSecretsService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.secretName = params.get('secretName');
            if (this.secretName) {
                // Set to edit mode
                this.isEditMode = true;
                this.renderForms();

                // Retrieve customer list from local storage
                this.customerData = getCustomerDataFromLocalCache(
                    this.secretName,
                );

                this.setFieldValuesForSecretForm(
                    this.customerData.name,
                    this.customerData.applicationType,
                );
                this.setFieldValuesForSalesforceForm(
                    this.customerData.secretKeyAWS,
                );
                this.setupPmsData();
            } else {
                // Set to add mode
                this.isEditMode = false;
                this.renderForms();
                this.isSecretKeyGenerated = false;
                this.isSalesforceTokenGenerated = false;
            }
        });
    }

    setFieldValuesForSecretForm(name: any, applicationType: any): void {
        this.separatedValues = parseSecretName(name);
        this.secretFormData = this.separatedValues;
        this.secretFormData.secretName = name;
        this.secretFormData.applicationType = applicationType;
        this.applicationType = '';
        this.applicationName = '';
    }

    setFieldValuesForSalesforceForm(value: any): void {
        this.salesforceFormData = value;
    }

    setupPmsData(): void {
        if (this.separatedValues[1] == 'mews') {
            this.mewsFormData = this.customerData.secretKeyAWS.mews;
        }
        if (this.separatedValues[1] == 'ohip') {
            this.ohipFormData = this.customerData.secretKeyAWS.ohip;
        }
        if (this.separatedValues[1] == 'stayntouch') {
            this.stayntouchFormData = this.customerData.secretKeyAWS.stayntouch;
        }
        if (this.separatedValues[1] == 'hconnect') {
            this.hconnectFormData = this.customerData.secretKeyAWS.hconnect;
        }
        if (this.separatedValues[1] == 'hotelkey') {
            this.hotelkeyFormData = this.customerData.secretKeyAWS.hotelkey;
        }
        if (this.separatedValues[1] == 'stripe') {
            this.stripeFormData = this.customerData.secretKeyAWS.stripe;
        }
        if (this.separatedValues[1] == 'sertifi') {
            this.sertifiFormData = this.customerData.secretKeyAWS.sertifi;
        }
        if (this.separatedValues[1] == 'cvent') {
            this.cventFormData = this.customerData.secretKeyAWS.cvent;
        }
        if (this.separatedValues[1] == 'etl') {
            this.etlFormData = this.customerData.secretKeyAWS.etl;
        }
    }

    renderForms(): void {
        this.secretForm = this.fb.group({
            customerId: [
                { value: '', disabled: this.isEditMode ? true : false },
                Validators.required,
            ],
            appName: [
                { value: '', disabled: this.isEditMode ? true : false },
                Validators.required,
            ],
            environment: [
                { value: '', disabled: this.isEditMode ? true : false },
                Validators.required,
            ],
            appType: [
                { value: '', disabled: this.isEditMode ? true : false },
                Validators.required,
            ],
            secretName: [{ value: '', disabled: true }],
        });

        this.salesforceForm = this.fb.group({
            // sfdc_auth_url: ['', [Validators.required]],
            // sfdc_client_id: ['', [Validators.required]],
            // sfdc_client_secret: ['', [Validators.required]],
            // sfdc_grant_type: [{ value: 'client_credentials', disabled: true }],
            // sfdc_object_url: ['', [Validators.required]],
            // sfdc_token: ['']

            sfdc_auth_url: [
                { value: '', disabled: true },
                [Validators.required],
            ],
            sfdc_client_id: ['', [Validators.required]],
            sfdc_client_secret: ['', [Validators.required]],
            sfdc_grant_type: [{ value: 'client_credentials', disabled: true }],
            sfdc_object_url: ['', [Validators.required]],
            sfdc_token: [''],
        });
    }

    onSecretFormObject(values: any): void {
        this.secretFormObject = values;
        const {
            customerId,
            appName,
            environment,
            secretName,
            isSecretKeyGenerated,
            applicationType,
            editMode,
        } = values;
        this.isSecretKeyGenerated = isSecretKeyGenerated;
        this.applicationType = applicationType;
        this.applicationName = appName;
        this.isEditMode = editMode;
    }

    onSalesforceFormObject(values: any): void {
        this.salesforceFormObject = values;
        const {
            sfdc_auth_url,
            sfdc_client_id,
            sfdc_client_secret,
            sfdc_grant_type,
            isSalesforceAuthorize,
            token,
        } = values;
        this.isSalesforceTokenGenerated = isSalesforceAuthorize;
        this.salesforceForm.patchValue({ sfdc_token: token });
    }

    onMewsFormSubmit(formValue: any): void {
        this.mewsFormObject = formValue;
        this.createRequestModel('mews');
    }

    onOhipFormSubmit(formValue: any): void {
        this.ohipFormObject = formValue;
        this.createRequestModel('ohip');
    }

    onStayntouchFormSubmit(formValue: any): void {
        this.stayntouchFormObject = formValue;
        this.createRequestModel('stayntouch');
    }

    onHconnectFormSubmit(formValue: any): void {
        this.hconnectFormObject = formValue;
        this.createRequestModel('hconnect');
    }

    onHotelkeyFormSubmit(formValue: any): void {
        this.hotelkeyFormObject = formValue;
        this.createRequestModel('hotelkey');
    }

    onStripeFormSubmit(formValue: any): void {
        this.stripeFormObject = formValue;
        this.createRequestModel('stripe');
    }

    onSertifiFormSubmit(formValue: any): void {
        this.sertifiFormObject = formValue;
        this.createRequestModel('sertifi');
    }

    onCventFormSubmit(formValue: any): void {
        this.cventFormObject = formValue;
        this.createRequestModel('cvent');
    }

    onEtlFormSubmit(formValue: any): void {
        this.etlFormObject = formValue;
        this.createRequestModel('etl');
    }

    createRequestModel(pms: String) {
        const appName = this.secretFormObject.appName;
        const customerData: AddCustomer = {
            secretName: this.secretFormObject.secretName,
            secretString: {
                sfdc_auth_url: this.salesforceFormObject.sfdc_auth_url,
                sfdc_client_id: this.salesforceFormObject.sfdc_client_id,
                sfdc_client_secret:
                    this.salesforceFormObject.sfdc_client_secret,
                sfdc_grant_type: this.salesforceFormObject.sfdc_grant_type,
                sfdc_object_url: this.salesforceFormObject.sfdc_object_url,
                sfdc_token: this.salesforceFormObject.token,
                mews:
                    pms === 'mews'
                        ? JSON.stringify(this.mewsFormObject.pmsCredentials)
                        : '',
                stayntouch:
                    pms === 'stayntouch'
                        ? JSON.stringify(
                              this.stayntouchFormObject.pmsCredentials,
                          )
                        : '',
                ohip:
                    pms === 'ohip'
                        ? JSON.stringify(this.ohipFormObject.pmsCredentials)
                        : '',
                hconnect:
                    pms === 'hconnect'
                        ? JSON.stringify(this.hconnectFormObject.pmsCredentials)
                        : '',
                hotelkey:
                    pms === 'hotelkey'
                        ? JSON.stringify(this.hotelkeyFormObject.pmsCredentials)
                        : '',
                stripe:
                    pms === 'stripe'
                        ? JSON.stringify(this.stripeFormObject.pmsCredentials)
                        : '',
                sertifi:
                    pms === 'sertifi'
                        ? JSON.stringify(this.sertifiFormObject.pmsCredentials)
                        : '',
                cvent:
                    pms === 'cvent'
                        ? JSON.stringify(this.cventFormObject.pmsCredentials)
                        : '',
                etl:
                    pms === 'etl'
                        ? JSON.stringify(this.etlFormObject.pmsCredentials)
                        : '',
                type: this.applicationType,
            },
        };
        localStorage.setItem(
            'createCustomerRecord',
            JSON.stringify(customerData),
        );
        if (this.isEditMode) {
            this.sendRequestToAWSUpdate(customerData);
        } else {
            this.sendRequestToAWSCreate(customerData);
        }
    }

    sendRequestToAWSCreate(customerData: AddCustomer): void {
        this.addCustomerService.createSecret(customerData).subscribe(
            (response: { status: string; message: string }) => {
                if (response.status === 'SUCCESS') {
                    this.toastr.success(
                        'Customer Secret Created Successfully.',
                        'Success',
                    );
                    this.removeCustomerList();
                    this.router.navigate(['/customer/detail']);
                } else {
                    this.toastr.error(response.message, 'Error');
                }
            },
            (error: HttpErrorResponse) => {
                if (error.error && error.error.message) {
                    const errorMessage = error.error.message;
                } else if (error.message) {
                    const errorMessage = error.message;
                }
            },
        );
    }

    sendRequestToAWSUpdate(customerData: UpdateSecretManager): void {
        this.upateCustomerService
            .updateSecretInformation(customerData)
            .subscribe(
                (response: { status: string; message: string }) => {
                    if (response.status === 'SUCCESS') {
                        this.toastr.success(
                            'Customer Secret Updates Successfully.',
                            'Success',
                        );
                        this.removeCustomerList();
                        this.router.navigate(['/customer/detail']);
                    } else {
                        this.toastr.error(response.message, 'Error');
                    }
                },
                (error: HttpErrorResponse) => {
                    if (error.error && error.error.message) {
                        const errorMessage = error.error.message;
                        console.log(errorMessage);
                    } else if (error.message) {
                        const errorMessage = error.message;
                        console.log(errorMessage);
                    }
                },
            );
    }

    removeCustomerList() {
        localStorage.removeItem('customer-list');
        localStorage.removeItem('envFilter');
        localStorage.removeItem('appNames');
        localStorage.removeItem('appType');
    }
}
