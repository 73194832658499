<div *ngIf="appName === 'stripe'" class='addFormContainer'>
    <div class="column text-2xl font-extrabold tracking-tight mt-6">
        <div class="appSelection">
            <div class="colHeading  mb-4">
                App Credentials
            </div>
        </div>
        <div class="actionRowBtn"  *ngIf="appType === 'noProvided'">
            <button (click)="addAppCredentialsField()" class="ml-3 w-28 " mat-flat-button type="button"
                [color]="'primary'">
                <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2">Add New</span>
            </button>
        </div>

    </div>

    <hr>

    <div [formGroup]="stripeForm" class='addFormContainer'>
        <div formArrayName="stripeCredentials">
            <div *ngFor="let credentialsGroup of getStripeCredentialsControls(); let i = index" [formGroupName]="i">
                <div *ngIf="appType === 'Webhook'">
                    <div class="column">
                        <div
                            class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading"
                        >
                            {{ appName | capitalize }} Webhook Information
                        </div>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Object Type</mat-label>
                            <input
                                matInput
                                placeholder="Object Type"
                                formControlName="objectType"
                            />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>API Source</mat-label>
                            <input
                                matInput
                                placeholder="API Source"
                                formControlName="apiSource"
                            />
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Sync Status</mat-label>
                            <input
                                matInput
                                placeholder="Sync Status"
                                formControlName="syncStatus"
                            />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Webhook Api Key</mat-label>
                            <input
                                matInput
                                placeholder="Webhook Api Key"
                                formControlName="webhookSecretKey"
                            />
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="appType === 'Sync/Read' || showSyncReadFields">
                    <h2>
                        Fields are not available for Sync/Read
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>


