import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef  } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { InputValidation } from 'app/shared/inputValidation';
import { MatSelectModule } from '@angular/material/select';
import { hconnectCredentials } from 'app/interface/i-addSecret-interface';
import { CapitalizePipe } from 'app/capitalize.pipe';

@Component({
  selector: 'app-app-hconnect-fields',
  standalone: true,
  imports: [MatButton, MatInputModule, ReactiveFormsModule, CommonModule, MatIconModule, MatTooltip, MatSelectModule, AppHconnectFieldsComponent, CapitalizePipe],
  templateUrl: './app-hconnect-fields.component.html',
  styleUrl: './app-hconnect-fields.component.scss'
})
export class AppHconnectFieldsComponent implements OnInit, OnChanges {
  @Input() appType: string;
  @Input() appBool: string;
  @Output() credentialsChanged = new EventEmitter<hconnectCredentials[]>();

  addSecretForm: FormGroup;
  hConnectForm: FormGroup;
  isDisabled: boolean = true;
  inputValidation = new InputValidation();
  isAppCredentails: boolean = false;
  environments: { key: string, value: string }[];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    public splashScreen: FuseSplashScreenService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.hConnectForm = this.formBuilder.group({
      hconnectCredentials: this.formBuilder.array([])
    });
    this.addAppCredentialsField();
  }

  get hconnectCredentials(): FormArray {
    return this.hConnectForm.get('hconnectCredentials') as FormArray;
  }

  getHconnectCredentialsControls(): AbstractControl[] {
    return this.hconnectCredentials.controls;
  }

  onCredentialsChanged(): void {
    this.credentialsChanged.emit(this.hconnectCredentials.value);
  }

  addAppCredentialsField(): void {
    const hconnectGroup = this.formBuilder.group({
      client_id: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      client_secret: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      scope: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      grant_type: ['client_credentials', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      propertyId: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      pmsDb: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      url: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      access_token: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      authUrl: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      appKey: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      hotelId: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      externalSystem: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
    });  

    hconnectGroup.valueChanges.subscribe(() => {
      this.hconnectCredentials.updateValueAndValidity();
      this.onCredentialsChanged();
    });

    this.hconnectCredentials.push(hconnectGroup);
    this.onCredentialsChanged();
  }

  removeAppCredentialsField(index: number): void {
    this.hconnectCredentials.removeAt(index);
    this.onCredentialsChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appBool'] && changes['appBool'].currentValue !== undefined) {
      this.updateValidationBasedOnAppBool(changes['appBool'].currentValue);
      this.cdr.detectChanges();
    }
  }

  private updateValidationBasedOnAppBool(appBool: string): void {
    this.hconnectCredentials.controls.forEach(control => {
      const clientId = control.get('client_id');
      const clientSecret = control.get('client_secret');
      const scope = control.get('scope');
      const grantType = control.get('grant_type');
      const propertyId = control.get('propertyId');
      const url = control.get('url');
      const accessToken = control.get('access_token');
      const authUrl = control.get('authUrl');
      const appKey = control.get('appKey');
      const hotelId = control.get('hotelId');
      const externalSystem = control.get('externalSystem');
      const pmsDb = control.get('pmsDb');

      if (appBool === 'Webhook') {
        clientId.clearValidators();
        clientSecret.clearValidators();
        scope.clearValidators();
        grantType.clearValidators();
        propertyId.clearValidators();
        url.clearValidators();
        accessToken.clearValidators();
        authUrl.clearValidators();
        appKey.clearValidators();
        hotelId.clearValidators();
        externalSystem.clearValidators();
        pmsDb.clearValidators();
      } else {
        clientId.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        clientSecret.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        scope.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        grantType.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        propertyId.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        url.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        accessToken.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        authUrl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        appKey.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        hotelId.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        externalSystem.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        pmsDb.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      }
      clientId.updateValueAndValidity();
      clientSecret.updateValueAndValidity();
      scope.updateValueAndValidity();
      grantType.updateValueAndValidity();
      propertyId.updateValueAndValidity();
      url.updateValueAndValidity();
      accessToken.updateValueAndValidity();
      authUrl.updateValueAndValidity();
      appKey.updateValueAndValidity();
      hotelId.updateValueAndValidity();
      externalSystem.updateValueAndValidity();
      pmsDb.updateValueAndValidity();
    });
    this.hconnectCredentials.updateValueAndValidity();
  }
}
