<app-card [showHeader]="false">
  <div class="d-flex align-items-center justify-content-between mb-3">
    <h5 class="mb-0">Category</h5>
    <div ngbDropdown>
      <a class="avatar avatar-s btn-link-secondary arrow-none" href="javascript:" ngbDropdownToggle>
        <i class="ti ti-dots-vertical f-18"></i>
      </a>
      <div ngbDropdownMenu>
        <a ngbDropdownItem>Today</a>
        <a ngbDropdownItem>Weekly</a>
        <a ngbDropdownItem>Monthly</a>
      </div>
    </div>
  </div>
  <div id="chart">
    <apx-chart
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [series]="chartOptions.series"
      [colors]="chartOptions.colors"
      [plotOptions]="chartOptions.plotOptions"
      [labels]="chartOptions.labels"
      [legend]="chartOptions.legend"
    ></apx-chart>
  </div>
</app-card>
