import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FetchSalesforceTokenService } from 'src/services/fetch-salesforce-token.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-salesforce-form',
    templateUrl: './salesforce-form.component.html',
    styleUrls: ['./salesforce-form.component.scss'],
    standalone: true,
    imports: [CommonModule, SharedModule, ReactiveFormsModule],
})
export class SalesforceFormComponent {
    @Input() salesforceForm!: FormGroup;
    @Output() salesforceFormObject = new EventEmitter<Object>();
    @Input() editMode: boolean = false;
    @Input() initialData: any;
    tokenResult: string | null = null;
    isSalesforceTokenGenerated: boolean = false;
    loading = false;
    errorMessage: string | null = null;

    constructor(
        private fb: FormBuilder,
        private fetchSalesforceTokenService: FetchSalesforceTokenService,
        private notifier: NotifierService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        if (this.editMode && this.initialData) {
            this.salesforceForm.get('sfdc_auth_url')?.enable();

            this.populateForm(this.initialData);
        }
    }

    populateForm(data: any): void {
        this.salesforceForm.patchValue({
            sfdc_auth_url: this.generateTokenUrl(data.sfdc_object_url),
            sfdc_client_id: data.sfdc_client_id,
            sfdc_client_secret: data.sfdc_client_secret,
            sfdc_object_url: data.sfdc_object_url,
            sfdc_token: data.sfdc_token,
        });
        this.checkSalesforceConnectivity();
    }

    checkSalesforceConnectivity(): void {
        if (!this.salesforceForm.valid) {
            this.handleError('Please fill in all required fields');
            return;
        }

        this.startLoading();

        // Fetch the value directly from the form control
        const sfdc_auth_url = this.salesforceForm.get('sfdc_auth_url')?.value;
        const sfdc_client_id = this.salesforceForm.get('sfdc_client_id')?.value;
        const sfdc_client_secret =
            this.salesforceForm.get('sfdc_client_secret')?.value;
        const sfdc_grant_type =
            this.salesforceForm.get('sfdc_grant_type')?.value;

        this.fetchSalesforceTokenService
            .generateToken(
                sfdc_auth_url,
                sfdc_client_id,
                sfdc_client_secret,
                sfdc_grant_type,
            )
            .subscribe({
                next: (response) => this.handleSuccess(response),
                error: (error) => this.handleError(`Error: ${error}`),
            });
    }

    private startLoading(): void {
        this.loading = true;
        this.errorMessage = null;
    }

    private stopLoading(): void {
        this.loading = false;
    }

    private handleSuccess(response: any): void {
        if (response?.status === 'Success' && response.body?.length) {
            const token = response.body[0];
            this.tokenResult = token;
            this.updateSalesforceFormWithToken(token);
            this.isSalesforceTokenGenerated = !!token?.trim();
            this.toastr.success(
                'Saleforce Connection Authorize Successfully.',
                'Success',
            );
            this.emitSalesforceFormObject(true, token);
        } else {
            this.handleError('credentials are not valid.');
        }
        this.finalizeRequest();
    }

    private handleError(message: string): void {
        this.toastr.error(message, 'Error');
        this.emitSalesforceFormObject(false);
        this.tokenResult = null;
        this.errorMessage = message;
        this.finalizeRequest();
    }

    private updateSalesforceFormWithToken(token: string): void {
        this.salesforceForm.patchValue({ token: token });
    }

    private emitSalesforceFormObject(
        isAuthorized: boolean,
        token?: string,
    ): void {
        const sfdc_auth_url = this.salesforceForm.get('sfdc_auth_url')?.value;
        const sfdc_object_url =
            this.salesforceForm.get('sfdc_object_url')?.value;
        const sfdc_client_id = this.salesforceForm.get('sfdc_client_id')?.value;
        const sfdc_client_secret =
            this.salesforceForm.get('sfdc_client_secret')?.value;
        const sfdc_grant_type =
            this.salesforceForm.get('sfdc_grant_type')?.value;
        const salesforceFormObject = {
            sfdc_auth_url,
            sfdc_client_id,
            sfdc_client_secret,
            sfdc_grant_type,
            sfdc_object_url,
            isSalesforceAuthorize: isAuthorized,
            token: token || null,
        };

        this.salesforceFormObject.emit(salesforceFormObject);
    }

    private finalizeRequest(): void {
        this.stopLoading();
        this.cdr.detectChanges();
    }

    onTypeObjectUrl(event: any) {
        const domain = event.target.value.trim(); // Get the input value and trim whitespace
        if (domain) {
            const tokenUrl = this.generateTokenUrl(domain);
            this.salesforceForm.get('sfdc_auth_url')?.setValue(tokenUrl);
        } else {
            console.warn('Domain is empty or invalid');
        }
    }

    generateTokenUrl(domain: string): string {
        try {
            // Ensure the input domain is valid
            if (!domain || typeof domain !== 'string') {
                throw new Error('Invalid domain provided');
            }

            // Construct the OAuth token URL
            const tokenUrl = `https://${domain}/services/oauth2/token`;

            return tokenUrl;
        } catch (error) {
            console.error('Error generating token URL:', error);
            return '';
        }
    }
}
