// Angular import
import { AsyncPipe, DecimalPipe, CommonModule } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';

// project import
import { SharedModule } from '../../../../../../src/app/theme/shared/shared.module';
import { LambdaLogs } from './log-data.config/log';
import { LogDataService } from './log-data.config/log-data.service';
import {
    NgbdSortableHeaderDirective,
    SortEvent,
} from '../../../../theme/shared/directive/sortable.directive';

// rxjs library
import { Observable, of } from 'rxjs';

// bootstrap import
import {
    NgbModal,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {
    getAppName,
    getProcessedEnvironments,
    getUserRole,
} from '../../../../../../src/app/theme/shared/utility';
import { ModalModule } from '../../../../../../src/app/theme/shared/components';
import { Clipboard } from '@angular/cdk/clipboard';
import { DeleteCustomerService } from '../../../../../../src/services/delete-customer.service';
import { LambdalogsService } from 'src/services/lambda-logs.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-log-list',
    standalone: true,
    imports: [
        DecimalPipe,
        FormsModule,
        NgbdSortableHeaderDirective,
        AsyncPipe,
        NgbTypeaheadModule,
        NgbPaginationModule,
        CommonModule,
        NgbTooltipModule,
        SharedModule,
        ModalModule,
    ],
    templateUrl: './log-list.component.html',
    styleUrls: ['./log-list.component.scss'],
    providers: [LogDataService, DecimalPipe, DatePipe],
})
export class logListComponent implements OnInit {
    customer$!: Observable<LambdaLogs[]>;
    total$!: Observable<number>;

    @ViewChildren(NgbdSortableHeaderDirective)
    headers!: QueryList<NgbdSortableHeaderDirective>;

    appNames!: any[];
    environments!: any[];
    isLoading$!: Observable<boolean>;
    selectedEnvironment: string = '';
    selectedNoOfDays: number = 1;
    noOfDaysChanged = false;
    processItem: any[] = [];
    searchQuery: string = '';
    logsFetched: boolean = false;
    selectedLogGroup: string = '';
    selectedApp: string = '';
    logGroupName: string = '';
    selectedStatus: string = 'all';
    displayedColumns: string[] = [
        'Date',
        'middlewareJobId',
        'status',
        'pmsSystem',
    ];
    middlewareJodId: string = '';
    sqsQueueUrls: any[] = [];
    middlewareLogGroupName: string = '';
    parsedLogDetails: { [key: string]: any } = {};

    constructor(
        public service: LogDataService,
        private router: Router,
        private datePipe: DatePipe,
        private clipboard: Clipboard,
        private deleteCustomerService: DeleteCustomerService,
        private modalService: NgbModal,
        private lambdalogsService: LambdalogsService,
        private toastr: ToastrService,
    ) {
        this.customer$ = service.customer$;
        this.total$ = service.total$;
        this.isLoading$ = service.loading$;
    }

    ngOnInit(): void {
        this.environments = getProcessedEnvironments();
        this.appNames = getAppName();
        this.checkLocalStorage();
    }

    checkLocalStorage(): void {
        const localLogs = localStorage.getItem('logs');
        const lambdaLogOldSearch = localStorage.getItem('lambdaLogOldSearch');
        const lambdaNoOfDays = localStorage.getItem('lambdaNoOfDays');

        if (localLogs) {
            if (lambdaLogOldSearch) {
                this.selectedEnvironment = lambdaLogOldSearch;
            }
            if (lambdaNoOfDays) {
                this.selectedNoOfDays = Number(lambdaNoOfDays);
            }
            this.onFilterChange();
        } else {
        }
    }

    generateLogGroupName(): string {
        const environment = this.selectedEnvironment.toLowerCase();
        return `/lambda-thynkcloud-${environment}`;
    }

    onSort({ column, direction }: SortEvent) {
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.sortColumn = column;
        this.service.sortDirection = direction;
    }

    showSecretDetails(
        secretName: string,
        createdDate: string,
        applicationType: string,
    ): void {
        const formattedDate = this.datePipe.transform(
            createdDate,
            'dd-MM-yyyy',
        );
        this.router.navigate([
            '/secretDetails',
            {
                secretName: secretName,
                createdDate: formattedDate,
                applicationType: applicationType,
            },
        ]);
    }

    public parseSecret(secretName: string) {
        const parts = secretName.split('_');
        if (parts.length < 4) {
            console.error('Unexpected secret name format:', secretName);
            return {
                secretName: secretName,
                organizationName: '',
                customerId: '',
                appName: '',
                environment: '',
                createdDate: '',
            };
        }

        const organizationName = parts[0];
        const customerId = parts.slice(1, -2).join('_');
        const appName = parts[parts.length - 2];
        const environment = parts[parts.length - 1];

        return {
            secretName: secretName,
            organizationName,
            customerId,
            appName,
            environment,
        };
    }

    redirectToDetailPage(middlewareId: String): void {
        const logs = localStorage.getItem('logs');
        if (logs) {
            const logItem = JSON.parse(logs).find(
                (item: { middlewareJobId: String }) =>
                    item.middlewareJobId === middlewareId,
            );
            if (logItem) {
                this.router.navigate([
                    '/log/detail',
                    {
                        middlewareId: middlewareId,
                        middlewareLogGroupName:
                            this.lambdalogsService.createLogGroupName(
                                logItem.sqsQueueUrl,
                            ),
                        logGroupName: this.generateLogGroupName(),
                    },
                ]);
            } else {
                console.error(
                    'No log found with the provided middlewareJobId:',
                    middlewareId,
                );
            }
        }
    }

    onEnvironmentChange(value: any): void {
        this.selectedEnvironment = value;
    }

    onDayChange(value: any): void {
        this.selectedNoOfDays = value;
        if (value != localStorage.getItem('lambdaNoOfDays')) {
            this.noOfDaysChanged = true;
        }
        localStorage.setItem('lambdaNoOfDays', value);
    }

    onFilterChange(): void {
        if (
            !this.selectedNoOfDays ||
            isNaN(this.selectedNoOfDays) ||
            this.selectedEnvironment == ''
        ) {
            this.toastr.warning(
                'Please select Environment and log days to fetch.',
                'Warning',
            );
            return;
        } else {
            this.service.selectedNoOfDays = this.selectedNoOfDays;
            this.service.selectedEnvironment = this.selectedEnvironment;
            this.service.lambdaLogOldSearch = this.selectedEnvironment;
            this.service.noOfDaysChanged = this.noOfDaysChanged;
            this.service.page = 1; // Reset to the first page
        }
    }

    copyMiddlewareId(middlewareId: string): void {
        this.clipboard.copy(middlewareId);
    }
}
