<div class="row">
  <div class="col-sm-12">
    <app-card [showHeader]="false" cardClass="table-card customer-list">
      <form class="table-responsive">
        <div class="datatable-top d-flex">
          <div class="datatable-top w-100 align-items-center mb-3">
            <!-- Left side with three dropdowns -->
            <div class="d-flex gap-2">
              <select
                class="form-select"
                title="Select Environment"
                [(ngModel)]="selectedEnvironment"
                (change)="onFilterChange()"
                name="selectedEnvironment"
              >
                <option value="" disabled selected>Select Environment</option>
                <option value="all">All</option>
                <option *ngFor="let env of environments" [value]="env.value">{{ env.key }}</option>
              </select>

              <select
                class="form-select"
                title="Select App Name"
                [(ngModel)]="selectedAppName"
                (change)="onFilterChange()"
                name="selectedAppName"
              >
                <option value="" disabled selected>Select Application Name</option>
                <option value="all">All</option>
                <option *ngFor="let app of appNames" [value]="app.value">{{ app.key }}</option>
              </select>

              <select
                class="form-select"
                title="Select App Type"
                [(ngModel)]="selectedAppType"
                (change)="onFilterChange()"
                name="selectedAppType"
              >
                <option value="" disabled selected>Select Application Type</option>
                <option value="all">All</option>
                <option value="Webhook">Webhook</option>
                <option value="sync/read">Sync / Read</option>
              </select>
            </div>

            <!-- Right side with search input and add button -->
            <div class="datatable-search d-flex align-items-center gap-2">
              <input
                id="table-complete-search"
                type="text"
                class="form-control"
                name="searchTerm"
                [(ngModel)]="service.searchTerm"
                placeholder="Search..."
              />
              <!-- <div>
                <button type="button" class="btn btn-info btn-icon" (click)="onAddNewCustomer()">
                  <i class="ti ti-plus"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>
        <div class="image-div" *ngIf="(customer$ | async)?.length <= 0">
          <img class="inner-image" src="../../../../../assets/images/thynk-cloud/no-logs.jpg" />
        </div>
        <table class="table table-hover" *ngIf="(customer$ | async)?.length > 0">
          <thead>
            <tr>
              <th scope="col"><input class="form-check-input" type="checkbox" title="Select All" /></th>
              <th scope="col" sortable="customerId" (sort)="onSort($event)">Customer Id</th>
              <th scope="col" sortable="appName" (sort)="onSort($event)">Application Name</th>
              <th scope="col" sortable="environment" (sort)="onSort($event)">Environment</th>
              <th scope="col" sortable="createdDate" (sort)="onSort($event)">Created Date</th>
              <th scope="col" sortable="appType" (sort)="onSort($event)">Application Type</th>
              <th scope="col" sortable="action" (sort)="onSort($event)" class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            @for (customer of customer$ | async; track customer) {
              <tr>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" title="checkbox" />
                  </div>
                </td>
                <td>
                  <div>
                    <div class="d-flex align-items-start flex-column">
                      <ngb-highlight
                        result="{{ parseSecret(customer.name).customerId }}"
                        [term]="service.searchTerm"
                        class="h5 mb-0"
                      ></ngb-highlight>
                      <ngb-highlight
                        result="{{ parseSecret(customer.name).secretName }}"
                        [term]="service.searchTerm"
                        class="text-muted f-12 mb-0"
                      ></ngb-highlight>
                    </div>
                  </div>
                </td>
                <td><ngb-highlight result="{{ parseSecret(customer.name).appName }}" [term]="service.searchTerm"></ngb-highlight></td>
                <td><ngb-highlight result="{{ parseSecret(customer.name).environment }}" [term]="service.searchTerm"></ngb-highlight></td>
                <td><ngb-highlight [result]="customer.createdDate | date: 'MM-dd-yyyy'" [term]="service.searchTerm"></ngb-highlight></td>

                <td>
                  @if (customer.applicationType === 'webhook') {
                    <ngb-highlight
                      [result]="customer.applicationType"
                      [term]="service.searchTerm"
                      class="badge bg-light-primary rounded-pill f-12"
                    ></ngb-highlight>
                  } @else if (customer.applicationType === 'sync/read') {
                    <ngb-highlight
                      [result]="customer.applicationType"
                      [term]="service.searchTerm"
                      class="badge bg-light-success rounded-pill f-12"
                    ></ngb-highlight>
                  }
                </td>
                <td class="text-center">
                  <button type="button" class="btn btn-icon btn-link-dark" (click)="copySecretName(parseSecret(customer.name).secretName)">
                    <i class="ti ti-copy"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon btn-link-info"
                    (click)="showSecretDetails(parseSecret(customer.name).secretName, customer.createdDate, customer.applicationType)"
                  >
                    <i class="ti ti-eye"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon btn-link-secondary"
                    (click)="editSecretName(parseSecret(customer.name).secretName)"
                  >
                    <i class="ti ti-pencil"></i>
                  </button>
                  <button
                    *ngIf="userRole == 'admin'"
                    type="button"
                    class="btn btn-icon btn-link-danger"
                    (click)="openMyModal(modalContent, parseSecret(customer.name).secretName)"
                  >
                    <i class="ti ti-trash"></i>
                  </button>
                  <div class="btn-page">
                    <ng-template #modalContent>
                      <div class="modal-header">
                        <h5 class="modal-title">Delete Customer Secret</h5>
                        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef?.close()"></button>
                      </div>
                      <div class="modal-body text-center">
                        <img width="100" src="../../../../../assets/images/thynk-cloud/trash-bin.gif" alt="Loading..." />
                        <p class="font-size">Are you sure you want to delete?</p>
                        <p class="font-size-14">{{ selectedSecretName }}</p>
                      </div>
                      <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-outline-secondary" (click)="modalRef?.close()">Cancel</button>
                        <button type="button" class="btn btn-danger" (click)="confirmDelete(selectedSecretName)">Delete</button>
                      </div>
                    </ng-template>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div class="d-flex justify-content-between align-items-center p-2">
          <select class="form-select" title="select item per page" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="15">15 items per page</option>
            <option [ngValue]="20">20 items per page</option>
          </select>
          <ngb-pagination
            [collectionSize]="(total$ | async)!"
            [(page)]="service.page"
            [pageSize]="service.pageSize"
            class="ms-3"
          ></ngb-pagination>
        </div>
      </form>
      <div class="loader-main" *ngIf="isLoading$ | async">
        <div class="loader-content">
          <img src="../../../../../assets/images/thynk-cloud/loader.gif" alt="Loading..." />
          <p>Optimizing your request... Almost done.</p>
        </div>
      </div>
    </app-card>
  </div>
</div>
