export function getProcessedEnvironments(): { key: string; value: string }[] {
  const environmentsString = localStorage.getItem('environment');
  const environmentsJson = environmentsString ? JSON.parse(environmentsString) : {};
  return Object.keys(environmentsJson).map((key) => ({ key, value: environmentsJson[key] }));
}

export function getAppName(): { key: string; value: string }[] {
  const appNameString = localStorage.getItem('appName');
  const appNameJson = appNameString ? JSON.parse(appNameString) : {};
  return Object.keys(appNameJson).map((key) => ({ key, value: appNameJson[key] }));
}

export function getAccessToken(): string | null {
  return localStorage.getItem('accessToken');
}

export function getUserRole(): string | null {
  return localStorage.getItem('role');
}

export function getUser(): string | null {
  return localStorage.getItem('userName');
}

export function generateToken(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function parseSecretName(secretName: string): string[] {
  // Split the string by underscores
  const parts = secretName.split('_');
  // Remove the first element ('seprate')
  parts.shift();
  return parts;
}

export function getCustomerDataFromLocalCache(name: string): any | null {
  const storedData = localStorage.getItem('customer-list');
  if (storedData) {
    const customerList: Array<{ name: string; [key: string]: any }> = JSON.parse(storedData); // Provide a structure for customer objects
    return customerList.find((customer: { name: string }) => customer.name === name); // Explicitly type `customer`
  }
  return null; // Return null if no data found
}
