// angular import
import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

// project import
import { environment } from 'src/environments/environment';
import { User } from '../_helpers/user';

// Import the 'map' operator from 'rxjs/operators'
import { map } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSignal = signal<User | null>(null);

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    // Initialize the signal with the current user from localStorage
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      this.currentUserSignal.set(JSON.parse(storedUser) as User);
    }
  }

  public get currentUserValue(): User | null {
    // Access the current user value from the signal
    return this.currentUserSignal();
  }

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  set environment(env: string) {
    localStorage.setItem('environment', env);
  }

  get environment(): string {
    return localStorage.getItem('environment') ?? '';
  }

  set userRole(role: string) {
    localStorage.setItem('role', role);
  }

  get userRole(): string {
    return localStorage.getItem('role') ?? '';
  }

  set appName(app: string) {
    localStorage.setItem('appName', app);
  }

  get appName(): string {
    return localStorage.getItem('appName') ?? '';
  }

  set userName(user: string) {
    localStorage.setItem('userName', user);
  }

  login(userName: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}login`, { userName, password }).pipe(
      map((response: any) => {
        // Check if the response indicates success
        if (response.status === 'SUCCESS') {
          this.accessToken = response.body;

          this.environment = response.environment;
          this.userRole = response.role;
          this.appName = response.app;
          this.userName = userName;

          // Store the currentUser if needed (assuming body holds relevant user info)
          const user = response.body as User;
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSignal.set(user);
          this.scheduleLogout(this.accessToken);
          return user;
        } else {
          // Throw an error if the status is not "SUCCESS"
          throw new Error(response.message || 'Login failed');
        }
      })
    );
  }

  getTokenExpiration(token: string): number {
    const decodedToken: any = jwtDecode(token);
    return decodedToken.exp * 1000; // Convert to milliseconds
  }

  scheduleLogout(token: string): void {
    const expirationTime = this.getTokenExpiration(token);
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime;
    console.log(timeUntilExpiration);
    if (timeUntilExpiration > 0) {
      setTimeout(() => {
        this.logout();
      }, timeUntilExpiration);
    } else {
      this.logout();
    }
  }

  logout() {
    // Remove user from local storage to log user out
    localStorage.clear();
    // Update the signal to null
    this.currentUserSignal.set(null);
    this.router.navigate(['/auth/auth2/login']);
  }
}
