import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit , OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { InputValidation } from 'app/shared/inputValidation';
import { MatSelectModule } from '@angular/material/select';
import { hotelkeyCredentials } from 'app/interface/i-addSecret-interface';
import { CapitalizePipe } from 'app/capitalize.pipe';

@Component({
  selector: 'app-app-hotelkey-fields',
  standalone: true,
  imports: [MatButton, MatInputModule, ReactiveFormsModule, CommonModule, MatIconModule, MatTooltip, MatSelectModule, AppHotelkeyFieldsComponent, CapitalizePipe],
  templateUrl: './app-hotelkey-fields.component.html',
  styleUrl: './app-hotelkey-fields.component.scss'
})
  export class AppHotelkeyFieldsComponent implements OnInit, OnChanges {

  @Input() appType: string;
  @Input() appBool: string;
  @Output() credentialsChanged = new EventEmitter<hotelkeyCredentials[]>();

  addSecretForm: FormGroup;
  hotelkeyForm: FormGroup;
  isDisabled: boolean = true;
  inputValidation = new InputValidation();
  isAppCredentails: boolean = false;
  environments: { key: string, value: string }[];

  constructor(private formBuilder: FormBuilder,
    public splashScreen: FuseSplashScreenService,
    private cdr: ChangeDetectorRef) { }



  ngOnInit(): void {
    this.hotelkeyForm = this.formBuilder.group({
      hotelkeyCredentials: this.formBuilder.array([])
    });
    this.addAppCredentialsField();
  }

  get hotelkeyCredentials(): FormArray {
    return this.hotelkeyForm.get('hotelkeyCredentials') as FormArray;
  }

  getHotelkeyCredentialsControls(): AbstractControl[] {
    this.hotelkeyForm.updateValueAndValidity();
    return this.hotelkeyCredentials.controls;
  }

  onCredentialsChanged(): void {
    this.credentialsChanged.emit(this.hotelkeyCredentials.value);
  }

  addAppCredentialsField(): void {
    const hotelkeyGroup = this.formBuilder.group({
      hostName: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      propertyCode: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      propertyId: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      pmsDb: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
    });
  
    hotelkeyGroup.valueChanges.subscribe(() => {
      this.hotelkeyCredentials.updateValueAndValidity();
      this.onCredentialsChanged();
    });
  
    this.hotelkeyCredentials.push(hotelkeyGroup);
    this.onCredentialsChanged();
  }

  removeAppCredentialsField(index: number): void {
    this.hotelkeyCredentials.removeAt(index);
    this.onCredentialsChanged();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appBool'] && changes['appBool'].currentValue !== undefined) {
      this.updateValidationBasedOnAppBool(changes['appBool'].currentValue);
      this.cdr.detectChanges();
    }
  }

  private updateValidationBasedOnAppBool(appBool: string): void {
    this.hotelkeyCredentials.controls.forEach(control => {
      const hostNameControl = control.get('hostName');
      const propertyCodeControl = control.get('propertyCode');
      const propertyIdControl = control.get('propertyId');
      const pmsDbControl = control.get('pmsDb');

      if (appBool === 'Webhook') {
        hostNameControl.clearValidators();
        propertyCodeControl.clearValidators();
        propertyIdControl.clearValidators();
        pmsDbControl.clearValidators();
      } else {
        hostNameControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        propertyCodeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        propertyIdControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        pmsDbControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      }
      hostNameControl.updateValueAndValidity();
      propertyCodeControl.updateValueAndValidity();
      propertyIdControl.updateValueAndValidity();
      pmsDbControl.updateValueAndValidity();
    });
    this.hotelkeyCredentials.updateValueAndValidity();
  }

}
