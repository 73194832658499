import { logListComponent } from './../../application/log/log-list/log-list.component';
import { environment } from './../../../../environments/environment.prod';
import {
    Component,
    OnInit,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DymamoDbService } from 'src/services/dynamo-db.service';
// project imports
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { BarChartComponent } from 'src/app/theme/shared/components/apexchart/bar-chart/bar-chart.component';
import { BajajChartComponent } from 'src/app/theme/shared/components/apexchart/bajaj-chart/bajaj-chart.component';
import { ChartDataMonthComponent } from 'src/app/theme/shared/components/apexchart/chart-data-month/chart-data-month.component';
import { StatisticsChartComponent } from '../../../theme/shared/components/apexchart/statistics-chart/statistics-chart.component';
import { withI18nSupport } from '@angular/platform-browser';
import { LambdalogsService } from 'src/services/lambda-logs.service';
import { Router } from '@angular/router';
import { parse } from 'path';
import { queue } from 'jquery';
import { ApexOptions, NgApexchartsModule } from 'ng-apexcharts';
import { getProcessedEnvironments } from 'src/app/theme/shared/utility';
import { throws } from 'assert';
import { NgxPaginationModule } from 'ngx-pagination';
import { Location } from '@angular/common';

@Component({
    selector: 'app-default',
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        BajajChartComponent,
        BarChartComponent,
        ChartDataMonthComponent,
        StatisticsChartComponent,
        NgApexchartsModule,
        NgxPaginationModule,
    ],
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit {
    @ViewChild('messageContainer') messageContainer!: ElementRef;

    date: string = 'today';
    currentPageItems: any[] = []; // Stores items to display on the page
    pageSize = 100; // Number of items per page
    currentIndex = 0; // Track current index
    applicationType: any;
    environment: string = 'dev';
 allCustomer:string = 'All Customers'
    responseArray: any[] = [];
    filterData: any[] = [];
    selectedTableData: any[] = [];
    previousSelectedTableData: any[] = [];
    applicationNameAndCount: any[] = [];
    CheckBoxData: any[] = [];
    customerList: any[] = [];
    selectedPayload: any;
    dataNotFound: any;
    searchValue: string = '';
    customerListDataRecord: any;
    isNextDisabled: boolean = false; // Third variable to disable button
    selectedValue: any;
    showCustomerDropdown: boolean = true;

    filterEnvironment: any;
    errorPayload: any;
    selectedCustomer: string = '';
    barApplicationName: any;
    barApplicationStatus: any;
    hasMoreData: boolean = false;
    lastEvaluatedKey: any;
    searchPagination: any;

    dashboardRequestsData = {
        totalRequestsCount: 0,
        successfulRequestsCount: 0,
        failedRequestsCount: 0,
    };
    chartOptions!: ApexOptions;
    isLoading: boolean = true;
    environments!: any[];
    categories: string[] = [];
    successCounts: number[] = [];
    failedCounts: number[] = [];
    defaultEnvironment: string = '';
    defaultDate: string = 'today';
    defaultApplicationType: string = 'sync/read';
    page: number = 1;
    currentPage: number = 1;
    itemsPerPage: number = 100;
    pageSizes: number[] = [10, 15, 20];
    selectedApplicationType: string = 'sync/read';
    showSelectedRecord = true;
    constructor(
        private location: Location,
        private dynamoDbService: DymamoDbService,
        private cdr: ChangeDetectorRef,
        private lambdalogsService: LambdalogsService,
        private router: Router,
    ) {
        this.location.replaceState('/default');
        this.environments = getProcessedEnvironments();
        this.chartOptions = {
            series: [],
            chart: {
                type: 'bar',
                height: 480,
                stacked: true,
                toolbar: { show: true },
                background: 'transparent',
                events: {
                    dataPointSelection: (
                        event: any,
                        chartContext: any,
                        config: any,
                    ) => {
                        this.handleBarClick(config);
                    },
                },
            },
            dataLabels: { enabled: false },
            colors: ['#4CAF50', '#B71C1C'],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0,
                        },
                    },
                },
            ],
            plotOptions: { bar: { horizontal: false, columnWidth: '50%' } },
            xaxis: { type: 'category', categories: [] },
            tooltip: { theme: 'light' },
        };
    }

    get filteredTableData() {
        if (!this.filterEnvironment || this.filterEnvironment === 'all') {
            return this.selectedTableData.sort(
                (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime(),
            );
        }

        return this.selectedTableData
            .filter((item) => item.environment === this.filterEnvironment)
            .sort(
                (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime(),
            );
    }

    ngOnInit(): void {
        this.fetchItems(
            this.environment,
            this.defaultDate,
            this.defaultApplicationType,
        );
        this.dynamoDbService.getSelectedTableData().subscribe((data) => {
            this.selectedTableData = data;
            this.cdr.detectChanges();
        });
        this.defaultEnvironment = 'dev';
    }

    selectRow(item: any): void {
        this.selectedPayload = item.payload;
        this.cdr.detectChanges();
    }

    reloadComponent() {
        window.location.reload();
    }
    fetchItems(
        environment: string,
        date: string,
        applicationType: string,
    ): void {
        this.searchPagination = true;
        console.log(this.showCustomerDropdown);
        this.applicationNameAndCount = [];
        this.CheckBoxData = [];
        this.isLoading = true;
        this.selectedTableData = [];
        this.previousSelectedTableData = [];
        this.customerListDataRecord = [];
        this.currentIndex = 0;
        this.dynamoDbService
            .getAllItems(
                'thynk-middleware-analytics',
                environment,
                date,
                applicationType,
            )
            .subscribe({
                next: (response) => {
                    this.applicationNameAndCount = [];
                    console.log('response --->', response);
                    if (response) {
                        this.dashboardRequestsData.totalRequestsCount =
                            response.totalCount;
                        this.dashboardRequestsData.failedRequestsCount =
                            response.failed;
                        this.dashboardRequestsData.successfulRequestsCount =
                            response.success;
                        if (
                            typeof response.applications === 'object' &&
                            response.applications !== null
                        ) {
                            this.applicationNameAndCount = [];

                            // Step 1: Push only application names first
                            Object.keys(response.applications).forEach(
                                (name) => {
                                    this.applicationNameAndCount.push({
                                        applicationName: name,
                                    });
                                },
                            );

                            // Step 2: Add success and failed counts
                            this.applicationNameAndCount.forEach((app) => {
                                const counts =
                                    response.applications[app.applicationName];

                                app.success =
                                    typeof counts?.success === 'number'
                                        ? counts.success
                                        : 0;
                                app.failed =
                                    typeof counts?.failed === 'number'
                                        ? counts.failed
                                        : 0;
                            });
                        }
                    }
                    this.CheckBoxData = this.applicationNameAndCount;
                    this.cdr.detectChanges();
                    this.updateChartData();
                    this.cdr.detectChanges();
                    this.isLoading = false;
                },

                error: (error) => {
                    console.error('Error:', error);
                    this.isLoading = false;
                },
            });
    }

    generateLogGroupName(env: String): string {
        const environment = env.toLowerCase();
        return `/lambda-thynkcloud-${environment}`;
    }

    redirectToLogs(item: any): void {
        localStorage.setItem('logs', '');
        localStorage.setItem('lambdaLogOldSearch', '');
        localStorage.setItem('lambdaNoOfDays', '');
        var middlewareId = item.middlewareId;
        const env = item.environment;
        const queueUrl = item.queueUrl;
        const payload = item.lambdaRequest;

        let parsedObject = JSON.parse(payload);
        const data = {
            [middlewareId]: parsedObject,
        };
        localStorage.setItem('parsedLogDetails', JSON.stringify(data));
        if (middlewareId) {
            this.router.navigate([
                '/log/detail',
                {
                    middlewareId: item.middlewareId,
                    middlewareLogGroupName:
                        this.lambdalogsService.createLogGroupName(queueUrl),
                    logGroupName: this.generateLogGroupName(env),
                },
            ]);
        } else {
            console.error(
                'No log found with the provided middlewareJobId:',
                item.middlewareId,
            );
        }
        this.cdr.detectChanges();
    }

    redirectToSalesforce(obj: any) {
        try {
            const data = JSON.parse(obj.errorMessage);
            if (!data.salesforceURl) {
                throw new Error('salesforceURl is missing in errorMessage');
            }
            const domain = data.salesforceURl.startsWith('http')
                ? data.salesforceURl
                : `https://${data.salesforceURl}`;

            if (!data.id) {
                throw new Error('Salesforce record ID is missing');
            }
            const viewUrl = `${domain}/lightning/r/${data.id}/view`;
            window.open(viewUrl, '_blank');
        } catch (error) {
            console.error('Error in redirectToSalesforce:', 'error.message');
        }
    }

    private updateChartData() {
        console.log(
            ' application nam and count is -->',
            this.applicationNameAndCount,
        );
        this.showSelectedRecord = true;
        this.categories = [];
        this.successCounts = [];
        this.failedCounts = [];
        this.cdr.detectChanges();
        this.applicationNameAndCount.forEach((app) => {
            this.categories.push(app.applicationName.toUpperCase());
            this.successCounts.push(app.success ?? 0);
            this.failedCounts.push(app.failed ?? 0);
        });
        this.chartOptions = {
            ...this.chartOptions,
            series: [
                { name: 'Success', data: this.successCounts },
                { name: 'Failed', data: this.failedCounts },
            ],
            xaxis: { ...this.chartOptions.xaxis, categories: this.categories },
        };

        this.cdr.detectChanges();
    }

    handleBarClick(config: any) {
        this.searchPagination = true;

        this.previousSelectedTableData = [];
        this.isLoading = true;
        this.customerList = [];
        this.barApplicationName = '';
        this.barApplicationStatus = '';
        this.dataNotFound = '';
        this.searchValue = '';
        this.selectedTableData = [];
        this.itemsPerPage = 100;
        this.updatePageSize();
        this.cdr.detectChanges();
        this.showSelectedRecord = true;
        this.errorPayload = '';
        this.selectedCustomer = 'All Customers';
        this.currentIndex = 0;
        this.responseArray = [];
        const clickedCategory =
            this.chartOptions.xaxis?.categories?.[config.dataPointIndex];
        const series = this.chartOptions.series as {
            name: string;
            data: number[];
        }[];
        const clickedSeries = series?.[config.seriesIndex]?.name.toLowerCase();
        this.barApplicationName = clickedCategory;
        this.barApplicationStatus = clickedSeries;
        this.currentPage = 1;
        console.log(
            'appi request data is -->',
            this.defaultEnvironment,
            this.defaultDate,
            this.defaultApplicationType,
            clickedCategory,
            clickedSeries.toLowerCase() === 'success' ? 'true' : 'false',
        );
        this.dynamoDbService
            .getApplicationItems(
                'thynk-middleware-analytics',
                this.defaultEnvironment.toLowerCase(),
                this.defaultDate.toLowerCase(),
                this.defaultApplicationType.toLowerCase(),
                clickedCategory,
                clickedSeries.toLowerCase() === 'success' ? 'true' : 'false',
                '',
            )
            .subscribe({
                next: (response) => {
                    console.log('data response is', response);
                    this.selectedTableData = response.items;
                    this.hasMoreData = response.hasMoreData;
                    this.lastEvaluatedKey = response.lastEvaluatedKey;
                    // if(this.hasMoreData) this.currentPage++;
                    this.previousSelectedTableData = this.selectedTableData;
                    this.customerListDataRecord =
                        this.previousSelectedTableData;
                    this.responseArray = this.customerListDataRecord;
                    console.log('data ', this.previousSelectedTableData);
                    this.populateCustomerList();
                    this.dynamoDbService.updateSelectedTableData(
                        this.selectedTableData,
                    );
                    this.loadPage();
                    this.isLoading = false;
                },
            });
    }

    loadPage() {
        console.log('dat', this.previousSelectedTableData);
        console.log(this.currentIndex, this.pageSize);
        this.isNextDisabled = false; // Disable button
        const start = this.currentIndex;
        const end = start + this.pageSize;
        this.selectedTableData = this.previousSelectedTableData.slice(
            start,
            end,
        );
        console.log(this.previousSelectedTableData.length);
        console.log(this.selectedTableData);

        this.cdr.detectChanges();
    }
    nextPage() {
        console.log('into next method');
        this.currentIndex += this.pageSize;
        console.log(
            this.currentIndex + ' ===' + this.customerListDataRecord.length,
        );
        if (this.currentIndex >= this.previousSelectedTableData.length) {
            console.log(this.lastEvaluatedKey);
            if (this.lastEvaluatedKey) {
                console.log('app', this.selectedCustomer);
                if (this.searchPagination) {
                    console.log(
                        'setting loading',
                        this.isLoading,
                        this.searchPagination,
                    );
                    this.isLoading = true;
                    this.dynamoDbService
                        .getSerchedRecord(
                            'thynk-middleware-analytics',
                            this.defaultEnvironment.toLowerCase(),
                            this.defaultDate.toLowerCase(),
                            this.defaultApplicationType.toLowerCase(),
                            this.barApplicationName,
                            this.barApplicationStatus.toLowerCase() ===
                                'success'
                                ? 'true'
                                : 'false',
                            this.selectedValue,
                        )
                        .subscribe({
                            next: (response) => {
                                console.log('data response is', response);
                                if (response.items) {
                                    this.lastEvaluatedKey =
                                        response.lastEvaluatedKey;
                                    this.previousSelectedTableData = [
                                        ...this.previousSelectedTableData,
                                        ...response.items,
                                    ];
                                } else {
                                    this.dataNotFound = `No record found for the entered value`;
                                    return;
                                }
                                //  this.customerListDataRecord = [...this.customerListDataRecord, ...response.items];
                                //    this.lastEvaluatedKey = response.lastEvaluatedKey;
                                // this.previousSelectedTableData = response.items;

                                this.isLoading = false;
                                this.loadPage();
                                this.cdr.detectChanges();
                            },
                        });
                } else if (this.selectedCustomer != 'All Customers') {
                    this.isNextDisabled = false;
                    console.log('app', this.selectedCustomer);
                    this.currentIndex -= this.pageSize;
                    this.isNextDisabled = true;
                    this.cdr.detectChanges();
                } else {
                    console.log('in to if ');
                    this.loadMoreData();
                }
            } else if (this.selectedCustomer !== 'All Customers') {
                this.currentIndex -= this.pageSize;
                this.isNextDisabled = true;
                this.cdr.detectChanges();
            } else {
                this.currentIndex -= this.pageSize;
                this.isNextDisabled = true;
                this.cdr.detectChanges();
            }
        } else {
            console.log('in to load page method from next');
            this.loadPage(); // Load from existing data
            this.cdr.detectChanges();
        }
    }
    prevPage() {
        this.isNextDisabled = false; // Disable button
        if (this.currentIndex > 0) {
            this.currentIndex -= this.pageSize;
            this.loadPage();
        }
    }
    loadMoreData() {
        this.isLoading = true;
        this.isNextDisabled = true;
        this.cdr.detectChanges();
        console.log('sending api hit for 2nd time');
        console.log(this.barApplicationName, this.barApplicationStatus);
        this.dynamoDbService
            .getApplicationItems(
                'thynk-middleware-analytics',
                this.defaultEnvironment.toLowerCase(),
                this.defaultDate.toLowerCase(),
                this.defaultApplicationType.toLowerCase(),
                this.barApplicationName,
                this.barApplicationStatus.toLowerCase() === 'success'
                    ? 'true'
                    : 'false',
                this.lastEvaluatedKey,
            )
            .subscribe({
                next: (response) => {
                    console.log('data response is', response);
                    this.customerListDataRecord = [
                        ...this.customerListDataRecord,
                        ...response.items,
                    ];
                    this.lastEvaluatedKey = response.lastEvaluatedKey;
                    this.previousSelectedTableData =
                        this.customerListDataRecord;
                    //  this.customerListDataRecord = this.previousSelectedTableData;
                    this.populateCustomerList();
                    this.dynamoDbService.updateSelectedTableData(
                        this.selectedTableData,
                    );
                    this.loadPage();
                    this.isLoading = false;
                    // this.loadPage();

                    this.cdr.detectChanges();
                },
            });
    }

    populateCustomerList() {
        this.customerList = [];
         this.customerList.push('All Customers');
        console.log(this.selectedTableData);

        this.previousSelectedTableData.forEach((item) => {
            if (item.secretkey) {
                console.log('secret key ', item.secretkey);
                let regex: RegExp = /thynk_(.*?)_(?=\w+_)/;
                let match: RegExpMatchArray | null =
                    item.secretkey.match(regex);

                let result = '';

                if (match) {
                    result = match[1];
                } else {
                    console.warn(
                        'No match found for secretName:',
                        item.secretkey,
                    );
                }
                if (result && !this.customerList.includes(result)) {
                    this.customerList.push(result);
                }
            } else {
                console.warn("Invalid or missing 'secretName' in item:", item);
            }
        });
    }

    CustomerChange(event: any) {
        this.searchPagination = false;
        this.searchValue = '';
        this.showSelectedRecord = true;
        this.errorPayload = '';
        // this.selectedTableData = this.previousSelectedTableData;
        // this.dynamoDbService.updateSelectedTableData(this.selectedTableData);
        this.previousSelectedTableData = this.customerListDataRecord;
        const selectedValue = event.target.value.trim().toLowerCase();
        // this.allCustomer=selectedValue;
        this.selectedCustomer = selectedValue;
        const filteredData = this.previousSelectedTableData.filter((item) => {
            return (
                item.secretkey &&
                item.secretkey
                    .toString()
                    .toLowerCase()
                    .includes(this.selectedCustomer.toLowerCase()) &&
                Object.values(item).some((value: any) => {
                    if (value !== undefined && value !== null) {
                        return value
                            .toString()
                            .toLowerCase()
                            .includes(selectedValue.toLowerCase());
                    }
                })
            );
        });
        if (filteredData.length > 0) {
            // this.itemsPerPage=10;
            this.updatePageSize();
            this.previousSelectedTableData = filteredData;
            this.dataNotFound = '';
            this.cdr.detectChanges();
        } else {
            if (selectedValue === 'all customers') {
                this.dataNotFound = '';
                this.selectedCustomer = 'All Customers';
                this.previousSelectedTableData = this.customerListDataRecord;
                // this.showSelectedRecord = false;
            } else {
                console.error('No matching record found');
            }
        }
        this.dynamoDbService.updateSelectedTableData(
            this.customerListDataRecord,
        );
        this.currentIndex = 0;
        this.loadPage();
        this.cdr.detectChanges();
    }

    resetMethod() {
        this.onMiddlewareChanges('');
        this.searchValue = '';
        this.searchPagination=false;
        // this.allCustomer='All Customers'
      //   this.CustomerChange('All Customers')
      this.allCustomer='All Customers';
         console.log(this.customerList);
        //  this.cdr.detectChanges();
        //   this.customerList=[];
        //   this.populateCustomerList();
        this.cdr.detectChanges();
    }

    onMiddlewareChanges(event: any) {
        this.isLoading = true;
        // this.showSelectedRecord = true;
        this.errorPayload = '';
        this.previousSelectedTableData = [];
        this.dataNotFound = '';
        this.searchPagination = false;
        this.selectedValue = '';
        // this.selectedTableData = this.previousSelectedTableData;
        console.log(event);
        const selectedValue = event.trim();
        this.selectedValue = selectedValue;
        if (selectedValue.length == 0) {
            this.searchPagination = true;
            this.showCustomerDropdown = true;
            this.isLoading = false;
            this.previousSelectedTableData = this.customerListDataRecord;
            this.currentIndex = 0;
            console.log(this.customerListDataRecord);
            console.log(this.responseArray);
            this.cdr.detectChanges();
            this.loadPage();
            return;
        }
        if (selectedValue.length < 8) {
            this.dataNotFound = `No record found for the entered value`;
            return;
        }
        this.showCustomerDropdown = false;
        console.log(
            this.defaultEnvironment.toLowerCase(),
            this.defaultDate.toLowerCase(),
            this.defaultApplicationType.toLowerCase(),
            this.barApplicationName,
            this.barApplicationStatus.toLowerCase() === 'success'
                ? 'true'
                : 'false',
            selectedValue,
        );

        this.dynamoDbService
            .getSerchedRecord(
                'thynk-middleware-analytics',
                this.defaultEnvironment.toLowerCase(),
                this.defaultDate.toLowerCase(),
                this.defaultApplicationType.toLowerCase(),
                this.barApplicationName,
                this.barApplicationStatus.toLowerCase() === 'success'
                    ? 'true'
                    : 'false',
                selectedValue,
            )
            .subscribe({
                next: (response) => {
                    console.log('data response is', response);
                    if (response.items) {
                        this.lastEvaluatedKey = response.lastEvaluatedKey;
                        if (response.lastEvaluatedKey) {
                            this.searchPagination = true;
                        }
                        this.previousSelectedTableData = response.items;
                    } else {
                        this.dataNotFound = `No record found for the entered value`;
                        this.isLoading = false;
                        return;
                    }
                    //  this.customerListDataRecord = [...this.customerListDataRecord, ...response.items];
                    this.lastEvaluatedKey = response.lastEvaluatedKey;
                    this.previousSelectedTableData = response.items;
                    //  this.customerListDataRecord = this.previousSelectedTableData;
                    // this.populateCustomerList();
                    // this.dynamoDbService.updateSelectedTableData(
                    //     this.selectedTableData,
                    // );
                    this.loadPage();
                    this.isLoading = false;
                    // this.loadPage();

                    this.cdr.detectChanges();
                },
            });
        this.dynamoDbService.updateSelectedTableData(this.selectedTableData);

        // if (selectedValue.length == 0) {
        //     this.dataNotFound = '';
        //     this.selectedTableData = this.previousSelectedTableData;
        //     return;
        // }

        // if (selectedValue.length < 8) {
        //     this.dataNotFound = `No record found for the entered value`;
        //     this.dynamoDbService.updateSelectedTableData([{}]);
        //     this.showSelectedRecord = false;

        //     return false;
        // }

        // const filteredData = this.selectedTableData.filter((item) => {
        //     if (this.selectedCustomer === 'All Customers') {
        //         return Object.values(item).some((value: any) => {
        //             if (value !== undefined && value !== null) {
        //                 return value
        //                     .toString()
        //                     .toLowerCase()
        //                     .includes(selectedValue);
        //             }
        //         });

        //     }
        //     else{
        //         return (
        //             item.secretName && item.secretName.toString().toLowerCase().includes(this.selectedCustomer.toLowerCase()) &&
        //             Object.values(item).some((value: any) => {
        //                 if (value !== undefined && value !== null) {
        //                     return value.toString().toLowerCase().includes(selectedValue.toLowerCase());
        //                 }
        //             })
        //         );
        //     }

        // });

        // if (filteredData.length > 0) {
        //     // this.itemsPerPage=10;
        //     this.updatePageSize();
        //     this.selectedTableData = filteredData;
        //     this.dataNotFound = '';
        //     this.cdr.detectChanges();
        // } else {

        //     this.dataNotFound = `No record found for the entered value`;
        //     this.selectedTableData = [{}];
        //     this.showSelectedRecord = false;

        // }
        // this.dynamoDbService.updateSelectedTableData(this.selectedTableData);
    }

    redirectToPayload(message: any) {
        setTimeout(() => {
            const element = document.getElementById('messageContainer');
            if (element) {
                const yOffset = -200;
                const y =
                    element.getBoundingClientRect().top +
                    window.scrollY +
                    yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }, 100);

        this.errorPayload = message.errorMessage
            ? JSON.parse(message.errorMessage)
            : {
                  middlewareJobId: message.middlewareJobId,
                  success: true,
                  error: [],
              };
        this.cdr.detectChanges();
    }

    async fetchDataFromAPI() {
        // this.selectedApplicationType = '';
        this.searchPagination = true;
        if (this.defaultEnvironment != null && this.defaultDate != null) {
            this.showSelectedRecord = true;
            this.selectedTableData = [];
            this.cdr.detectChanges();

            this.dashboardRequestsData = {
                totalRequestsCount: 0,
                successfulRequestsCount: 0,
                failedRequestsCount: 0,
            };
            this.responseArray = [];
            this.filterData = [];
            this.applicationNameAndCount = [];
            this.cdr.detectChanges();
            await this.fetchItems(
                this.defaultEnvironment,
                this.defaultDate,
                this.defaultApplicationType,
            );
            await this.filterEnvData(this.applicationType);
        } else {
            this.filterData;
        }
        this.cdr.detectChanges();
    }

    async onChnageData(event: any) {
        this.showSelectedRecord = true;
        // this.selectedTableData = [];
        this.cdr.detectChanges();

        var selectedEvent = event.target.name;

        if (selectedEvent === 'selectedEnvironment') {
            this.environment = event.target.value;
            this.dashboardRequestsData = {
                totalRequestsCount: 0,
                successfulRequestsCount: 0,
                failedRequestsCount: 0,
            };
            this.responseArray = [];
            this.filterData = [];
            this.applicationNameAndCount = [];
            this.cdr.detectChanges();
        } else if (selectedEvent === 'selectedDate') {
            this.defaultDate = event.target.value;
            this.dashboardRequestsData = {
                totalRequestsCount: 0,
                successfulRequestsCount: 0,
                failedRequestsCount: 0,
            };
            this.responseArray = [];
            this.filterData = [];
            this.applicationNameAndCount = [];
            this.cdr.detectChanges();
        } else if (selectedEvent === 'selectedApplicationType') {
            this.applicationType = event.target.value;
            this.defaultApplicationType = event.target.value;
            // this.filterEnvData(this.applicationType);
        } else {
            this.filterData;
        }
        this.cdr.detectChanges();
    }

    filterEnvData(applicationType: any) {
        let filteredRecords = this.responseArray;
        if (applicationType === 'webhook' || applicationType === 'sync/read') {
            filteredRecords = filteredRecords.filter(
                (item) => item.applicationType === applicationType,
            );
        }

        this.filterData = filteredRecords;
        this.updateChartData();
        this.cdr.detectChanges();
    }

    filterRecordWithDateString(date: any): string {
        let resultDate = '';

        if (date === 'today') {
            const today = new Date().toISOString().split('T')[0];
            resultDate = today;
        } else if (date === 'week') {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 7);
            resultDate = currentDate.toISOString().split('T')[0];
        } else if (date === 'month') {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 30);
            resultDate = currentDate.toISOString().split('T')[0];
        } else if (date === 'year') {
            const currentDate = new Date();
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            resultDate = currentDate.toISOString().split('T')[0];
        } else {
            resultDate = '';
        }

        return resultDate;
    }

    toggleApplicationSelection(
        isChecked: boolean,
        applicationName: any,
        index: any,
    ): void {
        this.selectedTableData = [];
        if (isChecked) {
            this.addApplication(applicationName, index);
        } else {
            this.removeApplication(applicationName);
        }
    }

    addApplication(applicationName: string, index: any): void {
        var totalCount = 0;
        const successCount = this.CheckBoxData.reduce((count, data) => {
            if (data.applicationName === applicationName) {
                return count + data.success; // Accumulate success values
            }
            return count; // Return the current count if no match
        }, 0); // Initial count is 0

        const failedCount = this.CheckBoxData.reduce((count, data) => {
            if (data.applicationName === applicationName) {
                return count + data.failed; // Accumulate failed values
            }
            return count; // Return the current count if no match
        }, 0); // Initial count is 0

        this.applicationNameAndCount.push({
            applicationName: applicationName.toLowerCase(),
            success: successCount,
            failed: failedCount,
        });

        this.updateChartData();
        this.cdr.detectChanges();
    }
    updatePageSize() {
        this.page = 1;
        this.cdr.detectChanges();
    }

    removeApplication(applicationName: string): void {
        console.log(applicationName);
        this.applicationNameAndCount = this.applicationNameAndCount.filter(
            (item) => {
                return item.applicationName != applicationName;
            },
        );
        this.updateChartData();
        this.cdr.detectChanges();
    }
    onPageChange(event: number) {
        //     if (event > this.currentPage) {  // Sirf "Next" button click hone par API call karein

        //         if (this.isLoading || !this.hasMoreData) return;
        //         this.dynamoDbService.getApplicationItems('thynk-middleware-analytics',this.defaultEnvironment.toLowerCase(),this.defaultDate.toLowerCase(),this.defaultApplicationType.toLowerCase(),this.barApplicationName,this.barApplicationStatus.toLowerCase() === 'success' ? 'true' : 'false','')
        //         .subscribe({
        //             next:(response) =>{
        //                 console.log("data response is",response);
        //                 this.selectedTableData = [...this.selectedTableData, ...response.items];
        //                 this.hasMoreData = response.hasMoreData;
        //                 if(this.hasMoreData) this.currentPage++;
        //             }
        //         })

        //         this.previousSelectedTableData = this.selectedTableData;
        //         this.populateCustomerList();
        //         this.dynamoDbService.updateSelectedTableData(
        //             this.selectedTableData,
        //         );
        //    }
        this.page = event;
        this.cdr.detectChanges();
    }
}
