import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecretsDetailsComponent } from './modules/admin/secrets-details/secrets-details.component';
import { AddSecretsComponent } from './modules/admin/add-secrets/add-secrets.component';
import { JwtGuideDocumentComponent } from './modules/admin/secrets-details/jwt-guide-document/jwt-guide-document.component';
import { UpdateSecretDetailsComponent } from './modules/admin/update-secret-details/update-secret-details.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
const routes: Routes = [
    { path: 'secret-details/:name', component: SecretsDetailsComponent },
    { path: 'addSecrets', component: AddSecretsComponent },
    { path: 'guideDocument', component: JwtGuideDocumentComponent },
    { path: 'updateDetails', component: UpdateSecretDetailsComponent },
];

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'secrets' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'secrets' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.routes'
                    ),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: 'secrets',
                loadChildren: () =>
                    import('app/modules/admin/secrets/secrets.routes'),
            },
            {
                path: 'secretsDetails',
                loadChildren: () =>
                    import(
                        'app/modules/admin/secrets-details/secrets-details.route'
                    ),
            },
            {
                path: 'addSecrets',
                loadChildren: () =>
                    import('app/modules/admin/add-secrets/add-secrets.routes'),
            },
            {
                path: 'cloudwatchlogs/:middlewareJobId/:middlewareLogGroupName/:lambdaGroupName/:parsedLogDetails',
                loadChildren: () =>
                    import(
                        'app/modules/admin/cloudwatch-logs/cloudwatch-logs/cloudwatch-logs.routes'
                    ),
            },
            {
                path: 'lambdalogs',
                loadChildren: () =>
                    import(
                        'app/modules/admin/lambda-logs/lambda-logs/lambda-logs.routes'
                    ),
            },
            {
                path: 'guideDocument',
                loadChildren: () =>
                    import(
                        'app/modules/admin/secrets-details/jwt-guide-document/jwt-guide-document.route'
                    ),
            },
            {
                path: 'updateDetails',
                loadChildren: () =>
                    import(
                        'app/modules/admin/update-secret-details/update-secret-details.route'
                    ),
            },
            {
                path: 'connectedAppGuide',
                loadChildren: () =>
                    import(
                        'app/modules/admin/connected-app-guide/connected-app-guide.route'
                    ),
            },
        ],
    },
];
