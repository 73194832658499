import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../../src/app/theme/shared/shared.module';
import { generateToken } from '../../../../../../src/app/theme/shared/utility';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-ohip-form',
    templateUrl: './ohip-form.component.html',
    standalone: true,
    imports: [CommonModule, SharedModule],
})
export class OhipFormComponent implements OnDestroy {
    @Input() applicationType!: string;
    @Output() formSubmit = new EventEmitter<any>();
    @Input() editMode: boolean = false;
    @Input() initialData: any;

    pmsForm!: FormGroup;
    private onDestroy$ = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.initializeForm();
        if (this.editMode && this.initialData) {
            this.populateForm(this.initialData);
        } else {
            this.renderForms();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['applicationType'] &&
            !changes['applicationType'].firstChange
        ) {
            this.renderForms();
        }
        if (
            changes['editMode'] &&
            changes['editMode'].currentValue &&
            this.initialData
        ) {
            this.populateForm(this.initialData);
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private initializeForm(): void {
        this.pmsForm = this.fb.group({
            pmsCredentials: this.fb.array([]),
        });
    }

    private createCredentialGroup(data?: any): FormGroup {
        const isWebhook = this.applicationType === 'webhook';
        const callBackValue = data?.callBack || false;
        const group = this.fb.group({
            client_id: [
                data?.client_id || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            client_secret: [
                data?.client_secret || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            x_app_key: [
                data?.x_app_key || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            username: [
                data?.username || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            password: [
                data?.password || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            enterpriseId: [
                data?.enterpriseId || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            scope: [
                data?.scope || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            grant_type: [
                data?.grant_type || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            extSystemCode: [
                data?.extSystemCode || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            url: [
                data?.url || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            pmsDb: [data?.pmsDb || ''],
            propertyId: [
                data?.propertyId || '',
                this.getDynamicValidators(isWebhook, callBackValue),
            ],
            callBack: [callBackValue],
            objectType: [
                data?.objectType || '',
                isWebhook ? Validators.required : null,
            ],
            apiSource: [
                data?.apiSource || '',
                isWebhook ? Validators.required : null,
            ],
            syncStatus: [
                data?.syncStatus || '',
                isWebhook ? Validators.required : null,
            ],
            webhookSecretKey: [
                {
                    value: data?.webhookSecretKey || generateToken(25),
                    disabled: false,
                },
                isWebhook ? Validators.required : null,
            ],
        });

        if (isWebhook) {
            this.subscribeToCallBackChanges(group);
        }

        return group;
    }

    private subscribeToCallBackChanges(group: FormGroup): void {
        const callBackControl = group.get('callBack');
        if (callBackControl) {
            callBackControl.valueChanges
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((isChecked: boolean | null) => {
                    this.updateFieldValidators(group, isChecked);
                });
        }
    }

    private updateFieldValidators(
        group: FormGroup,
        isChecked: boolean | null,
    ): void {
        const fieldsToUpdate = [
            'client_id',
            'client_secret',
            'x_app_key',
            'grant_type',
            'username',
            'url',
            'extSystemCode',
            'enterpriseId',
            'scope',
            'pmsDb',
            'propertyId',
        ];
        fieldsToUpdate.forEach((fieldName) => {
            const control = group.get(fieldName);
            if (control) {
                if (fieldName === 'pmsDb') {
                    control.setValue('');
                } else if (isChecked) {
                    control.setValidators([Validators.required]);
                    control.setValue('');
                } else {
                    control.clearValidators();
                    control.setValue('');
                }
                control.updateValueAndValidity();
            }
        });
    }

    private getDynamicValidators(
        isWebhook: boolean,
        callBack?: boolean,
    ): Validators[] {
        if (isWebhook) {
            return callBack ? [Validators.required] : [];
        }
        return [Validators.required];
    }

    private manageFieldArray(
        action: 'add' | 'remove',
        index?: number,
        data?: any,
    ): void {
        if (action === 'add') {
            this.pmsCredentials.push(this.createCredentialGroup(data));
        } else if (action === 'remove' && index !== undefined) {
            this.pmsCredentials.removeAt(index);
        }
    }

    addAppCredentialsField(): void {
        this.manageFieldArray('add');
    }

    private populateForm(data: any): void {
        if (!this.pmsForm) {
            console.error('pmsForm is undefined');
            return;
        }
        if (!data) {
            console.error('No data provided for populating the form');
            return;
        }

        let values;
        try {
            values = typeof data === 'string' ? JSON.parse(data) : data;
        } catch (error) {
            console.error('Error parsing initialData:', error);
            return;
        }

        this.pmsCredentials.clear();
        values.forEach((credential: any) => {
            this.manageFieldArray('add', undefined, credential);
            if (credential?.grant_type) {
                const index = this.pmsCredentials.length - 1;
                this.onChangeGrantType(credential.grant_type, index);
            }
        });

        this.pmsForm.updateValueAndValidity();
    }

    private renderForms(): void {
        this.pmsCredentials.clear();
        this.addAppCredentialsField();
    }

    get pmsCredentials(): FormArray {
        return this.pmsForm.get('pmsCredentials') as FormArray;
    }

    getPmsCredentialsControls(): AbstractControl[] {
        return this.pmsCredentials.controls;
    }

    removeAppCredentialsField(index: number): void {
        this.manageFieldArray('remove', index);
    }

    shouldShowField(credential: AbstractControl, fieldName?: string): boolean {
        const grantType = credential.get('grant_type')?.value;

        // If it's a webhook, ensure callBack is checked
        if (
            !(
                credential.get('callBack')?.value ||
                this.applicationType !== 'webhook'
            )
        ) {
            return false;
        }

        // Handle field visibility based on grant_type
        if (fieldName === 'username' || fieldName === 'password') {
            return grantType === 'password';
        }

        if (fieldName === 'enterpriseId' || fieldName === 'scope') {
            return grantType === 'client_credentials';
        }

        return true; // Default case for other fields
    }

    isInvalid(controlName: string, index: number): boolean {
        const control = (this.pmsCredentials.at(index) as FormGroup).get(
            controlName,
        );
        return control ? control.invalid && control.touched : false;
    }

    onSubmit(): void {
        this.cdr.detectChanges();
        if (this.pmsForm.invalid) {
            return;
        }
        this.formSubmit.emit(this.pmsForm.getRawValue());
    }

    onCancel(): void {
        this.router.navigate(['/customer/list']);
    }

    onChangeGrantType(e: string, index: number) {

        const credential = this.pmsCredentials?.at(index) as FormGroup;
        if (!credential) {
            console.error(`Credential at index ${index} is undefined.`);
            return;
        }

        const usernameControl = credential.get('username');
        const passwordControl = credential.get('password');
        const enterpriseIdControl = credential.get('enterpriseId');
        const scopeControl = credential.get('scope');

        if (e === 'password') {
            // Show username and password fields
            usernameControl?.setValidators([Validators.required]);
            passwordControl?.setValidators([Validators.required]);

            // Hide enterpriseId and scope
            enterpriseIdControl?.clearValidators();
            scopeControl?.clearValidators();

            // Set values to empty when hidden
            enterpriseIdControl?.setValue('');
            scopeControl?.setValue('');
        }

        if (e === 'client_credentials') {
            // Hide username and password fields
            usernameControl?.clearValidators();
            passwordControl?.clearValidators();

            // Show enterpriseId and scope
            enterpriseIdControl?.setValidators([Validators.required]);
            scopeControl?.setValidators([Validators.required]);

            // Set values to empty when hidden
            usernameControl?.setValue('');
            passwordControl?.setValue('');
        }

        // Update validation state
        usernameControl?.updateValueAndValidity();
        passwordControl?.updateValueAndValidity();
        enterpriseIdControl?.updateValueAndValidity();
        scopeControl?.updateValueAndValidity();
    }
}
