<div *ngIf="appName === 'mews'" class="addFormContainer">
    <div class="column text-2xl font-extrabold tracking-tight mt-6">
        <div class="appSelection">
            <div class="colHeading mb-4">App Credentials</div>
        </div>
        <div class="actionRowBtn" *ngIf="appType === 'Sync/Read'">
            <button
                (click)="addAppCredentialsField()"
                class="ml-3 w-28"
                mat-flat-button
                type="button"
                [color]="'primary'"
            >
                <mat-icon
                    class="icon-size-4"
                    [svgIcon]="'heroicons_outline:plus'"
                ></mat-icon>
                <span class="ml-2">Add New</span>
            </button>
        </div>
    </div>

    <hr />

    <div [formGroup]="mewsForm" class="addFormContainer">
        <div formArrayName="mewsCredentials">
            <div
                *ngFor="
                    let credentialsGroup of getMewsCredentialsControls();
                    let i = index
                "
                [formGroupName]="i"
            >
                <div *ngIf="appType === 'Webhook'">
                    <div class="column">
                        <div
                            class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading"
                        >
                            {{ appName | capitalize }} Webhook Information
                        </div>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Object Type</mat-label>
                            <input
                                matInput
                                placeholder="Object Type"
                                formControlName="objectType"
                            />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>API Source</mat-label>
                            <input
                                matInput
                                placeholder="API Source"
                                formControlName="apiSource"
                            />
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Sync Status</mat-label>
                            <input
                                matInput
                                placeholder="Sync Status"
                                formControlName="syncStatus"
                            />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Webhook Api Key</mat-label>
                            <input
                                matInput
                                placeholder="Webhook Api Key"
                                formControlName="webhookSecretKey"
                                [ngStyle]="{ cursor: 'not-allowed' }"
                            />
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="appType === 'Sync/Read' || showSyncReadFields">
                    <div class="column">
                        <div
                            *ngIf="appType === 'Sync/Read'"
                            class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading"
                        >
                            {{ appName | capitalize }} App Information
                        </div>
                        <div
                            class="actionRowBtn"
                            *ngIf="appType === 'Sync/Read'"
                        >
                            <button
                                class="-ml-4"
                                mat-button
                                [color]="'warn'"
                                (click)="removeAppCredentialsField(i)"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Client Name</mat-label>
                            <input
                                matInput
                                formControlName="client_name"
                                placeholder="Client Name"
                            />
                            <mat-error
                                *ngIf="
                                    credentialsGroup
                                        .get('client_name')
                                        .hasError('required')
                                "
                                >Client name is required</mat-error
                            >
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>API URL</mat-label>
                            <input
                                matInput
                                formControlName="api_url"
                                placeholder="API URL"
                            />
                            <mat-error
                                *ngIf="
                                    credentialsGroup
                                        .get('api_url')
                                        .hasError('required')
                                "
                                >API Url is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Client Token</mat-label>
                            <input
                                matInput
                                formControlName="client_token"
                                placeholder="Client Token"
                            />
                            <mat-error
                                *ngIf="
                                    credentialsGroup
                                        .get('client_token')
                                        .hasError('required')
                                "
                                >Client token is required</mat-error
                            >
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Access Token</mat-label>
                            <input
                                matInput
                                formControlName="access_token"
                                placeholder="Access Token"
                            />
                            <mat-error
                                *ngIf="
                                    credentialsGroup
                                        .get('access_token')
                                        .hasError('required')
                                "
                                >Access token is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="column" *ngIf="appType === 'Sync/Read'">
                        <mat-form-field appearance="fill">
                            <mat-label>PMS DB</mat-label>
                            <input
                                matInput
                                formControlName="pmsDb"
                                placeholder="PMS DB"
                            />
                            <mat-error
                                *ngIf="
                                    credentialsGroup
                                        .get('pmsDb')
                                        .hasError('required')
                                "
                                >PMS DB is required</mat-error
                            >
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Property ID</mat-label>
                            <input
                                matInput
                                formControlName="propertyId"
                                placeholder="Property ID"
                            />
                            <mat-error
                                *ngIf="
                                    credentialsGroup
                                        .get('propertyId')
                                        .hasError('required')
                                "
                                >Property ID is required</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="appType === 'Webhook'">
                    <mat-checkbox
                        formControlName="showSyncReadFields"
                        (change)="toggleSyncReadFields()"
                    >
                        Callback functionality
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>
