export function getProcessedEnvironments(): { key: string, value: string }[] {
    const environmentsJson = JSON.parse(localStorage.getItem('environment')) || {};
    const environmentsArray = Object.keys(environmentsJson).map(key => {
        return { key: key, value: environmentsJson[key] };
    });
    return environmentsArray;
}

export function getAppName(): { key: string, value: string }[] {
    const appNameJson = JSON.parse(localStorage.getItem('appName')) || {};
    const appNameArray = Object.keys(appNameJson).map(key => {
        return { key: key, value: appNameJson[key] };
    });
    return appNameArray;
}

export function getAccessToken(): string | null {
    return localStorage.getItem('accessToken');
}

export function getUserRole(): string | null {
    return localStorage.getItem('role');
}

export function getUser(): string | null {
    return localStorage.getItem('userName');
}

export function generateToken(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }