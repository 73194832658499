import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { InputValidation } from 'app/shared/inputValidation';
import { MatSelectModule } from '@angular/material/select';
import { sertifiCredentials } from 'app/interface/i-addSecret-interface';
import { CapitalizePipe } from 'app/capitalize.pipe';
import { generateToken } from 'app/shared/utility';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
  selector: 'app-app-sertifi-fields',
  standalone: true,
  imports: [MatButton, MatInputModule, ReactiveFormsModule, MatCheckboxModule, MatFormFieldModule,  CommonModule, MatIconModule, MatTooltip, MatSelectModule, AppSertifiFieldsComponent, CapitalizePipe],
  templateUrl: './app-sertifi-fields.component.html',
  styleUrl: './app-sertifi-fields.component.scss'
})
export class AppSertifiFieldsComponent implements OnInit, OnChanges  {

  @Input() appType: string;
  @Input() appName: string;
  @Input() appBool: string;
  @Output() credentialsChanged = new EventEmitter<sertifiCredentials[]>();
  @Output() showSyncReadFieldsChange = new EventEmitter<boolean>();

  addSecretForm: FormGroup;
  sertifiForm: FormGroup;
  isDisabled: boolean = true;
  inputValidation = new InputValidation();
  isAppCredentails: boolean = false;
  environments: { key: string, value: string }[];
  showSyncReadFields = false;

  constructor(private formBuilder: FormBuilder,
    public splashScreen: FuseSplashScreenService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.sertifiForm = this.formBuilder.group({
      sertifiCredentials: this.formBuilder.array([])
    });
    this.addAppCredentialsField();
  }

  get sertifiCredentials(): FormArray {
    return this.sertifiForm.get('sertifiCredentials') as FormArray;
  }

  getSertifiCredentialsControls(): AbstractControl[] {
    return this.sertifiCredentials.controls;
  }

  onCredentialsChanged(): void {
    this.enableDisabledFields();
    this.credentialsChanged.emit(this.sertifiCredentials.value);
    this.disableWebhookSecretKeyFields();
  }

  addAppCredentialsField(): void {
    let generatedToken = generateToken(25);
    const sertifiGroup = this.formBuilder.group({
      objectType: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      apiSource: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      syncStatus: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      webhookSecretKey: [{ value: generatedToken, disabled: true }, InputValidation.removeTrailingSpaceValidator()],
      showSyncReadFields: [false]
    });

    sertifiGroup.valueChanges.subscribe(() => {
      this.sertifiCredentials.updateValueAndValidity();
      this.onCredentialsChanged();
    });

    this.sertifiCredentials.push(sertifiGroup);
    this.onCredentialsChanged();
  }

  removeAppCredentialsField(index: number): void {
    this.sertifiCredentials.removeAt(index);
    this.onCredentialsChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appBool'] && changes['appBool'].currentValue !== undefined) {
      this.updateValidationBasedOnAppBool(changes['appBool'].currentValue);
      this.cdr.detectChanges();
    }
  }

  private updateValidationBasedOnAppBool(appBool: string): void {
    this.sertifiCredentials.controls.forEach(control => {
      const objectTypeControl = control.get('objectType');
      const apiSourceControl = control.get('apiSource');
      const syncStatusControl = control.get('syncStatus');
      const webhookSecretKeyControl = control.get('webhookSecretKey');

      if (appBool === 'Webhook') {
          objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          if(this.showSyncReadFields) {
          } else {
            objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          }
        
      } else if (appBool === 'Sync/Read') {
          objectTypeControl.clearValidators();
          apiSourceControl.clearValidators();
          syncStatusControl.clearValidators();
          webhookSecretKeyControl.clearValidators();
      }
      objectTypeControl.updateValueAndValidity();
      apiSourceControl.updateValueAndValidity();
      syncStatusControl.updateValueAndValidity();
      webhookSecretKeyControl.updateValueAndValidity();
  });
    this.sertifiCredentials.updateValueAndValidity();
  }

  toggleSyncReadFields(): void {
    this.showSyncReadFields = !this.showSyncReadFields;
    this.showSyncReadFieldsChange.emit(this.showSyncReadFields);
    this.updateValidationBasedOnAppBool(this.appBool); 
    this.cdr.markForCheck();
    if (!this.showSyncReadFields) {
      this.sertifiCredentials.controls.forEach(control => {
      });
    }
    this.sertifiCredentials.updateValueAndValidity();
  }

  private enableDisabledFields(): void {
    this.sertifiCredentials.controls.forEach((control) => {
      const webhookSecretKeyControl = control.get('webhookSecretKey');
      if (webhookSecretKeyControl && webhookSecretKeyControl.disabled) {
        webhookSecretKeyControl.enable({ emitEvent: false });
      }
    });
  }

  private disableWebhookSecretKeyFields(): void {
    this.sertifiCredentials.controls.forEach((control) => {
      const webhookSecretKeyControl = control.get('webhookSecretKey');
      if (webhookSecretKeyControl && webhookSecretKeyControl.enabled) {
        webhookSecretKeyControl.disable({ emitEvent: false });
      }
    });
  }

}
