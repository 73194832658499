// angular import
import { Component } from '@angular/core';

// project import
import { SharedModule } from '../../../../../../src/app/theme/shared/shared.module';

@Component({
  selector: 'app-help-document',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './help-document.component.html',
  styleUrl: './help-document.component.scss'
})
export class HelpDocumentComponent {}
