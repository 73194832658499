import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";

export class InputValidation {
  numericValidator(control: AbstractControl): Promise<{ [key: string]: any }> {
    return new Promise((resolve, reject) => {
      const numericValue = Number(control.value);
      if (isNaN(numericValue) || !Number.isInteger(numericValue)) {
        resolve({ 'numeric': true });
      } else {
        resolve(null);
      }
    });
  }

  static numericValidatorForUpdate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isNumeric = /^\d+$/.test(control.value);
      return isNumeric ? null : { 'nonNumeric': { value: control.value } };
    };
  }

  static urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const urlPattern = /^(?!https:\/\/).*(\.com)$/i;
      const isValid = urlPattern.test(control.value);
      return isValid ? null : { invalidUrl: true };
    };
  }

  static underscorePatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value: string = control.value;
      const pattern: RegExp = /^[\w]+(_[\w]+){3}$/;

      if (!pattern.test(value)) {
        return { invalidUnderscorePattern: true };
      }
      return null;
    };
  }

  static removeTrailingSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        const trimmedValue = control.value.trimEnd();
        if (control.value !== trimmedValue) {
          (control as FormControl).setValue(trimmedValue, { emitEvent: false });
        }
      }
      return null;
    };
  }

  static removeTrailingSpaceForNumeric(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        const trimmedValue = control.value.trimEnd();
        if (control.value !== trimmedValue) {
          (control as FormControl).setValue(trimmedValue, { emitEvent: false });
        }
        const numericValue = Number(trimmedValue);
        if (isNaN(numericValue) || !Number.isInteger(numericValue)) {
          return { numeric: true };
        }
      }
      return null;
    };
  }
}