<div *ngIf="appName === 'stayntouch'" class='addFormContainer'>
    <div class="column text-2xl font-extrabold tracking-tight mt-6">
        <div class="appSelection">
            <div class="colHeading  mb-4">
                App Credentials
            </div>
        </div>
        <div class="actionRowBtn" *ngIf="appType === 'Sync/Read'">
            <button (click)="addAppCredentialsField()" class="ml-3 w-28 " mat-flat-button type="button"
                [color]="'primary'">
                <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2">Add New</span>
            </button>
        </div>

    </div>

    <hr>

    <div [formGroup]="stayntouchForm" class='addFormContainer'>
        <div formArrayName="stayntouchCredentials">
            <div *ngFor="let credentialsGroup of getStayNTouchCredentialsControls(); let i = index" [formGroupName]="i">
                <div *ngIf="appType === 'Webhook'">
                    <div class="column">
                        <div
                            class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading"
                        >
                            {{ appName | capitalize }} Webhook Information
                        </div>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Object Type</mat-label>
                            <input
                                matInput
                                placeholder="Object Type"
                                formControlName="objectType"
                            />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>API Source</mat-label>
                            <input
                                matInput
                                placeholder="API Source"
                                formControlName="apiSource"
                            />
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Sync Status</mat-label>
                            <input
                                matInput
                                placeholder="Sync Status"
                                formControlName="syncStatus"
                            />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Webhook Api Key</mat-label>
                            <input
                                matInput
                                placeholder="Webhook Api Key"
                                formControlName="webhookSecretKey"
                                [ngStyle]="{ cursor: 'not-allowed' }"
                            />
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="appType === 'Sync/Read' || showSyncReadFields">
                    <div class="column" *ngIf="appType === 'Sync/Read'">
                        <div class="text-1xl font-extrabold tracking-tight mb-4 appInfoHeading">
                            {{ appName | capitalize }} App Information
                        </div>
                        <div class="actionRowBtn" *ngIf="appType === 'Sync/Read'">
                            <button class="-ml-4" mat-button [color]="'warn'" (click)="removeAppCredentialsField(i)">
                                Delete
                            </button>
                        </div>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Client ID</mat-label>
                            <input matInput formControlName="client_id" placeholder="Client ID" >
    
                            <mat-error *ngIf="credentialsGroup.get('client_id').hasError('required')">Client ID is
                                required</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Client Secret</mat-label>
                            <input matInput formControlName="client_secret" placeholder="Client Secret" >
                            <mat-error *ngIf="credentialsGroup.get('client_secret').hasError('required')">Client Secret is
                                required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>Grant Type</mat-label>
                            <input matInput formControlName="grant_type" placeholder="Grant Type" [readonly]="true" class="grantTypeInput" >
                            <mat-error *ngIf="credentialsGroup.get('grant_type').hasError('required')">Grant Type is
                                required</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Auth URL</mat-label>
                            <input matInput formControlName="authUrl" placeholder="Auth URL" >
                            <mat-error *ngIf="credentialsGroup.get('authUrl').hasError('required')">Auth Url is
                                required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="column">
                        <mat-form-field appearance="fill">
                            <mat-label>API URL</mat-label>
                            <input matInput formControlName="apiUrl" placeholder="API URL" >
                            <mat-error *ngIf="credentialsGroup.get('apiUrl').hasError('required')">API Url is
                                required</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Access Token</mat-label>
                            <input matInput formControlName="access_token" placeholder="Access Token" >
                            <mat-error *ngIf="credentialsGroup.get('access_token').hasError('required')">Access Token is
                                required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="column" *ngIf="appType === 'Sync/Read'">
                        <mat-form-field appearance="fill">
                            <mat-label>PMS DB</mat-label>
                            <input matInput formControlName="pmsDb" placeholder="PMS DB" >
                            <mat-error *ngIf="credentialsGroup.get('pmsDb').hasError('required')">PMS DB is
                                required</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Property ID</mat-label>
                            <input matInput formControlName="propertyId" placeholder="Property ID" >
                            <mat-error *ngIf="credentialsGroup.get('propertyId').hasError('required')">Property ID is
                                required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="appType === 'Webhook'">
                    <mat-checkbox
                        formControlName="showSyncReadFields"
                        (change)="toggleSyncReadFields()"
                    >
                        Callback functionality
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>


