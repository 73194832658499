<form [formGroup]="salesforceForm">
    <app-card cardTitle="Salesforce Credentials">
        <div class="d-flex justify-content-between mb-4 w-100">
            <div class="d-flex align-items-center">
                <i *ngIf="!tokenResult" class="ti ti-circle-check f-20 saleforce-connection-not-verified-icon"></i>
                <i *ngIf="tokenResult" class="ti ti-circle-check f-20 saleforce-connection-verified-icon"></i>
                <i *ngIf="!tokenResult" class="saleforce-connection-not-verified">Verify Salesforce Connection</i>
                <i *ngIf="tokenResult" class="saleforce-connection-verified">Connection Verified</i>
            </div>
            <button type="button" class="btn btn-primary" [disabled]="!salesforceForm.valid || loading" (click)="checkSalesforceConnectivity()">
                Check Connectivity & Continue
            </button>
        </div>
        <div class="form-group row">
            <div class="col-lg-4">
                <label class="form-label">Salesforce Auth URL</label>
                <div class="input-group search-form">
                    <input type="text" formControlName="sfdc_auth_url" class="form-control" />
                    <span class="input-group-text bg-transparent"><i class="feather icon-link"></i></span>
                </div>
                <div *ngIf="salesforceForm.get('sfdc_auth_url')?.invalid && salesforceForm.get('sfdc_auth_url')?.touched" class="text-danger">
                    Salesforce Auth URL is required
                </div>
            </div>
            <div class="col-lg-4">
                <label class="form-label">Salesforce Object URL</label>
                <div class="input-group search-form">
                    <input type="text" formControlName="sfdc_object_url" class="form-control" />
                    <span class="input-group-text bg-transparent"><i class="feather icon-link"></i></span>
                </div>
                <div *ngIf="salesforceForm.get('sfdc_object_url')?.invalid && salesforceForm.get('sfdc_object_url')?.touched" class="text-danger">
                    Salesforce Object URL is required
                </div>
            </div>
            <div class="col-lg-4">
                <label class="form-label">Salesforce Client ID</label>
                <div class="input-group">
                    <input type="text" formControlName="sfdc_client_id" class="form-control" />
                </div>
                <div *ngIf="salesforceForm.get('sfdc_client_id')?.invalid && salesforceForm.get('sfdc_client_id')?.touched" class="text-danger">
                    Salesforce Client ID is required
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-4">
                <label class="form-label">Salesforce Client Secret</label>
                <input type="text" formControlName="sfdc_client_secret" class="form-control" />
                <div *ngIf="salesforceForm.get('sfdc_client_secret')?.invalid && salesforceForm.get('sfdc_client_secret')?.touched" class="text-danger">
                    Salesforce Client Secret is required
                </div>
            </div>
            <div class="col-lg-4">
                <label class="form-label">Salesforce Grant Type</label>
                <div class="input-group search-form">
                    <input type="text" formControlName="sfdc_grant_type" class="form-control" disabled />
                </div>
            </div>
            <div class="col-lg-4">
                <label class="form-label">Salesforce Token (Optional)</label>
                <input type="text" formControlName="sfdc_token" class="form-control" />
            </div>
        </div>
    </app-card>
</form>