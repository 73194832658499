import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule} from '@angular/material/stepper';
import { Router } from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';


@Component({
  selector: 'app-jwt-guide-document',
  standalone: true,
  imports: [MatStepperModule , MatIconModule, MatSidenavModule, MatToolbarModule, MatListModule],
  templateUrl: './jwt-guide-document.component.html',
  styleUrl: './jwt-guide-document.component.scss'
})
export class JwtGuideDocumentComponent implements OnInit {
  

  constructor(
    private router: Router,

  ) {}


  ngOnInit(): void {
  }


  goBack() {
    this.router.navigate(['/']);
  }


}
