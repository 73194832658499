import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenGenerateService {
  serverUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  generateToken(secretName: string) {
    const body = { secretName };
    const url = this.serverUrl + 'generateToken'
    return this.http.post<any>(url, body);
  }
}
