import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FetchSecretsDetailsService } from 'app/services/fetch-secrets-details.service';
import { FormBuilder, FormGroup, FormsModule, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { JwtTokenGenerateService } from 'app/services/jwt-token-generate.service';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { UpdateSecretsService } from 'app/services/update-secrets.service';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APP_CONSTANTS } from 'app/constants/constants';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputValidation } from 'app/shared/inputValidation';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { FetchEnvTokenAndUrlService } from 'app/services/fetch-env-token-and-url.service';

@Component({
    selector: 'app-secrets-details',
    standalone: true,
    imports: [
        FormsModule,
        MatInputModule,
        MatButtonModule,
        HttpClientModule,
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatTooltipModule,
        MatCardModule,
        MatExpansionModule,
    ],
    templateUrl: './secrets-details.component.html',
    styleUrl: './secrets-details.component.scss',
})
export class SecretsDetailsComponent implements OnInit {
    secretData: any = {};
    stayntouchData: any = {};
    ohipData: any = {};
    hconnectData: any = {};
    hotelkeyData: any = {};
    stripeData: any = {};
    sertifiData: any = {};
    cventData: any = {};
    token: string;
    secretName: string;
    mewsData: any = {};
    editMode: boolean = false;
    updateSecretForm: FormGroup;
    @ViewChild('tokenTextarea') tokenTextarea: ElementRef;
    inputValidation = new InputValidation();
    displaySecretDataForMews: any = {};
    displaySecretDataForStayNTouch: any = {};
    displaySecretDataForOhip: any = {};
    displaySecretDataForHconnect: any = {};
    displaySecretDataForHotelkey: any = {};
    displaySecretDataForStripe: any = {};
    displaySecretDataForSertifi: any = {};
    displaySecretDataForCvent: any = {};
    salesforceData: any = {};
    appDataForMews: any = {};
    appDataForStayNTouch: any = {};
    appDataForOhip: any = {};
    appDataForHconnect: any = {};
    appDataForHotelkey: any = {};
    appDataForStripe: any = {};
    appDataForSertifi: any = {};
    appDataForCvent: any = {};
    isTokenAvailable: boolean = false;
    customerId: string;
    appName: string;
    environment: string;
    createdDate: string;
    apiEnvironmentUrl: string;
    apiEnvironmentToken: string;
    showToken: boolean = false;
    showApiUrl: boolean = false;
    selectedAppType: string;
    generatedWebhookToken: string; 
    

    constructor(
        private route: ActivatedRoute,
        private secretDetailService: FetchSecretsDetailsService,
        public updateSecrets: UpdateSecretsService,
        private jwtTokenService: JwtTokenGenerateService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        public splashScreen: FuseSplashScreenService,
        private router: Router,
        private fetchEnvService: FetchEnvTokenAndUrlService
    ) { }

    ngOnInit(): void {
        this.splashScreen.show();
        this.route.paramMap.subscribe((params) => {
            this.createdDate = params.get('createdDate');
            this.secretName = params.get('secretName');
            if (this.secretName) {
                this.getSecretDetails();
                this.initializeForm();
                const { customerId, appName, environment } =
                    this.parseSecretName(this.secretName);   
                this.customerId = customerId;
                this.appName = appName;
                this.environment = environment;
                this.splashScreen.hide();
            }
        });
    }

    initializeForm(): void {
        this.updateSecretForm = this.formBuilder.group({
            secretName: ['', Validators.required],
            sfdc_auth_url: ['', Validators.required],
            sfdc_client_id: ['', Validators.required],
            sfdc_client_secret: ['', Validators.required],
            sfdc_grant_type: ['', Validators.required],
            sfdc_object_url: ['', Validators.required],
            sfdc_token: [''],
            mews_client_name: ['', Validators.required],
            mews_client_token: ['', Validators.required],
            mews_access_token: ['', Validators.required],
            mews_api_url: ['', Validators.required],
            pmsDb: [
                '',
                Validators.required,
                this.inputValidation.numericValidator.bind(
                    this.inputValidation
                ),
            ],
        });
    }

    async getSecretDetails(): Promise<void> {
        try {
            const secretName = this.secretName;
            const response = await this.secretDetailService
                .getSecretValue(secretName)
                .toPromise();
            this.secretData = response.body[0];
            if (this.secretData) {
                this.stayntouchData = JSON.parse(this.secretData.stayntouch) || {};
                this.mewsData = JSON.parse(this.secretData.mews) || {};
                this.ohipData = JSON.parse(this.secretData.ohip) || {};
                this.hconnectData = JSON.parse(this.secretData.hconnect) || {};
                this.hotelkeyData = JSON.parse(this.secretData.hotelkey) || {};
                this.stripeData = JSON.parse(this.secretData.stripe) || {};
                this.sertifiData = JSON.parse(this.secretData.sertifi) || {};
                this.cventData = JSON.parse(this.secretData.cvent) || {};
                this.updateSecretForm.patchValue({
                    secretName: this.secretName,
                    sfdc_auth_url: this.secretData.sfdc_auth_url,
                    sfdc_client_id: this.secretData.sfdc_client_id,
                    sfdc_client_secret: this.secretData.sfdc_client_secret,
                    sfdc_grant_type: this.secretData.sfdc_grant_type,
                    sfdc_object_url: this.secretData.sfdc_object_url,
                    sfdc_token: this.secretData.sfdc_token,
                    mews_client_name: this.mewsData.client_name,
                    mews_client_token: this.mewsData.client_token,
                    mews_access_token: this.mewsData.access_token,
                    mews_api_url: this.mewsData.api_url,
                    pmsDb: this.secretData.pmsDb,
                    stayntouch_client_id: this.stayntouchData.client_id,
                    stayntouch_client_secret: this.stayntouchData.client_secret,
                    stayntouch_grant_type: this.stayntouchData.grant_type,
                    stayntouch_auth_url: this.stayntouchData.authUrl,
                    stayntouch_api_url: this.stayntouchData.apiUrl,
                    stayntouch_access_token: this.stayntouchData.access_token,
                });
                this.createDisplaySecretData();
            } else {
                console.error('Secret data is null.');
                this.openSnackBar('Secret data is null.');
            }
        } catch (error) {
            console.error('Error fetching secret:', error);
        }
    }


    parseSecretName(secretName: string) {

        const parts = secretName.split('_');

        if (parts.length < 4) {
          console.error('Unexpected secret name format:', secretName);
          return {
            secretName: secretName,
            organizationName: '',
            customerId: '',
            appName: '',
            environment: '',
          };
        }

        const organizationName = parts[0];
        const customerId = parts.slice(1, -2).join('_');
        const appName = parts[parts.length - 2];
        const environment = parts[parts.length - 1];
      
        return {
          secretName: secretName,
          organizationName: organizationName,
          customerId: customerId,
          appName: appName,
          environment: environment,
        };
      }

    createDisplaySecretData(): void {
        this.displaySecretDataForMews = {
            secretName: this.secretName,
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
            mews: this.mewsData,
            type: this.secretData.type,
        };
        this.displaySecretDataForStayNTouch = {
            secretName: this.secretName,
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
            stayntouch: this.stayntouchData,
            type: this.secretData.type,
        };
        this.displaySecretDataForOhip = {
            secretName: this.secretName,
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
            ohip: this.ohipData,
            type: this.secretData.type,
        };
        this.displaySecretDataForHconnect = {
            secretName: this.secretName,
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
            hconnect: this.hconnectData,
            type: this.secretData.type,
        };
        this.displaySecretDataForHotelkey = {
            secretName: this.secretName,
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
            hotelkey: this.hotelkeyData,
            type: this.secretData.type,
        };
        this.displaySecretDataForSertifi = {
            secretName: this.secretName,
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
            sertifi: this.sertifiData,
            type: this.secretData.type,
        };
        this.displaySecretDataForCvent = {
            secretName: this.secretName,
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
            cvent: this.cventData,
            type: this.secretData.type,
        };
        this.displaySecretDataForStripe = {
            secretName: this.secretName,
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
            stripe: this.stripeData,
            type: this.secretData.type,
        };
        this.salesforceData = {
            sfdc_auth_url: this.secretData.sfdc_auth_url,
            sfdc_client_id: this.secretData.sfdc_client_id,
            sfdc_client_secret: this.secretData.sfdc_client_secret,
            sfdc_grant_type: this.secretData.sfdc_grant_type,
            sfdc_object_url: this.secretData.sfdc_object_url,
            sfdc_token: this.secretData.sfdc_token,
        };
        this.appDataForMews = {
            mews: this.mewsData,
            type: this.secretData.type,
        }

        this.appDataForStayNTouch = {
            stayntouch: this.stayntouchData,
            type: this.secretData.type,
        };

        this.appDataForOhip = {
            ohip: this.ohipData,
            type: this.secretData.type,
        }

        this.appDataForHconnect = {
            hconnect: this.hconnectData,
            type: this.secretData.type,
        }
        this.appDataForHotelkey = {
            hotelkey: this.hotelkeyData,
            type: this.secretData.type,
        }
        this.appDataForStripe = {
            stripe: this.stripeData,
            type: this.secretData.type,
        }
        this.appDataForSertifi = {
            sertifi: this.sertifiData,
            type: this.secretData.type,
        }
        this.appDataForCvent = {
            cvent: this.cventData,
            type: this.secretData.type,
        }
    }


    async generateToken(): Promise<void> {
        this.splashScreen.show();
        try {
            this.isTokenAvailable = true;
            this.showApiUrl = true;
            this.showToken = true;
            const secretName = this.secretName;
            const response = await this.jwtTokenService
                .generateToken(secretName)
                .toPromise();
            this.token = response.body[0];
    
            const environment = this.extractEnvironmentFromSecretName(this.secretName);
    
            if (this.secretData.type === 'Webhook') {
                const appDataMap: { [key: string]: any } = {
                    cvent: this.cventData,
                    stripe: this.stripeData,
                    sertifi: this.sertifiData,
                    stayntouch: this.stayntouchData,
                    mews: this.mewsData,
                    ohip: this.ohipData,
                };
    
                const appData = appDataMap[this.appName];
    
                if (appData && appData[0]?.webhookSecretKey) {
                    this.generatedWebhookToken = appData[0].webhookSecretKey;
                    this.fetchEnvService.setWebhookToken(environment, this.generatedWebhookToken);
                } else {
                    throw new Error(`Webhook Secret Key not found for app: ${this.appName}`);
                }
            }
    
            this.fetchApiUrl();
            this.fetchApiToken();
            this.splashScreen.hide();
            this.openSnackBar('Token generated successfully');
        } catch (error) {
            this.splashScreen.hide();
            this.isTokenAvailable = false;
            this.openSnackBar(
                'Token generation failed. Please ensure PMS Database is provided.'
            );
            console.error('Error generating token:', error);
        }
    }
    

    async fetchApiUrl(): Promise<void> {
        try {
            this.splashScreen.show();
            const environment = this.extractEnvironmentFromSecretName(this.secretName);
            this.apiEnvironmentUrl = this.fetchEnvService.getApiUrl(environment, this.secretData.type);
            this.openSnackBar('Api Url fetched successfully');
            this.splashScreen.hide();
        } catch (error) {
            this.splashScreen.hide();
            this.openSnackBar('Fetch Api Url failed');
            console.error('Error generating token:', error);
        }
    }

    async fetchApiToken(): Promise<void> {
        try {
            this.splashScreen.show();
            this.showToken = true;

            const environment = this.extractEnvironmentFromSecretName(
                this.secretName
            );
            this.apiEnvironmentToken = await this.fetchEnvService.getToken(
                environment, this.secretData.type
            );
            this.splashScreen.hide();
            this.openSnackBar('API Token fetched successfully');
        } catch (error) {
            this.splashScreen.hide();
            this.openSnackBar('Failed to fetch token.');
            console.error('Error fetching token:', error);
        }
    }

    extractEnvironmentFromSecretName(secretName: string): string {
        const parts = secretName.split('_');
        
        if (parts.length === 5) {
            return parts[4];
        } else if (parts.length === 4) {
            return parts[3];
        } else {
            throw new Error('Invalid secret name format');
        }
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, APP_CONSTANTS.MAT_SNACKBAR_CLOSE, {
            duration: APP_CONSTANTS.MAT_SNACKBAR_DURATION,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
        if (this.editMode) {
            this.openSnackBar('Edit mode is enable now you can update data');
        }
    }

    goBack() {
        this.router.navigate(['/']);
    }

    copyToClipboard(field: string, event: MouseEvent): void {
        const target = event.currentTarget as HTMLElement;
        const textarea = target.previousElementSibling as HTMLTextAreaElement;
        textarea.select();
        document.execCommand('copy');
        this.openSnackBar('Copied to clipboard');
    }

    guideDocument(): void {
        window.open('/guideDocument', '_blank');
    }

    updateSecretDetails(): void {
        this.router.navigate(['./updateDetails'], { queryParams: { secretData: JSON.stringify(this.secretData), secretName: this.secretName, createdDate: this.createdDate } });
    }
}
