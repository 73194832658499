import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GuideDownloadService {

  constructor() { }

  static downloadFile(fileName: string): void {
    const link = document.createElement('a');
    link.href = `/assets/${fileName}`;
    link.download = fileName;
    link.click();
  }
}
