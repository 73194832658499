// angular import
import { Injectable, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

// rxjs import
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    delay,
    finalize,
    switchMap,
    tap,
} from 'rxjs/operators';

// project import
import { LambdaLogs } from './log';
import { LAMBDALOGS } from './log-data';
import { SortDirection } from '../../../../../theme/shared/directive/sortable.directive';
import { FetchCustomerListService } from '../../../../../../../src/services/fetch-customer-list.service';
import {
    getAccessToken,
    getAppName,
    getProcessedEnvironments,
    getUserRole,
} from '../../../../../../../src/app/theme/shared/utility';
import { LambdalogsService } from 'src/services/lambda-logs.service';

interface SearchResult {
    customer: LambdaLogs[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: string;
    sortDirection: SortDirection;
    middlewareJobId: string;
    status: string;
    appName: string; // new field for appName
    selectedNoOfDays: number;
    selectedEnvironment: string;
    lambdaLogOldSearch: string;
    noOfDaysChanged: Boolean;
}

const compare = (v1: string | number, v2: string | number) =>
    v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Injectable({
    providedIn: 'root',
})
export class LogDataService {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _customers$ = new BehaviorSubject<LambdaLogs[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    accessToken = getAccessToken();
    private _state: State = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: '',
        middlewareJobId: '',
        status: '',
        appName: '',
        selectedNoOfDays: 0,
        selectedEnvironment: '',
        lambdaLogOldSearch: '',
        noOfDaysChanged: false,
    };

    appNames!: any[];
    environments!: any[];
    isLoading$!: Observable<boolean>;
    processItem: any[] = [];
    searchQuery: string = '';
    logsFetched: boolean = false;
    selectedLogGroup: string = '';
    selectedApp: string = '';
    logGroupName: string = '';
    selectedStatus: string = 'all';
    displayedColumns: string[] = [
        'Date',
        'middlewareJobId',
        'status',
        'pmsSystem',
    ];
    middlewareJodId: string = '';
    sqsQueueUrls: any[] = [];
    middlewareLogGroupName: string = '';
    parsedLogDetails: { [key: string]: any } = {};

    // constructor
    constructor(
        private pipe: DecimalPipe,
        public customerListService: FetchCustomerListService,
        private lambdalogsService: LambdalogsService,
    ) {
        this._search$
            .pipe(
                tap(() => this._setLoadingState(true)), // Start loading
                debounceTime(200),
                switchMap(() => this._search()),
                finalize(() => this._setLoadingState(false)), // End loading
            )
            .subscribe((result) => {
                this._customers$.next(result.customer);
                this._total$.next(result.total);
            });

        this._search$.next();
    }

    get customer$() {
        return this._customers$.asObservable();
    }

    private _setLoadingState(isLoading: boolean): void {
        this._loading$.next(isLoading);
    }
    get total$() {
        return this._total$.asObservable();
    }
    get loading$() {
        return this._loading$.asObservable();
    }
    get page() {
        return this._state.page;
    }
    set page(page: number) {
        this._set({ page });
    }
    get pageSize() {
        return this._state.pageSize;
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    get searchTerm() {
        return this._state.searchTerm;
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    get lambdaLogOldSearch() {
        return this._state.lambdaLogOldSearch;
    }
    set lambdaLogOldSearch(lambdaLogOldSearch: string) {
        this._set({ lambdaLogOldSearch });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    get selectedEnvironment() {
        return this._state.selectedEnvironment;
    }
    set selectedEnvironment(selectedEnvironment: string) {
        this._set({ selectedEnvironment });
    }

    get selectedNoOfDays() {
        return this._state.selectedNoOfDays;
    }
    set selectedNoOfDays(selectedNoOfDays: number) {
        this._set({ selectedNoOfDays });
    }

    // get noOfDaysChanged() {
    //   return this._state.noOfDaysChanged;
    // }

    set noOfDaysChanged(noOfDaysChanged: boolean) {
        this._set({ noOfDaysChanged });
    }

    private _search(): Observable<SearchResult> {
        if (this._state.selectedEnvironment) {
            const { sortColumn, sortDirection, pageSize, page, searchTerm } =
                this._state;
            return this.fetchCustomers().pipe(
                tap(() => this._setLoadingState(true)),
                switchMap((result) => {
                    let customers = result.customer;

                    // 1. Sort
                    customers = this.sortCustomers(
                        customers,
                        sortColumn,
                        sortDirection,
                    );

                    // 2. Filter by search term
                    customers = this.filterCustomersBySearchTerm(
                        customers,
                        searchTerm,
                    );

                    const total = customers.length;

                    // 3. Paginate
                    customers = customers.slice(
                        (page - 1) * pageSize,
                        (page - 1) * pageSize + pageSize,
                    );

                    return of({ customer: customers, total });
                }),
                finalize(() => this._setLoadingState(false)),
            );
        } else {
            this._setLoadingState(false);
            console.error('Selected environment is not set.');
            // Return an empty result or an error Observable
            return of({ customer: [], total: 0 }); // Fallback for empty results
        }
    }

    private sortCustomers(
        customers: LambdaLogs[],
        sortColumn: string,
        sortDirection: string,
    ): LambdaLogs[] {
        return sort(customers, sortColumn, sortDirection);
    }

    private filterCustomersBySearchTerm(
        customers: LambdaLogs[],
        searchTerm: string,
    ): LambdaLogs[] {
        return customers.filter((customer) =>
            matches(customer, searchTerm, this.pipe),
        );
    }

    private fetchCustomers(): Observable<SearchResult> {
        return new Observable<SearchResult>((observer) => {
            const localData = localStorage.getItem('logs');
            const lambdaLogOldSearch =
                localStorage.getItem('lambdaLogOldSearch');
            if (
                localData &&
                lambdaLogOldSearch == this._state.lambdaLogOldSearch &&
                !this._state.noOfDaysChanged
            ) {
                const customerData: LambdaLogs[] = JSON.parse(localData);
                observer.next({
                    customer: customerData,
                    total: customerData.length,
                });
                observer.complete();
            } else {
                // Call getWatchLogs and handle its Promise
                from(this.getWatchLogs()).subscribe({
                    next: () => {
                        const updatedData = localStorage.getItem('logs');
                        if (updatedData) {
                            const customerData: LambdaLogs[] =
                                JSON.parse(updatedData);
                            observer.next({
                                customer: customerData,
                                total: customerData.length,
                            });
                            observer.complete();
                            localStorage.setItem(
                                'lambdaLogOldSearch',
                                this._state.lambdaLogOldSearch,
                            );
                            this._setLoadingState(false);
                        } else {
                            observer.error(
                                'No logs found after fetching watch logs.',
                            );
                            this._setLoadingState(false);
                        }
                    },
                    error: (error) => {
                        console.error('Error fetching watch logs:', error);
                        observer.error(error);
                        this._setLoadingState(false);
                    },
                });
            }
        });
    }

    async getWatchLogs(): Promise<void> {
        try {
            if (
                !this._state.selectedNoOfDays ||
                isNaN(this._state.selectedNoOfDays) ||
                this._state.selectedEnvironment == ''
            ) {
                return;
            }

            this.logGroupName = this.generateLogGroupName();
            const filterPattern = 'Sending message to SQS Queue';
            const filterPatternForMessage = 'SQS MESSAGE';
            const day = this._state.selectedNoOfDays;
            this.processItem = [];
            this.selectedApp = 'all';

            let response = await this.lambdalogsService
                .getLogs(
                    this.logGroupName,
                    filterPattern,
                    filterPatternForMessage,
                    day,
                )
                .toPromise();

            if (response.body != null) {
                this.sqsQueueUrls = [];
                response.body.forEach((item: string) => {
                    if (item.includes('Sending message to SQS Queue')) {
                        const urlMatch = item.match(
                            /https:\/\/sqs\.[\w\-]+\.amazonaws\.com\/[\d]+\/[\w\-]+/,
                        );
                        if (urlMatch) {
                            this.sqsQueueUrls.push(urlMatch[0]);
                        }
                    }

                    const sqsMessageIndex = item.indexOf('SQS MESSAGE: ');

                    if (sqsMessageIndex !== -1) {
                        const logTimestamp = item.substring(0, 19);
                        const logContentString = item.substring(
                            sqsMessageIndex + 'SQS MESSAGE: '.length,
                        );
                        try {
                            const logContent = JSON.parse(logContentString);

                            let middlewareJobId =
                                logContent.middlewareJobId || '';

                            if (
                                !middlewareJobId &&
                                logContent.profiles &&
                                logContent.profiles.length > 0
                            ) {
                                const profile = logContent.profiles.find(
                                    (prof: { middlewareJobId: any }) =>
                                        prof.middlewareJobId,
                                );
                                if (profile) {
                                    middlewareJobId = profile.middlewareJobId;
                                }
                            }

                            if (
                                !middlewareJobId &&
                                logContent.reservations &&
                                logContent.reservations.length > 0
                            ) {
                                const reservation =
                                    logContent.reservations.find(
                                        (res: { middlewareJobId: any }) =>
                                            res.middlewareJobId,
                                    );
                                if (reservation) {
                                    middlewareJobId =
                                        reservation.middlewareJobId;
                                }
                            }
                            const secretName = logContent.secretName || '';
                            const pmsSystem = secretName.split('_')[2] || '';

                            if (middlewareJobId && secretName) {
                                const status = item.indexOf(
                                    'Lambda successfully processed the request and forwarded it to SQS',
                                )
                                    ? 'Completed'
                                    : 'Failure';
                                this.processItem.push({
                                    middlewareJobId,
                                    secretName,
                                    timestamp: logTimestamp,
                                    pmsSystem,
                                    status,
                                });
                                this.parsedLogDetails[middlewareJobId] =
                                    logContent;
                            }
                        } catch (e) {
                            console.error('Error parsing log content:', e);
                        }
                    }
                });

                this.processItem = this.processItem.map((item, index) => ({
                    ...item,
                    sqsQueueUrl: this.sqsQueueUrls[index] || '',
                }));
                localStorage.setItem('logs', JSON.stringify(this.processItem));
                localStorage.setItem(
                    'parsedLogDetails',
                    JSON.stringify(this.parsedLogDetails),
                );
            } else {
                // this.openSnackBar('No Middleware Job ID found');
            }
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    }

    generateLogGroupName(): string {
        const environment = this._state.selectedEnvironment.toLowerCase();
        return `/lambda-thynkcloud-${environment}`;
    }

    refreshList() {
        this._search$.next();
    }
}

// eslint-disable-next-line
function sort(customers: any, column: string, direction: string): LambdaLogs[] {
    if (direction === '' || column === '') {
        return customers;
    } else {
        return [...customers].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matches(customer: LambdaLogs, term: string, pipe: PipeTransform) {
    return (
        customer.middlewareJobId.toLowerCase().includes(term.toLowerCase()) ||
        customer.pmsSystem.toLowerCase().includes(term.toLowerCase()) ||
        customer.secretName.toLowerCase().includes(term.toLowerCase()) ||
        customer.status.toLowerCase().includes(term.toLowerCase()) ||
        customer.timestamp.toLowerCase().includes(term.toLowerCase())
    );
}
