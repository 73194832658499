import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { InputValidation } from 'app/shared/inputValidation';
import { MatSelectModule } from '@angular/material/select';
import { StayNTouchCredentials } from 'app/interface/i-addSecret-interface';
import { CapitalizePipe } from 'app/capitalize.pipe';
import { generateToken } from 'app/shared/utility';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
  selector: 'app-app-stayntouch-fields',
  standalone: true,
  imports: [MatButton, MatInputModule, ReactiveFormsModule, MatCheckboxModule, CommonModule, MatFormFieldModule, MatIconModule, MatTooltip, MatSelectModule, AppStayntouchFieldsComponent, CapitalizePipe],
  templateUrl: './app-stayntouch-fields.component.html',
  styleUrl: './app-stayntouch-fields.component.scss'
})
export class AppStayntouchFieldsComponent implements OnInit, OnChanges {

  @Input() appType: string;
  @Input() appName: string;
  @Input() appBool: string;
  @Output() credentialsChanged = new EventEmitter<StayNTouchCredentials[]>();
  @Output() showSyncReadFieldsChange = new EventEmitter<boolean>();

  addSecretForm: FormGroup;
  stayntouchForm: FormGroup;
  isDisabled: boolean = true;
  inputValidation = new InputValidation();
  isAppCredentails: boolean = false;
  environments: { key: string, value: string }[];
  showSyncReadFields = false;

  constructor(private formBuilder: FormBuilder,
    public splashScreen: FuseSplashScreenService,
    private cdr: ChangeDetectorRef
  ) { }


  ngOnInit(): void {
    this.stayntouchForm = this.formBuilder.group({
      stayntouchCredentials: this.formBuilder.array([])
    });
    this.addAppCredentialsField();
  }

  get stayntouchCredentials(): FormArray {
    return this.stayntouchForm.get('stayntouchCredentials') as FormArray;
  }

  getStayNTouchCredentialsControls(): AbstractControl[] {
    return this.stayntouchCredentials.controls;
  }

  onCredentialsChanged(): void {
    this.enableDisabledFields();
    this.credentialsChanged.emit(this.stayntouchCredentials.value);
    this.disableWebhookSecretKeyFields();
  }
  addAppCredentialsField(): void {
    let generatedToken = generateToken(25);
    const stayntouchGroup = this.formBuilder.group({
      client_id: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      client_secret: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      grant_type: ['client_credentials', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      authUrl: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      apiUrl: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      access_token: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      pmsDb: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      propertyId: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      objectType: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      apiSource: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      syncStatus: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      webhookSecretKey: [{ value: generatedToken, disabled: true }, InputValidation.removeTrailingSpaceValidator()],
      showSyncReadFields: [false] 
    });

    stayntouchGroup.valueChanges.subscribe(() => {
      this.stayntouchCredentials.updateValueAndValidity();
      this.onCredentialsChanged();
    });

    this.stayntouchCredentials.push(stayntouchGroup);
    this.onCredentialsChanged();
  }

  removeAppCredentialsField(index: number): void {
    this.stayntouchCredentials.removeAt(index);
    this.onCredentialsChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appBool'] && changes['appBool'].currentValue !== undefined) {
      this.updateValidationBasedOnAppBool(changes['appBool'].currentValue);
      this.cdr.detectChanges();
    }
  }


  private updateValidationBasedOnAppBool(appBool: string): void {
    this.stayntouchCredentials.controls.forEach(control => {
      const clientIdControl = control.get('client_id');
      const clientSecretControl = control.get('client_secret');
      const grantTypeControl = control.get('grant_type');
      const authUrlControl = control.get('authUrl');
      const apiUrlControl = control.get('apiUrl');
      const accessTokenControl = control.get('access_token');
      const pmsDbControl = control.get('pmsDb');
      const propertyIdControl = control.get('propertyId');
      const objectTypeControl = control.get('objectType');
      const apiSourceControl = control.get('apiSource');
      const syncStatusControl = control.get('syncStatus');
      const webhookSecretKeyControl = control.get('webhookSecretKey');

      if (appBool === 'Webhook') {
        pmsDbControl.clearValidators();
        propertyIdControl.clearValidators();
        objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        if(this.showSyncReadFields) {
          clientIdControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          clientSecretControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          grantTypeControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          authUrlControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          apiUrlControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          accessTokenControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        } else {
          objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          apiUrlControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }

      } else if (appBool === 'Sync/Read') {
        objectTypeControl.clearValidators();
        apiSourceControl.clearValidators();
        syncStatusControl.clearValidators();
        webhookSecretKeyControl.clearValidators();
        clientIdControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        clientSecretControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        grantTypeControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        authUrlControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        apiUrlControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        accessTokenControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        pmsDbControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        propertyIdControl?.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      }
      clientIdControl?.updateValueAndValidity();
      clientSecretControl?.updateValueAndValidity();
      grantTypeControl?.updateValueAndValidity();
      authUrlControl?.updateValueAndValidity();
      apiUrlControl?.updateValueAndValidity();
      accessTokenControl?.updateValueAndValidity();
      pmsDbControl?.updateValueAndValidity();
      propertyIdControl?.updateValueAndValidity();
      objectTypeControl.updateValueAndValidity();
      apiSourceControl.updateValueAndValidity();
      syncStatusControl.updateValueAndValidity();
      webhookSecretKeyControl.updateValueAndValidity();
    });

    this.stayntouchCredentials.updateValueAndValidity();
  }

  toggleSyncReadFields(): void {
    this.showSyncReadFields = !this.showSyncReadFields;
    this.showSyncReadFieldsChange.emit(this.showSyncReadFields);
    this.updateValidationBasedOnAppBool(this.appBool); 
    this.cdr.markForCheck();
  
    if (!this.showSyncReadFields) {
      this.stayntouchCredentials.controls.forEach(control => {
        control.get('client_id')?.reset('');
        control.get('client_secret')?.reset('');
        control.get('grant_type')?.reset('');
        control.get('authUrl')?.reset('');
        control.get('apiUrl')?.reset('');
        control.get('access_token')?.reset('');
      });
    }
  
    this.stayntouchCredentials.updateValueAndValidity();
  }
  

  private enableDisabledFields(): void {
    this.stayntouchCredentials.controls.forEach((control) => {
      const webhookSecretKeyControl = control.get('webhookSecretKey');
      if (webhookSecretKeyControl && webhookSecretKeyControl.disabled) {
        webhookSecretKeyControl.enable({ emitEvent: false });
      }
    });
  }

  private disableWebhookSecretKeyFields(): void {
    this.stayntouchCredentials.controls.forEach((control) => {
      const webhookSecretKeyControl = control.get('webhookSecretKey');
      if (webhookSecretKeyControl && webhookSecretKeyControl.enabled) {
        webhookSecretKeyControl.disable({ emitEvent: false });
      }
    });
  }
}
