<div class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto container" cdkScrollable>

  <!-- Header -->
  <div
    class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 pb-22 sm:pt-12 sm:pb-28 sm:px-10 bg-default dark:bg-transparent">
    <div class="flex-1 min-w-0">
      <!-- Breadcrumbs -->
      <div>
        <div class="hidden sm:flex flex-wrap items-center font-medium">
          <div>
            <a class="whitespace-nowrap text-primary-500">Secret Manager</a>
          </div>
          <div class="flex items-center ml-1 whitespace-nowrap">
            <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
            <a class="ml-1 text-primary-500">Details</a>
          </div>
          <div class="flex items-center ml-1 whitespace-nowrap">
            <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
            <span class="ml-1 text-secondary">Secret</span>
          </div>
        </div>
      </div>
      <!-- Title -->
      <div class="mt-2">
        <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
          <mat-icon class="backButton" (click)="goBack()" svgIcon="heroicons_solid:chevron-double-left"
            matTooltip="Click to go back"></mat-icon>
          Secrets Details & Generate JWT Token
        </h2>
      </div>
    </div>
    <!-- Actions -->
    <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">

      <button mat-button *ngIf="!editMode" class="tokenBtn" (click)="generateToken()"
        matTooltip="Click to generate token">Generate Token</button>
      <button mat-button class="editBtn" (click)="updateSecretDetails()"
        matTooltip="Click to edit customer data">Edit</button>
    </div>
  </div>


  <!-- Main -->
  <div class="flex-auto -mt-16 p-6 sm:p-10 rounded-t-2xl shadow bg-card">

    <div class="tokenSection">
      <div class="cardSection" *ngIf="!editMode">
        <mat-card class="secretCard" *ngIf="isTokenAvailable">
          <div class="mt-4">
            <p class="ml-4 guideInfo" (click)="guideDocument()">
              Click here for documentation <mat-icon class="arrowSquare"
                svgIcon="heroicons_outline:arrow-top-right-on-square"></mat-icon>
            </p>
          </div>
          <mat-card-content>
            <div *ngIf="!editMode" class="generatedToken">
              <mat-form-field appearance="fill" *ngIf="secretData.type !== 'Webhook'">
                <mat-label class="text-1xl flex font-extrabold tracking-tight">JWT Token</mat-label>
                <input class="height" matInput #tokenTextarea placeholder="Create JWT Token to click on button"
                  [(ngModel)]="token" [readonly]="true">
                <button mat-icon-button matTooltip="Copy Token" (click)="copyToClipboard('token', $event)">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label class="text-1xl flex font-extrabold tracking-tight"  *ngIf="secretData.type !== 'Webhook'">API URL</mat-label>
                <mat-label class="text-1xl flex font-extrabold tracking-tight" *ngIf="secretData.type == 'Webhook'">Webhook API URL</mat-label>
                <input class="height" matInput #tokenApiUrl placeholder="API URL" [(ngModel)]="apiEnvironmentUrl"
                  [readonly]="true">
                <button mat-icon-button matTooltip="Copy API URL" (click)="copyToClipboard('ApiUrl', $event)">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label class="text-1xl flex font-extrabold tracking-tight">
                  {{ secretData.type === 'Webhook' ? 'Webhook API Key' : 'API Token' }}
                </mat-label>
                <input 
                  class="height" 
                  matInput 
                  #tokenApi 
                  placeholder="API Token" 
                  [ngModel]="secretData.type === 'Webhook' ? generatedWebhookToken : apiEnvironmentToken" 
                  [readonly]="true">
              
                <button mat-icon-button matTooltip="Copy Token" (click)="copyToClipboard('apiToken', $event)">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="headerforSecretName">
        <div class="text-2xl flex font-extrabold tracking-tight secretName">Customer Details
        </div>
      </div>
      <div class="cardSection" *ngIf="!editMode">
        <mat-card class="secretCard">
          <mat-card-content>
            <div class="customerInfo">
              <div class="customerId">
                <h4 class="heading"> Secret Name</h4>
                <span class="detailsContent">{{secretName}}</span>
              </div>
              <div class="customerId">
                <h4 class="heading"> Customer Id</h4>
                <span class="detailsContent">{{ customerId }}</span>
              </div>
              <div class="customerName">
                <h4 class="heading"> App Name</h4>
                <span class="detailsContent">{{appName}}</span>
              </div>
              <div class="customerName">
                <h4 class="heading"> Environment</h4>
                <span class="detailsContent">{{environment}}</span>
              </div>
              <div class="createdDate">
                <h4 class="heading"> Created Date</h4>
                <span class="detailsContent">{{createdDate}}</span>
              </div>
              <div class="appType">
                <h4 class="heading"> Application Type</h4>
                <span class="detailsContent">{{secretData.type}}</span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="cardSection" *ngIf="!editMode">
        <mat-card class="secretCard">
          <mat-card-content>
            <mat-expansion-panel class="salesforceData" expanded>
              <mat-expansion-panel-header class="headerExpansion">
                <mat-panel-title>
                  Salesforce Details
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <pre class="secretJson">{{salesforceData | json}}</pre>
            </mat-expansion-panel>
            <mat-expansion-panel class="appDetails" expanded>
              <mat-expansion-panel-header class="headerExpansion">
                <mat-panel-title>
                  App Details
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <pre *ngIf="appName === 'mews'" class="secretJson">{{appDataForMews | json}}</pre>
              <pre *ngIf="appName === 'stayntouch'" class="secretJson">{{appDataForStayNTouch | json}}</pre>
              <pre *ngIf="appName === 'ohip'" class="secretJson">{{appDataForOhip | json}}</pre>
              <pre *ngIf="appName === 'hconnect'" class="secretJson">{{appDataForHconnect | json}}</pre>
              <pre *ngIf="appName === 'hotelkey'" class="secretJson">{{appDataForHotelkey | json}}</pre>
              <pre *ngIf="appName === 'stripe'" class="secretJson">{{appDataForStripe | json}}</pre>
              <pre *ngIf="appName === 'sertifi'" class="secretJson">{{appDataForSertifi | json}}</pre>
              <pre *ngIf="appName === 'cvent'" class="secretJson">{{appDataForCvent | json}}</pre>
            </mat-expansion-panel>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="cardSection" *ngIf="!editMode">
        <mat-card class="secretCard">
          <mat-card-content>
            <mat-expansion-panel class="appDetails">
              <mat-expansion-panel-header class="headerExpansion">
                <mat-panel-title>
                  Secret Details
                </mat-panel-title>
              </mat-expansion-panel-header>
              <pre *ngIf="appName === 'mews'" class="secretJson">{{displaySecretDataForMews | json}}</pre>
              <pre *ngIf="appName === 'stayntouch'" class="secretJson">{{displaySecretDataForStayNTouch | json}}</pre>
              <pre *ngIf="appName === 'ohip'" class="secretJson">{{displaySecretDataForOhip | json}}</pre>
              <pre *ngIf="appName === 'hconnect'" class="secretJson">{{displaySecretDataForHconnect | json}}</pre>
              <pre *ngIf="appName === 'hotelkey'" class="secretJson">{{displaySecretDataForHotelkey | json}}</pre>
              <pre *ngIf="appName === 'stripe'" class="secretJson">{{displaySecretDataForStripe | json}}</pre>
              <pre *ngIf="appName === 'sertifi'" class="secretJson">{{displaySecretDataForSertifi | json}}</pre>
              <pre *ngIf="appName === 'cvent'" class="secretJson">{{displaySecretDataForCvent | json}}</pre>
            </mat-expansion-panel>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>