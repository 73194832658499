import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UpdateSecretManager } from 'app/interface/i-updateSecret-interface';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateSecretsService {
  serverUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  updateSecretInformation(body: UpdateSecretManager): Observable<any> {
    const url = this.serverUrl + 'updateSecret';
    return this.http.post(url, body);
  }

  mapFormDataToBody(formData: any, secretName: string): UpdateSecretManager {
      const mewsCredentials = formData.mews.map((creds: any) => ({
        client_name: creds.client_name,
        client_token: creds.client_token,
        access_token: creds.access_token,
        api_url: creds.api_url,
        pmsDb: creds.pmsDb,
        propertyId: creds.propertyId,
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));

      const stayNTouchCredentials = formData.stayntouch.map((creds: any) => ({
        client_id: creds.client_id,
        client_secret: creds.client_secret,
        grant_type: creds.grant_type,
        authUrl: creds.authUrl,
        apiUrl: creds.apiUrl,
        access_token: creds.access_token,
        propertyId: creds.propertyId,
        pmsDb: creds.pmsDb,
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));

      const ohipCredentials = formData.ohip.map((creds: any) => ({
        client_id: creds.client_id,
        client_secret: creds.client_secret,
        grant_type: creds.grant_type,
        x_app_key: creds.x_app_key,
        username: creds.username,
        password: creds.password,
        extSystemCode: creds.extSystemCode,
        propertyId: creds.propertyId,
        url: creds.url,
        pmsDb: creds.pmsDb,
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));

      const hconnectCredentials = formData.hconnect.map((creds: any) => ({
        client_id: creds.client_id,
        client_secret: creds.client_secret,
        grant_type: creds.grant_type,
        scope: creds.scope,
        propertyId: creds.propertyId,
        pmsDb: creds.pmsDb,
        url: creds.url,
        access_token: creds.access_token,
        authUrl: creds.authUrl,
        appKey: creds.appKey,
        hotelId: creds.hotelId,
        externalSystem: creds.externalSystem,
      }));
      const stripeCredentials = formData.stripe.map((creds: any) => ({
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));

      const hotelkeyCredentials = formData.hotelkey.map((creds: any) => ({
        hostName: creds.hostName,
        propertyCode: creds.propertyCode,
        propertyId: creds.propertyId,
        pmsDb: creds.pmsDb,
      }));

      const sertifiCredentials = formData.sertifi.map((creds: any) => ({
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));

      const cventCredentials = formData.cvent.map((creds: any) => ({
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));

      let appType = formData.type;

      return {
        secretName: secretName,
        secretString: {
          sfdc_auth_url: formData.sfdc_auth_url,
          sfdc_client_id: formData.sfdc_client_id,
          sfdc_client_secret: formData.sfdc_client_secret,
          sfdc_grant_type: formData.sfdc_grant_type,
          sfdc_object_url: formData.sfdc_object_url,
          sfdc_token: formData.sfdc_token,
          mews: JSON.stringify(mewsCredentials),
          stayntouch: JSON.stringify(stayNTouchCredentials),
          ohip: JSON.stringify(ohipCredentials),
          hconnect: JSON.stringify(hconnectCredentials),
          hotelkey: JSON.stringify(hotelkeyCredentials),
          stripe: JSON.stringify(stripeCredentials),
          sertifi: JSON.stringify(sertifiCredentials),
          cvent: JSON.stringify(cventCredentials),
          type: appType
          }
          };
    }
}

