import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeleteCustomerService {
  serverUrl = environment.apiUrl;
  secretDeleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(private http: HttpClient) { }

  deleteSecret(secretName: string, recoveryWindowInDays: string): Observable<any> {
    const url = this.serverUrl + 'deleteSecret'
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        secretName: secretName,
        recoveryWindowInDays: recoveryWindowInDays
      }
    };
    return this.http.delete<any>(url, options).pipe(
      tap(() => this.secretDeleted.emit())
    );
  }
}
