<div class="row">
    <div class="col-xl-12 col-md-12">
        <div
            class="card earning-card success-request text-white overflow-hidden"
        >
            <span class="round secondary-round small"></span>
            <span class="round secondary-round big"></span>
            <div class="card-body text-center">
                <div class="row"></div>
                <span class="text-white d-block f-34 f-w-500 my-2">
                    Work In Progress
                </span>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-md-6">
        <div
            class="card earning-card bg-secondary-dark text-white overflow-hidden"
        >
            <span class="round secondary-round small"></span>
            <span class="round secondary-round big"></span>
            <div class="card-body text-center">
                <div class="row"></div>
                <span class="text-white d-block f-34 f-w-500 my-2">
                    Total Request
                    <i class="ti ti-network opacity-50"></i>
                </span>
                <p class="mb-0 f-30 text-center">15,000</p>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-md-6">
        <div
            class="card earning-card success-request text-white overflow-hidden text-center"
        >
            <span class="round success-request-small small"></span>
            <span class="round success-request-big big"></span>
            <div class="card-body">
                <div class="row"></div>
                <span class="text-white d-block f-34 f-w-500 my-2">
                    Incoming Request Success
                    <i class="ti ti-arrow-down-circle opacity-50"></i>
                </span>
                <p class="mb-0 f-30 text-center">14,500</p>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-md-6">
        <div
            class="card earning-card failed-request text-white overflow-hidden text-center"
        >
            <span class="round failed-request-small small"></span>
            <span class="round failed-request-big big"></span>
            <div class="card-body">
                <div class="row"></div>
                <span class="text-white d-block f-34 f-w-500 my-2">
                    Incoming Request Failed
                    <i class="ti ti-alert-circle opacity-50"></i>
                </span>
                <p class="mb-0 f-30 text-center">500</p>
            </div>
        </div>
    </div>

    <div class="col-xl-12 col-md-12">
        <app-bar-chart />
    </div>

    <div class="row">
        <div class="col-xl-8 col-md-8">
            <app-card [showHeader]="false" cardClass="table-card customer-list">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col" sortable="customerId">
                                PMS System
                            </th>
                            <th scope="col" sortable="customerId">Status</th>
                            <th scope="col" sortable="customerId">
                                Middleware Job Id
                            </th>
                            <th scope="col" sortable="customerId">
                                Error Message
                            </th>
                            <th scope="col" sortable="customerId">Payload</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Mews</td>
                            <td>Failed</td>
                            <td>a1BWV000002FrZ72AK</td>
                            <td>Request Failed</td>
                            <td>something</td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="d-flex justify-content-between align-items-center p-2">
          <select class="form-select" title="select item per page" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="15">15 items per page</option>
            <option [ngValue]="20">20 items per page</option>
          </select>
          <ngb-pagination
            [collectionSize]="(total$ | async)!"
            [(page)]="service.page"
            [pageSize]="service.pageSize"
            class="ms-3"
          ></ngb-pagination>
        </div> -->
            </app-card>
        </div>
        <div class="col-xl-4 col-md-4">
            <app-card [showHeader]="false" cardClass="table-card customer-list">
                <div>
                    <pre
                        [style]="{
                            'white-space': 'pre-wrap',
                            'word-wrap': 'break-word',
                        }"
                        >{{ jsonData | json }}</pre
                    >
                </div>
            </app-card>
        </div>
    </div>
</div>
