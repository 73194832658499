import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef  } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { InputValidation } from 'app/shared/inputValidation';
import { MatSelectModule } from '@angular/material/select';
import { OhipCredentials } from 'app/interface/i-addSecret-interface';
import { CapitalizePipe } from 'app/capitalize.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { generateToken } from 'app/shared/utility';

@Component({
  selector: 'app-app-ohip-fields',
  standalone: true,
  imports: [MatButton, MatInputModule, ReactiveFormsModule,MatCheckboxModule, MatFormFieldModule, CommonModule, MatIconModule, MatTooltip, MatSelectModule, AppOhipFieldsComponent, CapitalizePipe],
  templateUrl: './app-ohip-fields.component.html',
  styleUrl: './app-ohip-fields.component.scss'
})
export class AppOhipFieldsComponent implements OnInit, OnChanges  {

  @Input() appType: string;
  @Input() appName: string;
  @Input() appBool: string;
  @Output() credentialsChanged = new EventEmitter<OhipCredentials[]>();
  @Output() showSyncReadFieldsChange = new EventEmitter<boolean>();

  addSecretForm: FormGroup;
  ohipForm: FormGroup;
  isDisabled: boolean = true;
  inputValidation = new InputValidation();
  isAppCredentails: boolean = false;
  environments: { key: string, value: string }[];
  showSyncReadFields = false;

  constructor(private formBuilder: FormBuilder,
    public splashScreen: FuseSplashScreenService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.ohipForm = this.formBuilder.group({
      ohipCredentials: this.formBuilder.array([])
    });
    this.addAppCredentialsField();
  }

  get ohipCredentials(): FormArray {
    return this.ohipForm.get('ohipCredentials') as FormArray;
  }

  getOhipCredentialsControls(): AbstractControl[] {
    return this.ohipCredentials.controls;
  }

  onCredentialsChanged(): void {
    this.enableDisabledFields();
    this.credentialsChanged.emit(this.ohipCredentials.value);
    this.disableWebhookSecretKeyFields();
  }

  addAppCredentialsField(): void {
    let generatedToken = generateToken(25);
    const ohipGroup = this.formBuilder.group({
      client_id: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      client_secret: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      x_app_key: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      username: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      password: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      grant_type: ['password', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      extSystemCode: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      propertyId: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      url: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      pmsDb: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      objectType: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      apiSource: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      syncStatus: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      webhookSecretKey: [{ value: generatedToken, disabled: true }, InputValidation.removeTrailingSpaceValidator()],
      showSyncReadFields: [false] 
    });  

    ohipGroup.valueChanges.subscribe(() => {
      this.ohipCredentials.updateValueAndValidity();
      this.onCredentialsChanged();
    });

    this.ohipCredentials.push(ohipGroup);
    this.onCredentialsChanged();
  }

  removeAppCredentialsField(index: number): void {
    this.ohipCredentials.removeAt(index);
    this.onCredentialsChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appBool'] && changes['appBool'].currentValue !== undefined) {
      this.updateValidationBasedOnAppBool(changes['appBool'].currentValue);
      this.cdr.detectChanges();
    }
  }

  private updateValidationBasedOnAppBool(appBool: string): void {
    this.ohipCredentials.controls.forEach(control => {
      const clientIdControl = control.get('client_id');
      const clientSecretControl = control.get('client_secret');
      const xAppKeyControl = control.get('x_app_key');
      const usernameControl = control.get('username');
      const passwordControl = control.get('password');
      const grantTypeControl = control.get('grant_type');
      const extSystemCodeControl = control.get('extSystemCode');
      const propertyIdControl = control.get('propertyId');
      const urlControl = control.get('url');
      const pmsDbControl = control.get('pmsDb');
      const objectTypeControl = control.get('objectType');
      const apiSourceControl = control.get('apiSource');
      const syncStatusControl = control.get('syncStatus');
      const webhookSecretKeyControl = control.get('webhookSecretKey');
  
      if (appBool === 'Webhook') {
        pmsDbControl.clearValidators();
        propertyIdControl.clearValidators();
        objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        if(this.showSyncReadFields) {
          clientIdControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          clientSecretControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          xAppKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          usernameControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          passwordControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          grantTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          extSystemCodeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          urlControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        } else {
          objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        }
      } else if (appBool === 'Sync/Read') {
        objectTypeControl.clearValidators();
        apiSourceControl.clearValidators();
        syncStatusControl.clearValidators();
        webhookSecretKeyControl.clearValidators();
        clientIdControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        clientSecretControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        xAppKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        usernameControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        passwordControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        grantTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        extSystemCodeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        propertyIdControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        urlControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
        pmsDbControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
      }
      clientIdControl.updateValueAndValidity();
      clientSecretControl.updateValueAndValidity();
      xAppKeyControl.updateValueAndValidity();
      usernameControl.updateValueAndValidity();
      passwordControl.updateValueAndValidity();
      grantTypeControl.updateValueAndValidity();
      extSystemCodeControl.updateValueAndValidity();
      propertyIdControl.updateValueAndValidity();
      urlControl.updateValueAndValidity();
      pmsDbControl.updateValueAndValidity();
      objectTypeControl.updateValueAndValidity();
      apiSourceControl.updateValueAndValidity();
      syncStatusControl.updateValueAndValidity();
      webhookSecretKeyControl.updateValueAndValidity();
    });
    this.ohipCredentials.updateValueAndValidity();
  }
  
  toggleSyncReadFields(): void {
    this.showSyncReadFields = !this.showSyncReadFields;
    this.showSyncReadFieldsChange.emit(this.showSyncReadFields);
    this.updateValidationBasedOnAppBool(this.appBool); 
    this.cdr.markForCheck();
    if (!this.showSyncReadFields) {
      this.ohipCredentials.controls.forEach(control => {
        control.get('client_id')?.reset('');
        control.get('client_secret')?.reset('');
        control.get('x_app_key')?.reset('');
        control.get('username')?.reset('');
        control.get('password')?.reset('');
        control.get('extSystemCode')?.reset('');
        control.get('url')?.reset('');
      });
    }
    this.ohipCredentials.updateValueAndValidity();
    this.ohipCredentials.updateValueAndValidity();
  }

  private enableDisabledFields(): void {
    this.ohipCredentials.controls.forEach((control) => {
      const webhookSecretKeyControl = control.get('webhookSecretKey');
      if (webhookSecretKeyControl && webhookSecretKeyControl.disabled) {
        webhookSecretKeyControl.enable({ emitEvent: false });
      }
    });
  }

  private disableWebhookSecretKeyFields(): void {
    this.ohipCredentials.controls.forEach((control) => {
      const webhookSecretKeyControl = control.get('webhookSecretKey');
      if (webhookSecretKeyControl && webhookSecretKeyControl.enabled) {
        webhookSecretKeyControl.disable({ emitEvent: false });
      }
    });
  }

}
