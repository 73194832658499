// Angular import
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { FetchCustomerDetailsService } from 'src/services/fetch-customer-details.service';
import { ActivatedRoute, Router } from '@angular/router';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FetchEnvTokenAndUrlService } from 'src/services/fetch-env-token-and-url.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { JwtTokenGenerateService } from 'src/services/jwt-token-generate.service';
import { DatePipe } from '@angular/common';
import { CloudwatchlogsService } from 'src/services/cloudwatch-logs.service';

@Component({
    selector: 'app-log-details',
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        MatStepperModule,
        MatNativeDateModule,
        HttpClientModule,
    ],
    templateUrl: './log-details.component.html',
    styleUrls: ['./log-details.component.scss'],
    providers: [DatePipe],
})
export class LogDetailsComponent implements OnInit {
    customers: any[] = []; // All log data
    filteredCustomers: any[] = []; // Filtered data after search
    displayedCustomers: any[] = []; // Data for the current page
    total: number = 0; // Total number of filtered log entries
    pageSize: number = 20; // Default page size
    page: number = 1; // Current page number
    searchText: string = ''; // Holds the search input text
    form: FormGroup; // Form group to manage form controls

    middlewareId: any;
    lambdaData: any;
    logGroupName: any;
    middlewareLogGroupName: any;
    lambdaGroupName: any;
    days: number = 10;
    isLoading: boolean = false;
    activeStepIndex: number = 0;

    firstFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
    });

    secondFormGroup = this._formBuilder.group({
        secondCtrl: ['', Validators.required],
    });

    isLinear = false;

    constructor(
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private clipboard: Clipboard,
        private cloudWatchLogsService: CloudwatchlogsService,
        private fb: FormBuilder,
        private router: Router,
    ) {
        this.form = this.fb.group({
            searchText: [''], // Search input
            pageSize: [20], // Page size dropdown
        });
    }

    ngOnInit(): void {
        this.form.get('pageSize')?.valueChanges.subscribe((newPageSize) => {
            this.pageSize = newPageSize;
            this.updateDisplayedCustomers();
        });
        this.route.paramMap.subscribe((params) => {
            this.middlewareId = params.get('middlewareId');
            this.middlewareLogGroupName = params.get('middlewareLogGroupName');
            this.lambdaGroupName = params.get('lambdaGroupName');
            if (this.middlewareId) {
                this.getLambdaLogs(this.middlewareId);
            }
        });
    }

    getLambdaLogs(middlewareId: string): void {
        // Get the parsedLogDetails from localStorage
        const parsedLogDetails = localStorage.getItem('parsedLogDetails');

        if (parsedLogDetails) {
            try {
                // Define the type for your log details
                interface LogDetail {
                    middlewareJobId: string;
                    secretName?: string;
                    [key: string]: any; // Add other optional fields if needed
                }

                // Parse the JSON string into an object with a known type
                const logDetailsObject: Record<string, LogDetail> =
                    JSON.parse(parsedLogDetails);

                // Convert the object to an array of values
                const logDetailsArray: LogDetail[] =
                    Object.values(logDetailsObject);

                // Find the record that matches the middlewareId
                const matchingRecord = logDetailsArray.find(
                    (record) => record.middlewareJobId === middlewareId,
                );

                if (matchingRecord) {
                    this.lambdaData = matchingRecord;
                } else {
                 
                }
            } catch (error) {
                console.error('Error parsing JSON from localStorage:', error);
            }
        } else {
            console.log('No parsedLogDetails found in localStorage.');
        }
    }

    onStepperSelectionChange(event: any): void {
        this.activeStepIndex = event.selectedIndex;
        if (this.activeStepIndex == 1) {
            this.onStepClick(this.activeStepIndex);
        }
    }

    // Check if the step is active
    isActiveStep(index: number): boolean {
        return this.activeStepIndex === index;
    }

    // Handle step click
    onStepClick(index: number): void {
        if (this.isActiveStep(index)) {
            if (this.filteredCustomers.length <= 0) {
                this.getWatchLogs();
            }
        }
    }

    async getWatchLogs(): Promise<void> {
        try {
            this.isLoading = true;
            const response = await this.fetchLogs();

            this.customers = response.body.map((log: string) => {
                const timestampMatch = log.match(/^\S+\s\S+/);
                const timestamp = timestampMatch ? timestampMatch[0] : 'N/A';

                const statusMatch = log.match(/\b(INFO|ERROR|WARNING)\b/);
                const status = statusMatch ? statusMatch[0] : 'N/A';

                const message = log
                    .replace(
                        /^\S+\s\S+\s+(INFO|ERROR|WARNING)\s+:\s+\[.*?\]/,
                        '',
                    )
                    .trim();

                let parsedMessage: any;
                try {
                    parsedMessage = JSON.parse(message); // Attempt to parse as JSON
                } catch {
                    parsedMessage = message; // If parsing fails, keep as string
                    this.isLoading = false;
                }

                return {
                    timestamp,
                    status,
                    message: parsedMessage,
                    showMore: false,
                };
            });

            this.filteredCustomers = [...this.customers];
            this.total = this.filteredCustomers.length;
            this.updateDisplayedCustomers();
            this.isLoading = false;
        } catch (error) {
            console.error('Error fetching logs:', error);
            this.isLoading = false;
        }
    }

    applySearch(): void {
        const searchText = this.form.get('searchText')?.value.toLowerCase();

        this.filteredCustomers = this.customers.filter(
            (customer) =>
                customer.timestamp.toLowerCase().includes(searchText) ||
                customer.status.toLowerCase().includes(searchText) ||
                JSON.stringify(customer.message)
                    .toLowerCase()
                    .includes(searchText),
        );

        this.page = 1;
        this.total = this.filteredCustomers.length;
        this.updateDisplayedCustomers();
    }

    updateDisplayedCustomers(): void {
        const pageSize = this.form.get('pageSize')?.value;
        const startIndex = (this.page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        this.displayedCustomers = this.filteredCustomers.slice(
            startIndex,
            endIndex,
        );
    }

    onPageChange(newPage: number): void {
        this.page = newPage;
        this.updateDisplayedCustomers();
    }

    toggleShowMore(customer: any): void {
        customer.showMore = !customer.showMore;
    }

    private async fetchLogs(): Promise<any> {
        // Simulated fetch logs logic
        return await this.cloudWatchLogsService
            .getLogs(this.middlewareLogGroupName, this.middlewareId, this.days)
            .toPromise();
    }

    redirectToCustomerList(): void {
        this.router.navigate(['/log/list']);
    }
}
