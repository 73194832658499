import { Component, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../../src/app/theme/shared/shared.module';
import { generateToken } from '../../../../../../src/app/theme/shared/utility';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-etl-form',
  templateUrl: './etl-form.component.html',
  standalone: true,
  imports: [CommonModule, SharedModule]
})
export class ETLFormComponent implements OnDestroy {
  @Input() applicationType!: string;
  @Output() formSubmit = new EventEmitter<any>();
  @Input() editMode: boolean = false;
  @Input() initialData: any;

  pmsForm!: FormGroup;
  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    if (this.editMode && this.initialData) {
      this.populateForm(this.initialData);
    } else {
      this.renderForms();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['applicationType'] && !changes['applicationType'].firstChange) {
      this.renderForms();
    }
    if (changes['editMode'] && changes['editMode'].currentValue && this.initialData) {
      this.populateForm(this.initialData);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initializeForm(): void {
    this.pmsForm = this.fb.group({
      pmsCredentials: this.fb.array([])
    });
  }

  private createCredentialGroup(data?: any): FormGroup {
    const isWebhook = this.applicationType === 'webhook';
    const callBackValue = data?.callBack || false;
    const group = this.fb.group({
      objectType: [data?.objectType || ''],
      apiSource: [data?.apiSource || ''],
      syncStatus: [data?.syncStatus || ''],
      webhookSecretKey: [{ value: data?.webhookSecretKey || generateToken(25), disabled: false }]
    });

    if (isWebhook) {
      this.subscribeToCallBackChanges(group);
    }

    return group;
  }

  private subscribeToCallBackChanges(group: FormGroup): void {
    const callBackControl = group.get('callBack');
    if (callBackControl) {
      callBackControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((isChecked: boolean | null) => {
        this.updateFieldValidators(group, isChecked);
      });
    }
  }

  private updateFieldValidators(group: FormGroup, isChecked: boolean | null): void {
    const fieldsToUpdate = ['client_name', 'client_token', 'access_token', 'api_url', 'propertyId', 'pmsDb'];
    fieldsToUpdate.forEach((fieldName) => {
      const control = group.get(fieldName);
      if (control) {
        if (fieldName === 'pmsDb') {
          control.setValue('');
        } else if (isChecked) {
          control.setValidators([Validators.required]);
          control.setValue('');
        } else {
          control.clearValidators();
          control.setValue('');
        }
        control.updateValueAndValidity();
      }
    });
  }

  private getDynamicValidators(isWebhook: boolean, callBack?: boolean): Validators[] {
    if (isWebhook) {
      return callBack ? [Validators.required] : [];
    }
    return [Validators.required];
  }

  private manageFieldArray(action: 'add' | 'remove', index?: number, data?: any): void {
    if (action === 'add') {
      this.pmsCredentials.push(this.createCredentialGroup(data));
    } else if (action === 'remove' && index !== undefined) {
      this.pmsCredentials.removeAt(index);
    }
  }

  addAppCredentialsField(): void {
    this.manageFieldArray('add');
  }

  private populateForm(data: any): void {
    const values = JSON.parse(data);
    this.pmsCredentials.clear();
    values.forEach((credential: any) => this.manageFieldArray('add', undefined, credential));
  }

  private renderForms(): void {
    this.pmsCredentials.clear();
    this.addAppCredentialsField();
  }

  get pmsCredentials(): FormArray {
    return this.pmsForm.get('pmsCredentials') as FormArray;
  }

  getPmsCredentialsControls(): AbstractControl[] {
    return this.pmsCredentials.controls;
  }

  removeAppCredentialsField(index: number): void {
    this.manageFieldArray('remove', index);
  }

  shouldShowField(credential: AbstractControl): boolean {
    return credential.get('callBack')?.value || this.applicationType !== 'webhook';
  }

  isInvalid(controlName: string, index: number): boolean {
    const control = (this.pmsCredentials.at(index) as FormGroup).get(controlName);
    return control ? control.invalid && control.touched : false;
  }

  onSubmit(): void {
    this.cdr.detectChanges();
    if (this.pmsForm.invalid) {
      return;
    }
    this.formSubmit.emit(this.pmsForm.getRawValue());
  }
}
