<div class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto addFormContainer" cdkScrollable>

  <!-- Header -->
  <div
    class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 pb-22 sm:pt-12 sm:pb-28 sm:px-10 bg-default dark:bg-transparent">
    <div class="flex-1 min-w-0">
      <!-- Breadcrumbs -->
      <div>
        <div class="hidden sm:flex flex-wrap items-center font-medium">
          <div>
            <a class="whitespace-nowrap text-primary-500">Secret Manager</a>
          </div>
          <div class="flex items-center ml-1 whitespace-nowrap">
            <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
            <a class="ml-1 text-primary-500">Add New</a>
          </div>
          <div class="flex items-center ml-1 whitespace-nowrap">
            <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
            <span class="ml-1 text-secondary">Secret</span>
          </div>
        </div>
      </div>
      <!-- Title -->
      <div class="mt-2">
        <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
          <mat-icon class="backButton" (click)="goBack()" svgIcon="heroicons_solid:chevron-double-left"
            matTooltip="Click to go back"></mat-icon>
          Add New Secret Manager
        </h2>
      </div>
    </div>

  </div>

  <!-- Main -->
  <div class="flex-auto -mt-16 p-6 sm:p-10 rounded-t-2xl shadow bg-card">

    <div class="secretsDetailContainer">

      <form class="mat-table" [formGroup]="addSecretForm" (ngSubmit)="onSubmit()">

        <div class="options">
          <div class="text-2xl font-extrabold tracking-tight mb-4">Generate Secret Name</div>
          <mat-form-field appearance="fill" class="mr-2">
            <input matInput formControlName="customerId" placeholder=" Enter Customer ID*" (input)="generateSecret()">
            <mat-error *ngIf="addSecretForm.get('customerId').hasError('required')">Customer Id is required</mat-error>
          </mat-form-field>

         

          <mat-form-field appearance="fill" class="mr-2">
            <mat-select formControlName="appName" placeholder="Select App Name*" (selectionChange)="generateSecret()">
              <mat-option value="mews">Mews</mat-option>
              <mat-option value="ohip">Ohip</mat-option>
              <mat-option value="stayntouch">StayNTouch</mat-option>
              <mat-option value="hconnect">HConnect</mat-option>
              <mat-option value="hotelkey">HotelKey</mat-option>
              <mat-option value="stripe">Stripe</mat-option>
              <mat-option value="sertifi">Sertifi</mat-option>
              <mat-option value="cvent">Cvent</mat-option>
            </mat-select>
            <mat-error *ngIf="addSecretForm.get('appName').hasError('required')">App Name is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="mr-2">
            <mat-select (selectionChange)="onTypeChange($event.value)" placeholder="Select App Type">
              <mat-option value="Webhook">Webhook</mat-option>
              <mat-option value="Sync/Read">Sync/Read</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="mr-2">
            <mat-select formControlName="environment" placeholder="Select Environment*"
              (selectionChange)="generateSecret()">
              <mat-option *ngFor="let env of environments" [value]="env.value">{{ env.key }}</mat-option>
            </mat-select>
            <mat-error *ngIf="addSecretForm.get('environment').hasError('required')">Environment is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="mr-2 w-1/4">
            <input matInput formControlName="secretName" placeholder="Secret Name (Auto generated)"
              class="secretNameInput" style="white-space: pre-wrap; overflow-x: auto;">
            <mat-error *ngIf="addSecretForm.get('secretName').hasError('required')">Secret Name is required</mat-error>
            <mat-error *ngIf="addSecretForm.get('secretName').hasError('invalidUnderscorePattern')">Secret Name must
              follow the pattern: 'organizationName_customer_app_environment' with underscores</mat-error>
          </mat-form-field>
        </div>
        <div class="column text-2xl font-extrabold tracking-tight mb-8 mt-6">

          <div class="salesforceCred">
            Salesforce Credentials
            <mat-icon class="statusIcon ml-2"
              [ngClass]="{'text-green-500': salesforceTokenRetrieved, 'text-gray-500': !salesforceTokenRetrieved}">
              check_circle
            </mat-icon>
          </div>
          <div class="getToken">
            <button mat-flat-button [color]="'primary'" type="button" class="saleforceConnectivity"
              [disabled]="salesforceTokenRetrieved" (click)="getSalesforceToken()">Check Connectivity</button>
          </div>
        </div>
        <hr>
        <div class="column">
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Auth URL</mat-label>
            <input matInput formControlName="sfdc_auth_url" placeholder="Salesforce Auth URL"
              style="white-space: pre-wrap; overflow-x: auto;">
            <mat-error *ngIf="addSecretForm.get('sfdc_auth_url').hasError('required')">Salesforce Auth URL is
              required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Object URL</mat-label>
            <input matInput formControlName="sfdc_object_url" placeholder="Salesforce Object URL">
            <mat-error *ngIf="addSecretForm.get('sfdc_object_url').hasError('required')">Salesforce Object URL is
              required</mat-error>
            <mat-error *ngIf="addSecretForm.get('sfdc_object_url').hasError('invalidUrl')">Invalid URL format. Ensure it
              ends with .com and doesn't start with https://.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Client ID</mat-label>
            <input matInput formControlName="sfdc_client_id" placeholder="Salesforce Client ID">
            <mat-error *ngIf="addSecretForm.get('sfdc_client_id').hasError('required')">Salesforce Client ID is
              required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Client Secret</mat-label>
            <input matInput formControlName="sfdc_client_secret" placeholder="Salesforce Client Secret">
            <mat-error *ngIf="addSecretForm.get('sfdc_client_secret').hasError('required')">Salesforce Client Secret is
              required</mat-error>
          </mat-form-field>
        </div>
        <div class="column mt-6">
          <mat-form-field appearance="fill">
            <mat-label>Salesforce Grant Type</mat-label>
            <input matInput formControlName="sfdc_grant_type" placeholder="Salesforce Grant Type" [readonly]="true"
              class="secretNameInput">
            <mat-error *ngIf="addSecretForm.get('sfdc_grant_type').hasError('required')">Salesforce Grant Type is
              required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label class="labelSaleforce">Salesforce Token (optional)</mat-label>
            <input matInput formControlName="sfdc_token" placeholder="Salesforce Token">
          </mat-form-field>
          <a></a>

        </div>
        <div *ngIf="addSecretForm.get('appName').value === 'mews'">
          <app-app-mews-fields 
              [appName]="addSecretForm.get('appName').value"
              [appType]="selectedType" 
              (credentialsChanged)="onMewsCredentialsChanged($event)" 
              [appBool]="selectedType"
              (showSyncReadFieldsChange)="onShowSyncReadFieldsChange($event)">
          </app-app-mews-fields>
        </div>
      
      <div *ngIf="addSecretForm.get('appName').value === 'stayntouch'">
          <app-app-stayntouch-fields 
              [appName]="addSecretForm.get('appName').value"
              [appType]="selectedType" 
              (credentialsChanged)="onStayntouchCredentialsChanged($event)" 
              [appBool]="selectedType"
              (showSyncReadFieldsChange)="onShowSyncReadFieldsChange($event)">
          </app-app-stayntouch-fields>
      </div>
      
      <div *ngIf="addSecretForm.get('appName').value === 'ohip'">
          <app-app-ohip-fields 
              [appName]="addSecretForm.get('appName').value"
              [appType]="selectedType" 
              (credentialsChanged)="onOhipCredentialsChanged($event)" 
              [appBool]="selectedType"
              (showSyncReadFieldsChange)="onShowSyncReadFieldsChange($event)">
          </app-app-ohip-fields>
      </div>
      
      <div *ngIf="addSecretForm.get('appName').value === 'hconnect'">
          <app-app-hconnect-fields 
              [appType]="addSecretForm.get('appName').value"
              (credentialsChanged)="onHconnectCredentialsChanged($event)" 
              [appBool]="selectedType">
          </app-app-hconnect-fields>
      </div>
      
      <div *ngIf="addSecretForm.get('appName').value === 'hotelkey'">
          <app-app-hotelkey-fields 
              [appType]="addSecretForm.get('appName').value"
              (credentialsChanged)="onHotelkeyCredentialsChanged($event)" 
              [appBool]="selectedType">
          </app-app-hotelkey-fields>
      </div>
      
      <div *ngIf="addSecretForm.get('appName').value === 'stripe'">
          <app-app-stripe-fields 
              [appName]="addSecretForm.get('appName').value"
              [appType]="selectedType" 
              (credentialsChanged)="onStripeCredentialsChanged($event)" 
              [appBool]="selectedType"
              (showSyncReadFieldsChange)="onShowSyncReadFieldsChange($event)">
          </app-app-stripe-fields>
      </div>
      
      <div *ngIf="addSecretForm.get('appName').value === 'sertifi'">
          <app-app-sertifi-fields 
              [appName]="addSecretForm.get('appName').value"
              [appType]="selectedType" 
              (credentialsChanged)="onSertifiCredentialsChanged($event)" 
              [appBool]="selectedType"
              (showSyncReadFieldsChange)="onShowSyncReadFieldsChange($event)">
          </app-app-sertifi-fields>
      </div>

      <div *ngIf="addSecretForm.get('appName').value === 'cvent'">
          <app-app-cvent-fields 
              [appName]="addSecretForm.get('appName').value"
              [appType]="selectedType" 
              (credentialsChanged)="onCventCredentialsChanged($event)" 
              [appBool]="selectedType"
              (showSyncReadFieldsChange)="onShowSyncReadFieldsChange($event)">
          </app-app-cvent-fields>
      </div>

        <div class="submitBtn">
          <button mat-raised-button type="submit" [disabled]="!addSecretForm.valid"
            matTooltip="Click to add secret manager">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>