<form [formGroup]="pmsForm" (ngSubmit)="onSubmit()">
    <app-card cardTitle="STRIPE Credentials">
        <div
            *ngFor="
                let credential of getPmsCredentialsControls();
                let i = index
            "
        >
            <div *ngIf="applicationType != 'webhook'">
                Stripe Sync/Read or Callback Functionality not available.
            </div>
            <div [formGroup]="credential" class="form-group row">
                <div class="row" *ngIf="applicationType === 'webhook'">
                    <div class="col-lg-4 mb-3">
                        <label class="form-label">Object Type</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="objectType"
                        />
                        <div
                            *ngIf="isInvalid('objectType', i)"
                            class="text-danger"
                        >
                            Object Type ID is required.
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <label class="form-label">API Source</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="apiSource"
                        />
                        <div
                            *ngIf="isInvalid('apiSource', i)"
                            class="text-danger"
                        >
                            API Source ID is required.
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <label class="form-label">Sync Status</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="syncStatus"
                        />
                        <div
                            *ngIf="isInvalid('syncStatus', i)"
                            class="text-danger"
                        >
                            sync status ID is required.
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <label class="form-label">Webhook API Key</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="webhookSecretKey"
                        />
                        <p *ngIf="!editMode" class="auto-generate-info">
                            Automatically Generated Key, which can be edited in
                            the Edit form.
                        </p>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <!-- Submit -->
        <div class="d-flex justify-content-center">
            <button
                class="btn btn-dribbble"
                style="margin-right: 10px"
                (click)="onCancel()"
            >
                Cancel
            </button>
            <button
                type="submit"
                class="btn btn-primary"
                *ngIf="applicationType === 'webhook'"
                [disabled]="pmsForm.invalid"
            >
                Submit
            </button>
        </div>
    </app-card>
</form>
