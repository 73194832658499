<app-secret-form
  [editMode]="isEditMode"
  [initialData]="secretFormData"
  [secretForm]="secretForm"
  (secretFormObject)="onSecretFormObject($event)"
></app-secret-form>

<app-salesforce-form
  *ngIf="isSecretKeyGenerated"
  [editMode]="isEditMode"
  [initialData]="salesforceFormData"
  [salesforceForm]="salesforceForm"
  (salesforceFormObject)="onSalesforceFormObject($event)"
></app-salesforce-form>

<app-mews-form
  [editMode]="isEditMode"
  [initialData]="mewsFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'mews'"
  [applicationType]="applicationType"
  (formSubmit)="onMewsFormSubmit($event)"
></app-mews-form>

<app-ohip-form
  [editMode]="isEditMode"
  [initialData]="ohipFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'ohip'"
  [applicationType]="applicationType"
  (formSubmit)="onOhipFormSubmit($event)"
></app-ohip-form>

<app-stayntouch-form
  [editMode]="isEditMode"
  [initialData]="stayntouchFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'stayntouch'"
  [applicationType]="applicationType"
  (formSubmit)="onStayntouchFormSubmit($event)"
></app-stayntouch-form>

<app-stripe-form
  [editMode]="isEditMode"
  [initialData]="stripeFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'stripe'"
  [applicationType]="applicationType"
  (formSubmit)="onStripeFormSubmit($event)"
></app-stripe-form>

<app-sertifi-form
  [editMode]="isEditMode"
  [initialData]="sertifiFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'sertifi'"
  [applicationType]="applicationType"
  (formSubmit)="onSertifiFormSubmit($event)"
></app-sertifi-form>

<app-cvent-form
  [editMode]="isEditMode"
  [initialData]="cventFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'cvent'"
  [applicationType]="applicationType"
  (formSubmit)="onCventFormSubmit($event)"
></app-cvent-form>

<app-hotelkey-form
  [editMode]="isEditMode"
  [initialData]="hotelkeyFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'hotelkey'"
  [applicationType]="applicationType"
  (formSubmit)="onHotelkeyFormSubmit($event)"
></app-hotelkey-form>

<app-hconnect-form
  [editMode]="isEditMode"
  [initialData]="hconnectFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'hconnect'"
  [applicationType]="applicationType"
  (formSubmit)="onHconnectFormSubmit($event)"
></app-hconnect-form>

<app-etl-form
  [editMode]="isEditMode"
  [initialData]="etlFormData"
  *ngIf="isSalesforceTokenGenerated && applicationName === 'etl'"
  [applicationType]="applicationType"
  (formSubmit)="onEtlFormSubmit($event)"
></app-etl-form>
