<div class="page-header">
    <div class="page-block">
        <div class="row align-items-center">
            <div
                class="col-md-12 d-sm-flex align-items-center justify-content-between"
            >
                <div class="page-header-title">
                    <h5 class="m-b-10">
                        <i
                            (click)="redirectToCustomerList()"
                            class="back-button ti ti-chevrons-left"
                        ></i>

                        Customer Details
                    </h5>
                </div>
                <ul
                    class="breadcrumb"
                    *ngIf="hideUpdateButton && userRole == 'admin'"
                >
                    <li class="breadcrumb-item">
                        <button
                            (click)="editSecretName()"
                            class="btn btn-primary"
                        >
                            Update Customer
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<app-card [cardTitle]="customerId | uppercase">
    <mat-stepper #stepper orientation="vertical" [linear]="isLinear">
        <mat-step [stepControl]="firstFormGroup">
            <ng-template matStepLabel>
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                        <i
                            ng-reflect-ng-class="ti ti-users me-2"
                            class="text-2xl ti ti-users"
                        ></i>
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h5 class="mb-0">Customer Profile</h5>
                        <small class="text-muted">Profile Settings</small>
                    </div>
                </div>
            </ng-template>
            <div class="align-items-center mb-3 mt-2">
                <div class="customerInfo">
                    <div class="customerId">
                        <h5 class="heading">Secret Name</h5>
                        <span class="text-muted">{{ secretName }}</span>
                    </div>
                    <div class="customerId">
                        <h5 class="heading">Customer ID</h5>
                        <span class="text-muted">{{ customerId }}</span>
                    </div>
                    <div class="customerName">
                        <h5 class="heading">Application Name</h5>
                        <span class="text-muted">{{ appName }}</span>
                    </div>
                    <div class="customerName">
                        <h5 class="heading">Application Type</h5>
                        <span class="text-muted">{{ applicationType }}</span>
                    </div>
                    <div class="customerName">
                        <h5 class="heading">Environment</h5>
                        <span class="text-muted">{{ environment }}</span>
                    </div>
                    <div class="createdDate">
                        <h5 class="heading">Created Date</h5>
                        <span class="text-muted">{{ createdDate }}</span>
                    </div>
                </div>
                <hr />
                <div *ngIf="applicationType != 'webhook'">
                    <div class="mt-2 d-flex justify-content-between">
                        <h5>
                            <i class="ti ti-link"></i>
                            URL
                        </h5>
                        <p>
                            {{ apiEnvironmentUrl }}
                            <button
                                (click)="copyToClipboard('apiEnvironmentUrl')"
                                class="btn btn-link p-0 ms-2"
                                title="Copy URL"
                            >
                                <i class="ti ti-copy"></i>
                            </button>
                        </p>
                    </div>
                    <div class="mt-2 d-flex justify-content-between">
                        <h5>
                            <i class="ti ti-info-circle"></i>
                            Secret Token
                        </h5>
                        <p>
                            {{ apiEnvironmentToken }}
                            <button
                                (click)="copyToClipboard('apiEnvironmentToken')"
                                class="btn btn-link p-0 ms-2"
                                title="Copy URL"
                            >
                                <i class="ti ti-copy"></i>
                            </button>
                        </p>
                    </div>
                    <div class="mt-2">
                        <div class="tokenContainer">
                            <h5 class="tokenHeading">
                                <i class="ti ti-info-circle"></i>
                                JWT Token
                            </h5>
                            <p class="webToken">{{ token }}</p>
                            <button
                                (click)="copyToClipboard('token')"
                                class="btn btn-link p-0 ms-2 inline-end"
                                title="Copy URL"
                            >
                                <i class="ti ti-copy"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="applicationType == 'webhook'">
                    <div class="mt-2 d-flex justify-content-between">
                        <h5>
                            <i class="ti ti-link"></i>
                            Webhook API URL
                        </h5>
                        <p>
                            {{ webhookApiUrlType1 }}
                            <button
                                (click)="copyToClipboard('webhookApiUrlType1')"
                                class="btn btn-link p-0 ms-2"
                                title="Copy URL"
                            >
                                <i class="ti ti-copy"></i>
                            </button>
                        </p>
                    </div>
                    <div class="mt-2 d-flex justify-content-between">
                        <h5>
                            <i class="ti ti-link"></i>
                            Webhook API URL
                        </h5>
                        <p>
                            {{ webhookApiUrlType2 }}
                            <button
                                (click)="copyToClipboard('webhookApiUrlType2')"
                                class="btn btn-link p-0 ms-2"
                                title="Copy URL"
                            >
                                <i class="ti ti-copy"></i>
                            </button>
                        </p>
                    </div>
                    <div class="mt-2 d-flex justify-content-between">
                        <h5>
                            <i class="ti ti-info-circle"></i>
                            Webhook Secret Key
                        </h5>
                        <p>
                            {{ webhookSecretKey }}
                            <button
                                (click)="copyToClipboard('webhookSecretKey')"
                                class="btn btn-link p-0 ms-2"
                                title="Copy URL"
                            >
                                <i class="ti ti-copy"></i>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                            <i
                                ng-reflect-ng-class="ti ti-users me-2"
                                class="text-2xl ti ti-file-description"
                            ></i>
                        </div>
                        <div class="flex-grow-1 ms-2">
                            <h5 class="mb-0">Salesforce Details</h5>
                            <small class="text-muted"
                                >Credentials Information</small
                            >
                        </div>
                    </div>
                </ng-template>
                <pre class="secretJson">{{ salesforceData | json }}</pre>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                            <i
                                ng-reflect-ng-class="ti ti-users me-2"
                                class="text-2xl ti ti-table-alias"
                            ></i>
                        </div>
                        <div class="flex-grow-1 ms-2">
                            <h5 class="mb-0">Application Details</h5>
                            <small class="text-muted"
                                >Application Information</small
                            >
                        </div>
                    </div>
                </ng-template>
                <div class="row">
                    <pre class="secretJson">{{ applicationData | json }}</pre>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                            <i
                                ng-reflect-ng-class="ti ti-users me-2"
                                class="text-2xl ti ti-shield-lock"
                            ></i>
                        </div>
                        <div class="flex-grow-1 ms-2">
                            <h5 class="mb-0">Complete Customer Details</h5>
                            <small class="text-muted"
                                >AWS Secret Key Details</small
                            >
                        </div>
                    </div>
                </ng-template>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <pre class="secretJson">{{
                                displaySecretData | json
                            }}</pre>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
    </mat-stepper>
</app-card>
