/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { GuideDownloadService } from 'app/services/guide-download.service';

export const defaultNavigation: FuseNavigationItem[] = [
    
    {
        id   : 'secretList',
        title: 'Secret Manager',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/secrets'
    },
    {
        id   : 'cloudwatchlogs',
        title: 'Watch Logs',
        type : 'basic',
        icon : 'heroicons_outline:cloud',
        link : '/lambdalogs'
    },
    {
        id   : 'guides',
        title: 'Guides',
        type : 'collapsable',
        icon : 'heroicons_outline:book-open',
        children: [
            {
                id   : 'jwtGuideDocument',
                title: 'JWT Guide',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/guideDocument'
            },
            {
                id   : 'connectedAppGuideDocument',
                title: 'Salesforce Connected App Guide',
                type : 'basic',
                icon : 'heroicons_outline:document-arrow-down',
                function: (item: FuseNavigationItem) => {
                    GuideDownloadService.downloadFile('salesforce-connected-app-guide.pdf');
                }
            }
        ]
    }
    
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-list',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'secretList',
        title: 'Secret Manager',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-list',
        link : '/secrets'
    },
    {
        id   : 'cloudwatchlogs',
        title: 'Watch Logs',
        type : 'basic',
        icon : 'heroicons_outline:cloud',
        link : '/lambdalogs'
    },
    {
        id   : 'guides',
        title: 'Guides',
        type : 'collapsable',
        icon : 'heroicons_outline:book-open',
        children: [
            {
                id   : 'jwtGuideDocument',
                title: 'JWT Guide',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/guideDocument'
            },
            {
                id   : 'connectedAppGuideDocument',
                title: 'Salesforce Connected App Guide',
                type : 'basic',
                icon : 'heroicons_outline:document-arrow-down',
                function: (item: FuseNavigationItem) => {
                    GuideDownloadService.downloadFile('salesforce-connected-app-guide.pdf');
                }
            }
        ]
    }
];
