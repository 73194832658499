<div class="row">
  <div class="col-sm-12">
    <app-card [showHeader]="false" cardClass="table-card customer-list">
      <form class="table-responsive">
        <div class="datatable-top d-flex">
          <div class="datatable-top w-100 align-items-center mb-3">
            <!-- Left side with three dropdowns -->
            <div class="d-flex gap-2 w-50">
              <select
                class="form-select"
                title="Select Environment"
                [ngModel]="selectedEnvironment"
                (change)="onEnvironmentChange($event.target.value)"
                name="selectedEnvironment"
              >
                <option value="" disabled selected>Select Environment</option>
                <option value="all" disabled>All</option>
                <option *ngFor="let env of environments" [value]="env.value">{{ env.key }}</option>
              </select>

              <div>
                <input
                  id="table-complete-search"
                  type="text"
                  [ngModel]="selectedNoOfDays"
                  (change)="onDayChange($event.target.value)"
                  class="form-control"
                  name="Log History"
                  placeholder="Enter no of days eg: 1"
                />
              </div>

              <div>
                <button class="btn btn-primary" (click)="onFilterChange()">Find Lambda Logs</button>
              </div>
            </div>

            <!-- Right side with search input and add button -->
            <div class="datatable-search d-flex align-items-center gap-2">
              <input
                id="table-complete-search"
                type="text"
                class="form-control"
                name="searchTerm"
                [(ngModel)]="service.searchTerm"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <div class="image-div" *ngIf="(customer$ | async)?.length <= 0">
          <img class="inner-image" src="../../../../../assets/images/thynk-cloud/no-logs.jpg" />
        </div>
        <table class="table table-hover" *ngIf="(customer$ | async)?.length > 0">
          <thead>
            <tr>
              <th scope="col"><input class="form-check-input" type="checkbox" title="Select All" /></th>
              <th scope="col" sortable="timestamp" (sort)="onSort($event)">Timestamp</th>
              <th scope="col" sortable="middlewareJobId" (sort)="onSort($event)">Middleware Job Id</th>
              <th scope="col" sortable="pmsSystem" (sort)="onSort($event)">Status</th>
              <th scope="col" sortable="createdDate" (sort)="onSort($event)">Application Name</th>
              <th scope="col" sortable="action" (sort)="onSort($event)" class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            @for (customer of customer$ | async; track customer) {
              <tr>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" title="checkbox" />
                  </div>
                </td>
                <td>
                  <div>
                    <div class="d-flex align-items-start flex-column">
                      <ngb-highlight result="{{ customer.timestamp }}" [term]="service.searchTerm" class="h5 mb-0"></ngb-highlight>
                    </div>
                  </div>
                </td>

                <td>
                  <div>
                    <div class="d-flex align-items-start flex-column">
                      <ngb-highlight result="{{ customer.middlewareJobId }}" [term]="service.searchTerm" class="h5 mb-0"></ngb-highlight>
                      <ngb-highlight
                        result="{{ customer.secretName }}"
                        [term]="service.searchTerm"
                        class="text-muted f-12 mb-0"
                      ></ngb-highlight>
                    </div>
                  </div>
                </td>

                <td>
                  @if (customer.status === 'Completed') {
                    <ngb-highlight
                      [result]="customer.status"
                      [term]="service.searchTerm"
                      class="badge bg-light-success rounded-pill f-12"
                    ></ngb-highlight>
                  } @else if (customer.status === 'Error') {
                    <ngb-highlight
                      [result]="customer.status"
                      [term]="service.searchTerm"
                      class="badge bg-light-info rounded-pill f-12"
                    ></ngb-highlight>
                  }
                </td>
                <td><ngb-highlight result="{{ customer.pmsSystem }}" [term]="service.searchTerm"></ngb-highlight></td>

                <td class="text-center">
                  <button type="button" class="btn btn-icon btn-link-dark" (click)="copyMiddlewareId(customer.middlewareJobId)">
                    <i class="ti ti-copy"></i>
                  </button>
                  <button type="button" class="btn btn-icon btn-link-info" (click)="redirectToDetailPage(customer.middlewareJobId)">
                    <i class="ti ti-eye"></i>
                  </button>
                  <div class="btn-page"></div>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div class="d-flex justify-content-between align-items-center p-2">
          <select class="form-select" title="select item per page" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="15">15 items per page</option>
            <option [ngValue]="20">20 items per page</option>
          </select>
          <ngb-pagination
            [collectionSize]="(total$ | async)!"
            [(page)]="service.page"
            [pageSize]="service.pageSize"
            class="ms-3"
          ></ngb-pagination>
        </div>
      </form>
      <div class="loader-main" *ngIf="isLoading$ | async">
        <div class="loader-content">
          <img src="../../../../../assets/images/thynk-cloud/loader.gif" alt="Loading..." />
          <p>Optimizing your request... Almost done.</p>
        </div>
      </div>
    </app-card>
  </div>
</div>
