<form [formGroup]="pmsForm" (ngSubmit)="onSubmit()">
    <app-card cardTitle="MEWS Credentials">
        <div
            *ngFor="
                let credential of getPmsCredentialsControls();
                let i = index
            "
        >
            <div
                class="mb-4"
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                "
                *ngIf="applicationType != 'webhook'"
            >
                <h4>{{ i + 1 }} | (PMS) Mews Credentials</h4>
                <div>
                    <button
                        type="button"
                        class="btn btn-icon btn-link-danger"
                        *ngIf="i != 0"
                        (click)="removeAppCredentialsField(i)"
                    >
                        <i class="ti ti-trash"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-icon btn-link-secondary"
                        *ngIf="i == 0"
                        (click)="addAppCredentialsField()"
                    >
                        <i class="ti ti-plus"></i>
                    </button>
                </div>
            </div>
            <div [formGroup]="credential" class="form-group row">
                <div class="row" *ngIf="applicationType === 'webhook'">
                    <div class="col-lg-4 mb-3">
                        <label class="form-label">Object Type</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="objectType"
                        />
                        <div
                            *ngIf="isInvalid('objectType', i)"
                            class="text-danger"
                        >
                            Object Type ID is required.
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <label class="form-label">API Source</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="apiSource"
                        />
                        <div
                            *ngIf="isInvalid('apiSource', i)"
                            class="text-danger"
                        >
                            API Source ID is required.
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <label class="form-label">Sync Status</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="syncStatus"
                        />
                        <div
                            *ngIf="isInvalid('syncStatus', i)"
                            class="text-danger"
                        >
                            sync status ID is required.
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <label class="form-label">Webhook API Key</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="webhookSecretKey"
                        />
                        <p *ngIf="!editMode" class="auto-generate-info">
                            Automatically Generated Key, which can be edited in
                            the Edit form.
                        </p>
                    </div>
                    <div class="col-lg-4 mb-3">
                        <div style="margin-top: 40px">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                formControlName="callBack"
                                (change)="
                                    onCallBackChange($event.target.checked)
                                "
                            />
                            <label
                                class="form-check-label"
                                style="padding-left: 10px"
                                for="flexCheckDefault"
                                >Callback functionality</label
                            >
                        </div>
                    </div>
                </div>

                <div
                    class="row"
                    *ngIf="
                        applicationType !== 'webhook' ||
                        (applicationType === 'webhook' &&
                            credential.get('callBack')?.value)
                    "
                >
                    <div
                        class="col-lg-4 mb-3"
                        *ngIf="shouldShowField(credential)"
                    >
                        <label class="form-label">Client Name</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="client_name"
                        />
                        <div
                            *ngIf="isInvalid('client_name', i)"
                            class="text-danger"
                        >
                            Client Name is required.
                        </div>
                    </div>
                    <div
                        class="col-lg-4 mb-3"
                        *ngIf="shouldShowField(credential)"
                    >
                        <label class="form-label">API URL</label>
                        <div class="input-group search-form">
                            <input
                                type="text"
                                formControlName="api_url"
                                class="form-control"
                            />
                            <span class="input-group-text bg-transparent"
                                ><i class="feather icon-link"></i
                            ></span>
                        </div>
                        <div
                            *ngIf="isInvalid('api_url', i)"
                            class="text-danger"
                        >
                            Valid API URL is required.
                        </div>
                    </div>
                    <div
                        class="col-lg-4 mb-3"
                        *ngIf="shouldShowField(credential)"
                    >
                        <label class="form-label">Client Token</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="client_token"
                        />
                        <div
                            *ngIf="isInvalid('client_token', i)"
                            class="text-danger"
                        >
                            Client Token is required.
                        </div>
                    </div>
                    <div
                        class="col-lg-4 mb-3"
                        *ngIf="shouldShowField(credential)"
                    >
                        <label class="form-label">Access Token</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="access_token"
                        />
                        <div
                            *ngIf="isInvalid('access_token', i)"
                            class="text-danger"
                        >
                            Access Token is required.
                        </div>
                    </div>

                    <div
                        class="col-lg-4 mb-3"
                        *ngIf="shouldShowField(credential)"
                    >
                        <label class="form-label">Property ID</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="propertyId"
                        />
                        <div
                            *ngIf="isInvalid('propertyId', i)"
                            class="text-danger"
                        >
                            Property ID is required.
                        </div>
                    </div>
                    <div
                        class="col-lg-4 mb-3"
                        *ngIf="shouldShowField(credential)"
                    >
                        <label class="form-label">PMS DB</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="pmsDb"
                        />
                        <div *ngIf="isInvalid('pmsDb', i)" class="text-danger">
                            PMS DB is required.
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <!-- Submit -->
        <div class="d-flex justify-content-center">
            <button
                class="btn btn-dribbble"
                style="margin-right: 10px"
                (click)="onCancel()"
            >
                Cancel
            </button>

            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="pmsForm.invalid"
            >
                Submit
            </button>
        </div>
    </app-card>
</form>
