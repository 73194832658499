// angular import
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

// project import
import { AuthenticationService } from '../service/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    // constructor
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {}

    // public method
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        const currentUser = this.authenticationService.currentUserValue;

        if (!currentUser) {
            this.router.navigate(['/auth/auth2/login'], {
                queryParams: { returnUrl: state.url },
            });
            return false;
        }

        // Safely get role
        const role = localStorage.getItem('role') || '';

        // Get list of restricted roles from route data
        const restrictedRoles: string[] = route.data['restrictedRoles'] || [];
        console.log(restrictedRoles);
        // Block access if role is in the restricted list
        if (restrictedRoles.includes(role)) {
            // alert('Access Denied: You are not authorized to access this page.');
            this.router.navigate(['/default']);
            return false;
        }

        return true;
    }
}
