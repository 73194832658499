// Angular import
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { FetchCustomerDetailsService } from 'src/services/fetch-customer-details.service';
import { ActivatedRoute } from '@angular/router';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FetchEnvTokenAndUrlService } from 'src/services/fetch-env-token-and-url.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { JwtTokenGenerateService } from 'src/services/jwt-token-generate.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-customer-details',
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        MatStepperModule,
        MatNativeDateModule,
        HttpClientModule,
    ],
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
    providers: [DatePipe],
})
export class CustomerDetailsComponent implements OnInit {
    createdDate: string | null = null;
    secretName: string | null = null;
    customerId: string = '';
    appName: string = '';
    environment: string = '';
    secretDetails: any = {};

    displaySecretData: any = {};

    salesforceData: any = {};

    apiEnvironmentUrl!: string;
    apiEnvironmentToken!: string;
    token!: string;
    applicationType: string | null = null;

    applicationData: any;
    applicationDataComplete: any;
    webhookApiUrlType1: any;
    webhookApiUrlType2: any;
    webhookSecretKey: any;

    firstFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
    });

    secondFormGroup = this._formBuilder.group({
        secondCtrl: ['', Validators.required],
    });

    isLinear = false;

    constructor(
        private _formBuilder: FormBuilder,
        private customerDetails: FetchCustomerDetailsService,
        private route: ActivatedRoute,
        private fetchEnvService: FetchEnvTokenAndUrlService,
        private clipboard: Clipboard,
        private jwtTokenService: JwtTokenGenerateService,
        private datePipe: DatePipe,
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.createdDate = params.get('createdDate');
            this.secretName = params.get('secretName');
            this.applicationType = params.get('applicationType');
            if (this.secretName) {
                const { customerId, appName, environment } =
                    this.parseSecretName(this.secretName);
                this.customerId = customerId;
                this.appName = appName;
                this.environment = environment;
                this.getSecretDetails(this.secretName);

                if (this.applicationType == 'webhook') {
                    this.generateWebhookURL(environment, customerId, appName);
                }
                if (this.applicationType != 'webhook') {
                    this.fetchApiDetails();
                    this.generateToken();
                }
            }
        });
    }

    parseSecretName(secretName: string) {
        const parts = secretName.split('_');

        if (parts.length < 4) {
            console.error('Unexpected secret name format:', secretName);
            return {
                secretName: secretName,
                organizationName: '',
                customerId: '',
                appName: '',
                environment: '',
            };
        }

        const organizationName = parts[0];
        const customerId = parts.slice(1, -2).join('_');
        const appName = parts[parts.length - 2];
        const environment = parts[parts.length - 1];

        return {
            secretName: secretName,
            organizationName: organizationName,
            customerId: customerId,
            appName: appName,
            environment: environment,
        };
    }

    getSecretDetails(secretName: string): void {
        this.customerDetails.getSecretValue(secretName).subscribe(
            (response) => {
                this.secretDetails = response.body[0];
                if (this.secretDetails) {
                    console.log(this.appName);
                    const name = this.appName;
                    this.applicationData =
                        this.secretDetails && this.secretDetails[name]
                            ? JSON.parse(this.secretDetails[name])
                            : {};
                    console.log(this.applicationData);
                    this.webhookSecretKey =
                        this.applicationData[0].webhookSecretKey;
                    console.log(this.webhookSecretKey);
                    this.createDisplaySecretData(this.appName);
                } else {
                    console.error('Secret data is null.');
                }
            },
            (error) => {
                console.error('Error fetching secret details:', error);
            },
        );
    }

    generateWebhookURL(env: string, customerId: string, appName: string) {
        this.webhookApiUrlType1 =
            'https://' +
            env +
            '.webhook.thynk.cloud/' +
            appName +
            '?cust_id=' +
            customerId;
        this.webhookApiUrlType2 =
            'https://' +
            env +
            '.webhook.thynk.cloud/' +
            appName +
            '/' +
            customerId;
    }

    createDisplaySecretData(appName: String): void {
        const createDisplayData = (appData: any, appType: string) => ({
            secretName: this.secretName,
            sfdc_auth_url: this.secretDetails.sfdc_auth_url,
            sfdc_client_id: this.secretDetails.sfdc_client_id,
            sfdc_client_secret: this.secretDetails.sfdc_client_secret,
            sfdc_grant_type: this.secretDetails.sfdc_grant_type,
            sfdc_object_url: this.secretDetails.sfdc_object_url,
            sfdc_token: this.secretDetails.sfdc_token,
            [appType]: appData,
            applicationType: this.secretDetails.type,
        });

        this.displaySecretData = createDisplayData(
            this.applicationData,
            appName.toString(),
        );
        this.salesforceData = {
            sfdc_auth_url: this.secretDetails.sfdc_auth_url,
            sfdc_client_id: this.secretDetails.sfdc_client_id,
            sfdc_client_secret: this.secretDetails.sfdc_client_secret,
            sfdc_grant_type: this.secretDetails.sfdc_grant_type,
            sfdc_object_url: this.secretDetails.sfdc_object_url,
            sfdc_token: this.secretDetails.sfdc_token,
        };
        this.applicationDataComplete = {
            appName: this.applicationData,
            applicationType: this.secretDetails.type,
        };
    }

    async fetchApiUrl(): Promise<void> {
        try {
            const environment = this.extractEnvironmentFromSecretName(
                this.secretName!,
            );
            this.apiEnvironmentUrl = this.fetchEnvService.getApiUrl(
                environment,
                this.secretDetails.type,
            );
        } catch (error) {
            console.error('Error generating token:', error);
        }
    }

    async fetchApiToken(): Promise<void> {
        try {
            const environment = this.extractEnvironmentFromSecretName(
                this.secretDetails,
            );
            this.apiEnvironmentToken = await this.fetchEnvService.getToken(
                environment,
                this.secretDetails.type,
            );
        } catch (error) {
            console.error('Error fetching token:', error);
        }
    }

    extractEnvironmentFromSecretName(secretName: string): string {
        const parts = secretName.split('_');
        if (parts.length === 5) {
            return parts[4];
        } else if (parts.length === 4) {
            return parts[3];
        } else {
            throw new Error('Invalid secret name format');
        }
    }

    async fetchApiDetails(): Promise<void> {
        try {
            const environment = this.extractEnvironmentFromSecretName(
                this.secretName!,
            );
            this.apiEnvironmentUrl = await this.fetchEnvService.getApiUrl(
                environment,
                this.secretDetails.type,
            );
            this.apiEnvironmentToken = await this.fetchEnvService.getToken(
                environment,
                this.secretDetails.type,
            );
        } catch (error) {
            console.error('Error fetching API details:', error);
        }
    }

    copyToClipboard(
        type:
            | 'webhookSecretKey'
            | 'webhookApiUrlType1'
            | 'webhookApiUrlType2'
            | 'apiEnvironmentUrl'
            | 'apiEnvironmentToken'
            | 'token',
    ) {
        let textToCopy = '';

        if (type === 'webhookSecretKey') {
            textToCopy = this.webhookSecretKey;
        } else if (type === 'webhookApiUrlType1') {
            textToCopy = this.webhookApiUrlType1;
        } else if (type === 'webhookApiUrlType2') {
            textToCopy = this.webhookApiUrlType2;
        } else if (type === 'apiEnvironmentUrl') {
            textToCopy = this.apiEnvironmentUrl;
        } else if (type === 'apiEnvironmentToken') {
            textToCopy = this.apiEnvironmentToken;
        } else if (type === 'token') {
            textToCopy = this.token;
        }
        if (textToCopy) {
            this.clipboard.copy(textToCopy);
        } else {
            console.warn(`${type.toUpperCase()} is not defined`);
        }
    }

    async generateToken(): Promise<void> {
        try {
            const secretName = this.secretName;
            const response = await this.jwtTokenService
                .generateToken(secretName!)
                .toPromise();
            this.token = response.body[0];
        } catch (error) {
            console.error('Error generating token:', error);
        }
    }
}
