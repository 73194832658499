<div class="page-header">
  <div class="page-block">
    <div class="row align-items-center">
      <div class="col-md-12 d-sm-flex align-items-center justify-content-between">
        <div class="page-header-title">
          <h5 class="m-b-10">MIddleware Logs</h5>
        </div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a title="Home">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="var(--bs-secondary)"
                class="icon icon-tabler icons-tabler-filled icon-tabler-home"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M12.707 2.293l9 9c.63 .63 .184 1.707 -.707 1.707h-1v6a3 3 0 0 1 -3 3h-1v-7a3 3 0 0 0 -2.824 -2.995l-.176 -.005h-2a3 3 0 0 0 -3 3v7h-1a3 3 0 0 1 -3 -3v-6h-1c-.89 0 -1.337 -1.077 -.707 -1.707l9 -9a1 1 0 0 1 1.414 0m.293 11.707a1 1 0 0 1 1 1v7h-4v-7a1 1 0 0 1 .883 -.993l.117 -.007z"
                />
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item">
            <a class="text-muted f-w-500">Log Details</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-card [cardTitle]="">
  <mat-stepper #stepper orientation="vertical" [linear]="isLinear" (selectionChange)="onStepperSelectionChange($event)">
    <mat-step [stepControl]="firstFormGroup">
      <ng-template matStepLabel>
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i ng-reflect-ng-class="ti ti-users me-2" class="text-2xl ti ti-users"></i>
          </div>
          <div class="flex-grow-1 ms-2">
            <h5 class="mb-0">Lambda Logs</h5>
            <small class="text-muted">Incoming Request Log</small>
          </div>
        </div>
      </ng-template>
      <div class="align-items-center mb-3 mt-2">
        <div class="customerInfo">
          <div class="lambdaLogs">
            {{ lambdaData | json }}
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>
          <div class="d-flex align-items-center" [class.active]="isActiveStep(1)">
            <div class="flex-shrink-0">
              <i ng-reflect-ng-class="ti ti-users me-2" class="text-2xl ti ti-file-description"></i>
            </div>
            <div class="flex-grow-1 ms-2">
              <h5 class="mb-0">Middleware Logs</h5>
              <small class="text-muted">PMS System Log</small>
            </div>
          </div>
        </ng-template>

        <div [formGroup]="form">
          <div class="align-items-center d-flex justify-content-end mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search logs..."
              formControlName="searchText"
              (input)="applySearch()"
              style="width: 300px"
            />
          </div>

          <table class="table table-hover" *ngIf="displayedCustomers.length > 0">
            <thead>
              <tr>
                <th scope="col">Timestamp</th>
                <th scope="col">Status</th>
                <th scope="col">Message</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let customer of displayedCustomers; trackBy: trackByCustomer">
                <td>{{ customer.timestamp }}</td>
                <td>{{ customer.status }}</td>
                <td>
                  <div>
                    <pre
                      [ngClass]="{ 'preformatted-text': true }"
                      [ngStyle]="{
                        'max-height': customer.showMore ? 'none' : '6em',
                        overflow: customer.showMore ? 'visible' : 'hidden'
                      }"
                      >{{ customer.message | json }}
            </pre
                    >
                    <button class="btn btn-link p-0 mt-1" (click)="toggleShowMore(customer)">
                      {{ customer.showMore ? 'Read less' : 'Read more' }}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="d-flex justify-content-between align-items-center p-2">
            <select
              class="form-select"
              title="Select items per page"
              style="width: auto"
              name="pageSize"
              formControlName="pageSize"
              (change)="updateDisplayedCustomers()"
            >
              <option [ngValue]="10">10 items per page</option>
              <option [ngValue]="15">15 items per page</option>
              <option [ngValue]="20">20 items per page</option>
            </select>

            <ngb-pagination
              [collectionSize]="total"
              [(page)]="page"
              [pageSize]="form.get('pageSize')?.value"
              (pageChange)="onPageChange($event)"
              class="ms-3"
            ></ngb-pagination>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</app-card>
<div class="loader-main" *ngIf="isLoading">
  <div class="loader-content">
    <img src="../../../../../assets/images/thynk-cloud/loader.gif" alt="Loading..." />
    <p>Optimizing your request... Almost done.</p>
  </div>
</div>
