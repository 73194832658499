<div
    class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto container"
    cdkScrollable>

    <!-- Header -->
    <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 pb-22 sm:pt-12 sm:pb-28 sm:px-10 bg-default dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <!-- Breadcrumbs -->
            <div>
                <div class="hidden sm:flex flex-wrap items-center font-medium">
                    <div>
                        <a class="whitespace-nowrap text-primary-500">Secret Details</a>
                    </div>
                    <div class="flex items-center ml-1 whitespace-nowrap">
                        <mat-icon
                            class="icon-size-5 text-secondary"
                            [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
                        <a class="ml-1 text-primary-500">Guide</a>
                    </div>
                    <div class="flex items-center ml-1 whitespace-nowrap">
                        <mat-icon
                            class="icon-size-5 text-secondary"
                            [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
                        <span class="ml-1 text-secondary">Guide Document</span>
                    </div>
                </div>
            </div>
            <!-- Title -->
            <div class="mt-2">
              <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                  <mat-icon class="backButton" (click)="goBack()" svgIcon="heroicons_solid:chevron-double-left" matTooltip="Click to go back"></mat-icon>
                  JWT Guide Document
                </h2>
            </div>
        </div>
        
    </div>

    <!-- Main -->
    <div class="flex-auto -mt-16 p-6 sm:p-10 rounded-t-2xl shadow bg-card">

        <div class="ml-6">
            <h3 class="ml-2">Following are the steps for utilizing Tokens & Urls</h3>
        </div>
        <mat-vertical-stepper orientation="vertical" #stepper>
            <mat-step label="Sign in to your Salesforce org">
                <div class="mt-4">
                    <h4>Sign in to your Salesforce org</h4>
                </div>
                <div class="mt-4">
                    <img src="assets/images/jwtGuideImages/step-1.png" alt="sign in to Salesforce org" class="loginImg">
                </div>
            </mat-step>
            <mat-step label="Navigate to the Setup Menu and search for 'Custom Metadata Types'">
                <div class="navigateStep">
                    <div class="mt-4">
                        <h4>Navigate to the Setup Menu</h4>
                    </div>
                    <div class="mt-4 setup ">
                        <img src="assets/images/jwtGuideImages/step-2-1.png" alt="sign in to Salesforce org" class="loginImg">
                    </div>
                    <div class="mt-4">
                        <h4>Search for Custom Metadata Types</h4>
                    </div>
                    <div class="mt-4 metaData">
                        <img src="assets/images/jwtGuideImages/step-2-2.png" alt="sign in to Salesforce org" class="loginImg">
                    </div>
                </div>
            </mat-step>
            <mat-step label="Locate 'Middleware Connectors' from the Custom Metadata Types list and select it">
                <div class="mt-4">
                    <h4>Locate 'Middleware Connectors' from the Custom Metadata Types list and select it</h4>
                </div>
                <div class="mt-4">
                    <img src="assets/images/jwtGuideImages/step-3.png" alt="sign in to Salesforce org" class="loginImg">
                </div>
            </mat-step>
            <mat-step label="Next, click on 'Property,' and on the subsequent screen, click 'Edit'">
                <div class="mt-4">
                    <h4>Next, click on 'Property,' and on the subsequent screen, click 'Edit'</h4>
                </div>
                <div class="mt-4">
                    <img src="assets/images/jwtGuideImages/step-4.png" alt="sign in to Salesforce org" class="loginImg">
                </div>
            </mat-step>
            <mat-step label="Paste the Endpoint (copied from the Utility API URL) into the Endpoint field">
                <div class="mt-4">
                    <h4>Paste the Endpoint (copied from the Utility API URL) into the Endpoint field</h4>
                </div>
                <div class="mt-4">
                    <img src="assets/images/jwtGuideImages/step-5.png" alt="sign in to Salesforce org" class="loginImg">
                </div>
            </mat-step>
            <mat-step label="Paste the Middleware API Token (copied from the Utility API Token) into the Middleware API Token field">
                <div class="mt-4">
                    <h4>Paste the Middleware API Token (copied from the Utility API Token) into the Middleware API Token field</h4>
                </div>
                <div class="mt-4">
                    <img src="assets/images/jwtGuideImages/step-5.png" alt="sign in to Salesforce org" class="loginImg">
                </div>
            </mat-step>
            <mat-step label="Paste the Middleware JWT Token (copied from the Utility JWT Token) into the Middleware JWT Token field">
                <div class="mt-4">
                    <h4>Paste the Middleware JWT Token (copied from the Utility JWT Token) into the Middleware JWT Token field</h4>
                </div>
                <div class="mt-4">
                    <img src="assets/images/jwtGuideImages/step-5.png" alt="sign in to Salesforce org" class="loginImg">
                </div>
            </mat-step>
            <mat-step label="Click on Save Button">
                <div class="mt-4">
                    <h4>Click on Save Button</h4>
                </div>
                <div class="mt-4">
                    <img src="assets/images/jwtGuideImages/step-5.png" alt="sign in to Salesforce org" class="loginImg">
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </div>

</div>
