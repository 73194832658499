<div class="row">
    <div class="d-flex gap-2 align-items-center">
        <div class="col-auto">
            <select
                class="form-select"
                title="Select Environment"
                [(ngModel)]="defaultEnvironment"
                name="selectedEnvironment"
            >
                <option value="" disabled selected>Select Environment</option>
                <!-- <option value="all">All</option> -->
                <option *ngFor="let env of environments" [value]="env.value">
                    {{ env.key }}
                </option>
            </select>
        </div>
        <div class="col-auto align-items-center">
            <select
                class="form-select p-r-35 me-3"
                title="Select Date"
                name="selectedDate"
                [(ngModel)]="defaultDate"
            >
                <option value="all" disabled>Select Date Period</option>
                <option value="today">Today</option>
                <option value="week">Week</option>
            </select>
        </div>
        <div class="col-auto align-items-center">
            <select
                class="form-select"
                title="Select Environment"
                name="selectedApplicationType"
                [(ngModel)]="selectedApplicationType"
                (change)="onChnageData($event)"
            >
                <option value="sync/read">Sync/Read</option>
                <option value="webhook">Webhook</option>
            </select>
        </div>
        <div class="ol-auto align-items-center">
            <button class="btn btn-primary" (click)="fetchDataFromAPI()">
                <!-- <i class="ti ti-search"></i>  -->
                Search
            </button>
        </div>
        <!-- Button div will be at last with margin-auto to push it to right -->
        <div class="datatable-search ms-auto m-2">
            <button class="btn btn-primary" (click)="reloadComponent()">
                <i class="ti ti-reload"></i>
            </button>
        </div>
    </div>

    <div class="row w-full mt-4">
        <div class="col-xl-4 col-md-6">
            <div
                class="card earning-card bg-secondary-dark text-white overflow-hidden"
            >
                <div class="card-body text-center">
                    <span class="text-white d-block f-34 f-w-500 my-2">
                        Request Count
                        <i class="ti ti-network opacity-50"></i>
                    </span>
                    <p class="mb-0 f-30 text-center">
                        {{ dashboardRequestsData.totalRequestsCount }}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-md-6">
            <div
                class="card earning-card success-request text-white overflow-hidden text-center"
            >
                <div class="card-body">
                    <span class="text-white d-block f-34 f-w-500 my-2">
                        Success
                        <i class="ti ti-arrow-down-circle opacity-50"></i>
                    </span>
                    <p class="mb-0 f-30 text-center">
                        {{ dashboardRequestsData.successfulRequestsCount }}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-md-6">
            <div
                class="card earning-card failed-request text-white overflow-hidden text-center"
            >
                <div class="card-body">
                    <span class="text-white d-block f-34 f-w-500 my-2">
                        Failed
                        <i class="ti ti-alert-circle opacity-50"></i>
                    </span>
                    <p class="mb-0 f-30 text-center">
                        {{ dashboardRequestsData.failedRequestsCount }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-12 col-md-12 mt-4">
        <div class="card">
            <div class="card-body">
                <div
                    class="row mb-3 align-items-center justify-content-between"
                >
                    <div class="col-auto">
                        <div class="f-32">Request Insights Overview</div>
                    </div>
                    <div class="checkbox-container">
                        <label *ngFor="let app of CheckBoxData">
                            <input
                                type="checkbox"
                                [value]="app.application"
                                (change)="
                                    toggleApplicationSelection(
                                        $event.target.checked,
                                        app.applicationName
                                    )
                                "
                                checked
                            />
                            {{ app.applicationName }}
                            <!-- Display the application name next to the checkbox -->
                        </label>
                    </div>
                </div>
                <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [dataLabels]="chartOptions.dataLabels"
                    [plotOptions]="chartOptions.plotOptions"
                    [responsive]="chartOptions.responsive"
                    [xaxis]="chartOptions.xaxis"
                    [colors]="chartOptions.colors"
                    [tooltip]="chartOptions.tooltip"
                ></apx-chart>
            </div>
            <!-- <div class="loader-main d-flex flex-column justify-content-center align-items-center text-center w-100 h-100"
                *ngIf="isLoading$ ===true">
                <div class="loader-content d-flex flex-column align-items-center"> <img
                        src="../../../../../../assets/images/thynk-cloud/loader.gif" alt="Loading..."
                        class="img-fluid mt-2 " /> </div>
            </div> -->
        </div>
    </div>

    <div
        *ngIf="selectedTableData && selectedTableData.length > 0"
        class="row mt-4"
    >
        <div class="col-xl-7 col-md-7">
            <app-card [showHeader]="false" cardClass="table-card customer-list">
                <div class="w-full overflow-x-auto p-10">
                    <div>
                        <div
                            class="col-auto d-flex align-items-center dropdown-cus"
                            *ngIf="showCustomerDropdown === true"
                        >
                            <select
                                style="height: 44px"
                                class="form-select"
                                [(ngModel)]="allCustomer"
                                (change)="CustomerChange($event)"
                            >
                            <!-- <option value="All Customers">All Customers</option> -->
                                <option
                                    *ngFor="let customer of customerList"
                                    [value]="customer"
                                >
                                    {{ customer }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="col-auto d-flex align-items-center search-box"
                        >
                            <div>
                                <input
                                    #searchInput
                                    id="table-complete-search"
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="searchValue"
                                    name="Log History"
                                    (keyup.enter)="
                                        onMiddlewareChanges(searchInput.value)
                                    "
                                    placeholder="Search..."
                                />
                            </div>

                            <button
                                class="search-customer btn btn-light-primary"
                                (click)="onMiddlewareChanges(searchInput.value)"
                            >
                                <i class="ti ti-zoom-code"></i>
                            </button>
                            <button
                                class="btn btn-light-danger"
                                (click)="resetMethod()"
                            >
                                <i class="ti ti-restore"></i>
                            </button>
                        </div>
                    </div>

                    <div
                        *ngIf="dataNotFound"
                        class="alert alert-danger margin-class"
                    >
                        {{ dataNotFound }}
                    </div>

                    <table
                        class="border-collapse text-left col-xl-12 col-md-12 text-sm"
                    >
                        <thead>
                            <tr class="bg-gray-100">
                                <th
                                    style="width: 18%"
                                    class="py-2 px-4 font-semibold"
                                >
                                    PMS System
                                </th>
                                <th
                                    style="width: 52%"
                                    class="py-2 px-4 font-semibold"
                                >
                                    Middleware Job Details
                                </th>
                                <th
                                    style="width: 20%"
                                    class="py-2 px-4 font-semibold"
                                >
                                    Message
                                </th>
                                <th
                                    style="width: 10%"
                                    class="py-2 px-4 font-semibold"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="showSelectedRecord">
                            <tr
                                *ngFor="
                                    let item of filteredTableData
                                        | paginate
                                            : {
                                                  itemsPerPage: itemsPerPage,
                                                  currentPage: page,
                                              }
                                "
                            >
                                <td class="py-2 px-4">
                                    <div
                                        class="d-flex align-items-start flex-column"
                                    >
                                        <ngb-highlight
                                            style="text-transform: capitalize"
                                            result="{{ item.application }}"
                                            class="h6 mb-0"
                                        ></ngb-highlight>
                                        <ngb-highlight
                                            result="Type: {{
                                                item.applicationType
                                            }}"
                                            class="text-muted f-12 mb-0"
                                        ></ngb-highlight>
                                        <ngb-highlight
                                            result="Env : {{
                                                item.environment
                                            }}"
                                            class="text-muted f-12 mb-0"
                                        ></ngb-highlight>
                                    </div>
                                </td>
                                <td class="py-2 w-auto">
                                    <div
                                        class="d-flex align-items-start flex-column"
                                    >
                                        <ngb-highlight
                                            result="{{ item.middlewareId }}"
                                            class="h5 mb-0"
                                        ></ngb-highlight>
                                        <ngb-highlight
                                            result="Customer Id"
                                            class="h6 mb-0"
                                        ></ngb-highlight>
                                        <ngb-highlight
                                            result="{{ item.secretkey }}"
                                            class="text-muted f-12 mb-0"
                                        ></ngb-highlight>
                                        <ngb-highlight
                                            result="Date : {{ item.date }}"
                                            class="text-muted f-12 mb-0"
                                        ></ngb-highlight>
                                    </div>
                                </td>
                                <td class="col-xl-2">
                                    <div
                                        class="d-flex align-items-start flex-column"
                                    >
                                        <ngb-highlight
                                            result="{{ item.middlewareId }}"
                                            class="h5 mb-0"
                                        ></ngb-highlight>
                                        <ngb-highlight
                                            [class.success-text]="
                                                item.isSuccessful === 'true'
                                            "
                                            [class.error-text]="
                                                item.isSuccessful === 'false' ||
                                                item.isSuccessful == null
                                            "
                                            result="Success: {{
                                                item.isSuccessful | titlecase
                                            }}"
                                            class="f-12 mb-0"
                                        ></ngb-highlight>
                                        <div>
                                            <ngb-highlight
                                                result="Message : {{ '' }}"
                                                class="text-muted f-12 mb-0"
                                            ></ngb-highlight>
                                            <span
                                                (click)="
                                                    redirectToPayload(item)
                                                "
                                                class="text-primary fs-6 fw-medium fw-bold cursor-pointer"
                                                >View details..</span
                                            >
                                        </div>
                                    </div>
                                </td>
                                <td class="py-2 px-2 d-flex">
                                    <button
                                        type="button"
                                        class="btn btn-icon btn-link-info"
                                        (click)="redirectToPayload(item)"
                                    >
                                        <i class="ti ti-eye"></i>
                                    </button>
                                    <button
                                        *ngIf="
                                            item.applicationType != 'webhook'
                                        "
                                        type="button"
                                        class="btn btn-icon btn-link-info"
                                        (click)="redirectToLogs(item)"
                                    >
                                        <i class="ti ti-file-text"></i>
                                    </button>
                                    <button
                                        *ngIf="
                                            item.applicationType ===
                                                'webhook' &&
                                            item.isSuccessful === 'true'
                                        "
                                        type="button"
                                        class="btn btn-icon btn-link-info"
                                        (click)="redirectToSalesforce(item)"
                                    >
                                        <i class="ti ti-external-link"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-flex align-items-center justify-content-between pagination-wrapper">
                        <!-- Rows per page dropdown -->
                    <!-- <div class="d-flex align-items-center">
                            <select id="pageSize" class="form-select" [(ngModel)]="itemsPerPage" (change)="updatePageSize()">
                                <option *ngFor="let size of pageSizes" [value]="size">{{ size }} items per page</option>
                            </select>
                        </div> -->

                    <!-- Pagination Controls -->
                    <!-- <nav aria-label="Page navigation">
                            <pagination-controls class="pagination-custom" (pageChange)="onPageChange($event)">
                            </pagination-controls>
                        </nav> -->
                    <!-- <button (click)="prevPage()" [disabled]="currentIndex === 0">Previous</button>
                        <span> {{ (currentIndex / pageSize) + 1 }} ..</span>
                        <button (click)="nextPage() " [disabled]="isNextDisabled">Next</button>
                    </div> -->
                    <div class="d-flex justify-content-end pagination-wrapper">
                        <button
                            (click)="prevPage()"
                            [disabled]="currentIndex === 0"
                        >
                            Previous
                        </button>
                        <span>
                            &nbsp; &nbsp;
                            {{ currentIndex / pageSize + 1 }}
                            ..&nbsp;&nbsp;&nbsp;</span
                        >
                        <button
                            (click)="nextPage()"
                            [disabled]="isNextDisabled"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </app-card>
        </div>

        <div
            id="messageContainer"
            class="col-xl-5 col-md-5"
            *ngIf="errorPayload"
        >
            <app-card [showHeader]="false" cardClass="table-card customer-list">
                <h2 class="text-center">Message</h2>
                <div class="p-4 json-container">
                    <pre class="json-content">{{ errorPayload | json }}</pre>
                </div>
            </app-card>
        </div>
    </div>
    <div class="loader-main" *ngIf="isLoading">
        <div class="loader-content">
            <img
                src="../../../../../assets/images/thynk-cloud/loader.gif"
                alt="Loading..."
            />
            <p>Optimizing your request... Almost done.</p>
        </div>
    </div>
</div>
