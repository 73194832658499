export const APP_CONSTANTS  = {
    SUCCESS_STATUS: 'SUCCESS',
    SUCCESS_MESSAGE: 'Secret added successfully',
    SECRETS_PAGE: './secrets',
    FAILED_TO_ADD_SECRET: 'Failed to add secret. Please try again.',
    ERROR_OCCURED: 'An error occurred:',
    MAT_SNACKBAR_CLOSE:'Close',
    MAT_SNACKBAR_DURATION: 3000,
    MAT_SNACKBAR_HORIZONTAL_POSITION: 'center',
    MAT_SNACKBAR_VERTICAL_POSITION: 'top'


  };