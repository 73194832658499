<div class="card">
  <div class="card-body">
    <div class="row mb-3 align-items-center">
      <div class="col">
        <div class="f-32">Request Insights Overview</div>
      </div>
      <div class="col-auto">
        <select class="form-select p-r-35" title="Select">
          <option selected>Today</option>
          <option>Last 7 Days</option>
          <option>Month</option>
          <option>Year</option>
        </select>
      </div>
    </div>
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [responsive]="chartOptions.responsive"
      [xaxis]="chartOptions.xaxis"
      [colors]="chartOptions.colors"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>
  </div>
</div>
