<app-navigation class="coded-navbar pc-sidebar" [ngClass]="{
    'navbar-collapsed': navCollapsed,
    horizontal: currentLayout === 'horizontal',
    compact: currentLayout === 'compact',
    'mob-open': navCollapsedMob
  }" (NavCollapsedMob)="navMobClick()"></app-navigation>
<app-nav-bar (NavCollapse)="this.navCollapsed = !this.navCollapsed" (NavCollapsedMob)="navMobClick()"></app-nav-bar>
<div class="pc-container">
  <div class="coded-wrapper">
    <div class="coded-content">
      <div class="coded-inner-content">
        <app-breadcrumb></app-breadcrumb>
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pc-menu-overlay" (click)="closeMenu()" (keydown)="handleKeyDown($event)" tabindex="0"></div>
</div>
<footer class="pc-footer">
  <div class="footer-wrapper container-fluid">
    <div class="row">
      <div class="col my-1">
        <p class="m-0">
          Copyright &copy;
          <a href="https://thynk.cloud/" target="_blank">Thynk Utility</a>
        </p>
      </div>
    </div>
  </div>
</footer>
<app-configuration></app-configuration>