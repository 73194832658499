import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddSecretManager } from 'app/interface/i-addSecret-interface';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddSecretsService {
  serverUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  createSecret(body: AddSecretManager): Observable<any> {
    const url = this.serverUrl + 'createSecret';
    return this.http.post(url, body);
  }

  mapFormDataToBody(formData: any, appName: string, type: string): AddSecretManager {
    let appCredentials;
    let apptype;
    if (appName === 'mews') {
      appCredentials = formData.mewsCredentials.map((creds: any) => ({
        client_name: creds.mews_client_name,
        client_token: creds.mews_client_token,
        access_token: creds.mews_access_token,
        api_url: creds.mews_api_url,
        pmsDb: creds.pmsDb,
        propertyId: creds.propertyId,
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey

      }));
    } else if (appName === 'stayntouch') {
      appCredentials = formData.stayNTouchCredentials.map((creds: any) => ({
        client_id: creds.stayNTouch_client_id,
        client_secret: creds.stayNTouch_client_secret,
        grant_type: 'client_credentials',
        authUrl: creds.stayNTouch_authUrl,
        apiUrl: creds.stayNTouch_apiUrl,
        access_token: creds.stayNTouch_access_token,
        propertyId: creds.propertyId,
        pmsDb: creds.pmsDb,
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));
    } else if (appName === 'ohip') {
      appCredentials = formData.ohipCredentials.map((creds: any) => ({
        client_id: creds.ohip_client_id,
        client_secret: creds.ohip_client_secret,
        x_app_key: creds.ohip_x_app_key,
        username: creds.ohip_username,
        password: creds.ohip_password,
        grant_type: 'password',
        extSystemCode: creds.extSystemCode,
        propertyId: creds.propertyId,
        url: creds.ohip_url,
        pmsDb: creds.pmsDb,
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));
    } else if (appName === 'hconnect') {
      appCredentials = formData.hconnectCredentials.map((creds: any) => ({
        client_id: creds.client_id,
        client_secret: creds.client_secret,
        scope: creds.scope,
        grant_type: 'client_credentials',
        propertyId: creds.propertyId,
        pmsDb: creds.pmsDb,
        url: creds.url,
        access_token: creds.access_token,
        authUrl: creds.authUrl,
        appKey: creds.applicationKey,
        hotelId: creds.hotelId,
        externalSystem: creds.externalSystem,
        webhookToken: creds.webhookToken
      }));
    } else if (appName === 'hotelkey') {
      appCredentials = formData.hotelkeyCredentials.map((creds: any) => ({
        hostName: creds.hostName,
        propertyCode: creds.propertyCode,
        propertyId: creds.propertyId,
        pmsDb: creds.pmsDb,
        webhookToken: creds.webhookToken
      }));  
    } else if (appName === 'stripe') {
      appCredentials = formData.stripeCredentials.map((creds: any) => ({
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      })); 
    } else if (appName === 'sertifi') {
      appCredentials = formData.sertifiCredentials.map((creds: any) => ({
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));
    } else if (appName === 'cvent') {
      appCredentials = formData.cventCredentials.map((creds: any) => ({
        objectType: creds.objectType,
        apiSource: creds.apiSource,
        syncStatus: creds.syncStatus,
        webhookSecretKey: creds.webhookSecretKey
      }));
    }
    return {
      secretName: formData.secretName,
      secretString: {
        sfdc_auth_url: formData.sfdc_auth_url,
        sfdc_client_id: formData.sfdc_client_id,
        sfdc_client_secret: formData.sfdc_client_secret,
        sfdc_grant_type: formData.sfdc_grant_type,
        sfdc_object_url: formData.sfdc_object_url,
        sfdc_token: formData.sfdc_token,
        [appName]: JSON.stringify(appCredentials),
        type: apptype,
      },
    };
  }
}

