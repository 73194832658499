import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FetchSalesforceTokenService } from 'src/services/fetch-salesforce-token.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-salesforce-form',
  templateUrl: './salesforce-form.component.html',
  styleUrls: ['./salesforce-form.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule]
})
export class SalesforceFormComponent {
  @Input() salesforceForm!: FormGroup;
  @Output() salesforceFormObject = new EventEmitter<Object>();
  @Input() editMode: boolean = false;
  @Input() initialData: any;
  tokenResult: string | null = null;
  isSalesforceTokenGenerated: boolean = false;
  loading = false;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    private fetchSalesforceTokenService: FetchSalesforceTokenService,
    private notifier: NotifierService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.editMode && this.initialData) {
      this.populateForm(this.initialData);
    }
  }

  populateForm(data: any): void {
    console.log(data);
    this.salesforceForm.patchValue({
      sfdc_auth_url: data.sfdc_auth_url,
      sfdc_client_id: data.sfdc_client_id,
      sfdc_client_secret: data.sfdc_client_secret,
      sfdc_object_url: data.sfdc_object_url,
      sfdc_token: data.sfdc_token
    });
    this.checkSalesforceConnectivity();
  }

  checkSalesforceConnectivity(): void {
    if (!this.salesforceForm.valid) {
      this.handleError('Please fill in all required fields');
      return;
    }

    this.startLoading();

    const { sfdc_auth_url, sfdc_client_id, sfdc_client_secret } = this.salesforceForm.value;
    const sfdc_grant_type = this.salesforceForm.get('sfdc_grant_type')?.value;

    this.fetchSalesforceTokenService.generateToken(sfdc_auth_url, sfdc_client_id, sfdc_client_secret, sfdc_grant_type).subscribe({
      next: (response) => this.handleSuccess(response),
      error: (error) => this.handleError(`Error: ${error}`)
    });
  }

  private startLoading(): void {
    this.loading = true;
    this.errorMessage = null;
  }

  private stopLoading(): void {
    this.loading = false;
  }

  private handleSuccess(response: any): void {
    if (response?.status === 'Success' && response.body?.length) {
      const token = response.body[0];
      this.tokenResult = token;
      this.updateSalesforceFormWithToken(token);
      this.isSalesforceTokenGenerated = !!token?.trim();
      this.toastr.success('Saleforce Connection Authorize Successfully.', 'Success');
      this.emitSalesforceFormObject(true, token);
    } else {
      this.handleError('Unexpected response format.');
    }
    this.finalizeRequest();
  }

  private handleError(message: string): void {
    console.log(message);
    this.toastr.error(message, 'Error');
    this.emitSalesforceFormObject(false);
    this.tokenResult = null;
    this.errorMessage = message;
    this.finalizeRequest();
  }

  private updateSalesforceFormWithToken(token: string): void {
    this.salesforceForm.patchValue({ token: token });
  }

  private emitSalesforceFormObject(isAuthorized: boolean, token?: string): void {
    const { sfdc_auth_url, sfdc_client_id, sfdc_client_secret, sfdc_object_url } = this.salesforceForm.value;
    const sfdc_grant_type = this.salesforceForm.get('sfdc_grant_type')?.value;

    const salesforceFormObject = {
      sfdc_auth_url,
      sfdc_client_id,
      sfdc_client_secret,
      sfdc_grant_type,
      sfdc_object_url,
      isSalesforceAuthorize: isAuthorized,
      token: token || null
    };

    this.salesforceFormObject.emit(salesforceFormObject);
  }

  private finalizeRequest(): void {
    this.stopLoading();
    this.cdr.detectChanges();
  }
}
