// Angular Imports
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// project import
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { AuthGuard } from './theme/shared/_helpers/auth.guard';
import { DefaultComponent } from './utility-app/dashboard/default/default.component';

const routes: Routes = [
    {
        path: '',
        component: GuestComponent,
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './utility-app/authentication/authentication-v2/v2-login/v2-login.component'
                    ).then((c) => c.V2LoginComponent),
            },
            {
                path: 'auth',
                loadChildren: () =>
                    import(
                        './utility-app/authentication/authentication.module'
                    ).then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '',
        component: AdminComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './utility-app/dashboard/default/default.component'
                    ).then((c) => c.DefaultComponent),
            },
            {
                path: 'default',
                loadComponent: () =>
                    import(
                        './utility-app/dashboard/default/default.component'
                    ).then((c) => c.DefaultComponent),
            },
            {
                path: 'analytics',
                loadComponent: () =>
                    import(
                        './utility-app/dashboard/analytics/analytics.component'
                    ).then((c) => c.AnalyticsComponent),
            },
            {
                path: 'finance',
                loadComponent: () =>
                    import(
                        './utility-app/dashboard/finance/finance.component'
                    ).then((c) => c.FinanceComponent),
            },
            {
                path: 'customer',
                loadChildren: () =>
                    import(
                        './utility-app/application/customer/customer.module'
                    ).then((m) => m.CustomerModule),
            },
            {
                path: 'help',
                loadChildren: () =>
                    import('./utility-app/application/help/help.module').then(
                        (m) => m.HelpModule,
                    ),
            },
            {
                path: 'log',
                loadChildren: () =>
                    import('./utility-app/application/log/log.module').then(
                        (m) => m.LogModule,
                    ),
            },
            {
                path: 'create/new/customer',
                canActivate: [AuthGuard],
                data: { restrictedRoles: ['support', 'user'] },
                loadComponent: () =>
                    import(
                        './utility-app/application/create-customer/create-customer.component'
                    ).then((c) => c.CreateCustomerComponent),
            },
            {
                path: '**',
                loadComponent: () =>
                    import(
                        './utility-app/dashboard/default/default.component'
                    ).then((c) => c.DefaultComponent),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
