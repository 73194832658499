import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit,  OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';
import { InputValidation } from 'app/shared/inputValidation';
import { MatSelectModule } from '@angular/material/select';
import { stripeCredentials } from 'app/interface/i-addSecret-interface';
import { CapitalizePipe } from 'app/capitalize.pipe';
import { generateToken } from 'app/shared/utility';


@Component({
  selector: 'app-app-stripe-fields',
  standalone: true,
  imports: [MatButton, MatInputModule, ReactiveFormsModule, CommonModule, MatIconModule, MatTooltip, MatSelectModule, AppStripeFieldsComponent, CapitalizePipe],
  templateUrl: './app-stripe-fields.component.html',
  styleUrl: './app-stripe-fields.component.scss'
})
export class AppStripeFieldsComponent implements OnInit, OnChanges {

  @Input() appType: string;
  @Input() appName: string;
  @Input() appBool: string;
  @Output() credentialsChanged = new EventEmitter<stripeCredentials[]>();
  @Output() showSyncReadFieldsChange = new EventEmitter<boolean>();

  addSecretForm: FormGroup;
  stripeForm: FormGroup;
  isDisabled: boolean = true;
  inputValidation = new InputValidation();
  isAppCredentails: boolean = false;
  environments: { key: string, value: string }[];
  showSyncReadFields = false;

  constructor(private formBuilder: FormBuilder,
    public splashScreen: FuseSplashScreenService,
    private cdr: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.stripeForm = this.formBuilder.group({
      stripeCredentials: this.formBuilder.array([])
    });
    this.addAppCredentialsField();

  }

  get stripeCredentials(): FormArray {
    return this.stripeForm.get('stripeCredentials') as FormArray;
  }

  getStripeCredentialsControls(): AbstractControl[] {
    return this.stripeCredentials.controls;
  }

  onCredentialsChanged(): void {
    this.credentialsChanged.emit(this.stripeCredentials.value);
  }

  addAppCredentialsField(): void {
    const stripeGroup = this.formBuilder.group({
      objectType: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      apiSource: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      syncStatus: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      webhookSecretKey: ['', this.appBool === 'Webhook' ? [InputValidation.removeTrailingSpaceValidator()] : [Validators.required, InputValidation.removeTrailingSpaceValidator()]],
      showSyncReadFields: [false]
    });

    stripeGroup.valueChanges.subscribe(() => {
      this.stripeCredentials.updateValueAndValidity();
      this.onCredentialsChanged();
    });

    this.stripeCredentials.push(stripeGroup);
    this.onCredentialsChanged();
  }

  removeAppCredentialsField(index: number): void {
    this.stripeCredentials.removeAt(index);
    this.onCredentialsChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appBool'] && changes['appBool'].currentValue !== undefined) {
      this.updateValidationBasedOnAppBool(changes['appBool'].currentValue);
      this.cdr.detectChanges();
    }
  }


  private updateValidationBasedOnAppBool(appBool: string): void {
    this.stripeCredentials.controls.forEach(control => {
      const objectTypeControl = control.get('objectType');
      const apiSourceControl = control.get('apiSource');
      const syncStatusControl = control.get('syncStatus');
      const webhookSecretKeyControl = control.get('webhookSecretKey');

      if (appBool === 'Webhook') {
          objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          if(this.showSyncReadFields) {
          } else {
            objectTypeControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            apiSourceControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            syncStatusControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
            webhookSecretKeyControl.setValidators([Validators.required, InputValidation.removeTrailingSpaceValidator()]);
          }
        
      } else if (appBool === 'Sync/Read') {
          objectTypeControl.clearValidators();
          apiSourceControl.clearValidators();
          syncStatusControl.clearValidators();
          webhookSecretKeyControl.clearValidators();
      }
      objectTypeControl.updateValueAndValidity();
      apiSourceControl.updateValueAndValidity();
      syncStatusControl.updateValueAndValidity();
      webhookSecretKeyControl.updateValueAndValidity();
  });
    this.stripeCredentials.updateValueAndValidity();
  }

}

