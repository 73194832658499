import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FetchEnvTokenAndUrlService {
  private urlsAndTokens = {
    dev: {
      apiUrl: 'https://dev.middleware.thynk.cloud',
      token: 'AN2itiuyMM2cMutuPZSUb3O46ntQqLrt9SnOkSbZ',
      webhookUrl: 'https://dev.webhooks.thynk.cloud',
      webhookToken: ''
    },
    test: {
      apiUrl: 'https://test.middleware.thynk.cloud',
      token: 'GQwWWYupfL2v4uDWvrJqj1gEhIReU5LM2gIE95RU',
      webhookUrl: 'https://test.webhooks.thynk.cloud',
      webhookToken: ''
    },
    prod: {
      apiUrl: 'https://prod.middleware.thynk.cloud',
      token: 'SYCOTPNfSdad89LvZJwVI1ARKiJvb9Dw9EC9WBXZ',
      webhookUrl: 'https://prod.webhooks.thynk.cloud',
      webhookToken: ''
    },
    staging: {
      apiUrl: 'https://staging.middleware.thynk.cloud',
      token: 'l7ysRZA81qahXKtoDktqS9TXoUHT8uUC9Ut1tfg1',
      webhookUrl: 'https://staging.webhooks.thynk.cloud',
      webhookToken: ''
    }
  };

  constructor() {}

  getApiUrl(environment: string, type: string): string {
    if (type === 'Webhook') {
      return this.urlsAndTokens[environment]?.webhookUrl;
    }
    return this.urlsAndTokens[environment]?.apiUrl;
  }

  setWebhookToken(environment: string, token: string): void {
    this.urlsAndTokens[environment].webhookToken = token;
  }

  getToken(environment: string, type: string): string {
    if (type === 'Webhook') {
      return this.urlsAndTokens[environment]?.webhookToken || '';
    }
    return this.urlsAndTokens[environment]?.token;
  }
}
