import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerList } from 'src/Interface/i-customerList-interface';

@Injectable({
  providedIn: 'root'
})
export class FetchCustomerListService {
  serverUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getSecrets(accessToken: string, filter: string): Observable<CustomerList> {
    const url = this.serverUrl + 'secretList?filter=' + filter;
    const requestBody = { jwtToken: accessToken };

    return this.http.post<CustomerList>(url, requestBody);
  }
}
