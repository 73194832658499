import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DymamoDbService {
  serverUrl = environment.apiUrl;
  secretDeleted: EventEmitter<void> = new EventEmitter<void>();

  private selectedTableDataSubject = new BehaviorSubject<any[]>([]);
  selectedTableData$ = this.selectedTableDataSubject.asObservable();

  constructor(private http: HttpClient) { }

  getAllItems(tableName: string, environment: string, dateFilter: string,applicationType:string): Observable<any> {
    const url = `${this.serverUrl}analytics/count?schema=${tableName}&environment=${environment}&dateFilter=${dateFilter}&applicationType=${applicationType}`;
    
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    
    return this.http.get<any>(url, options);
}
getApplicationItems(tableName: string, environment: string, dateFilter: string,applicationType:string, applicationName:string,isSuccessful:string,lastKey:string){

   var url;
  if (lastKey) {
     url = `${this.serverUrl}analytics?schema=${tableName}&environment=${environment}&dateFilter=${dateFilter}&applicationType=${applicationType}&applicationName=${applicationName.toLowerCase()}&isSuccessful=${isSuccessful}&lastKey=${lastKey}`;
     
  }else{
  
     url = `${this.serverUrl}analytics?schema=${tableName}&environment=${environment}&dateFilter=${dateFilter}&applicationType=${applicationType}&applicationName=${applicationName.toLowerCase()}&isSuccessful=${isSuccessful}`;
     console.log("url is -->",url);
  }

  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  };
  console.log(url);
  
  return this.http.get<any>(url, options);
}

getSerchedRecord(tableName: string, environment: string, dateFilter: string,applicationType:string,applicationName:string,isSuccessful:string,selectedValue:string): Observable<any> {
  const url = `${this.serverUrl}analytics/search?schema=${tableName}&environment=${environment}&dateFilter=${dateFilter}&applicationType=${applicationType}&applicationName=${applicationName.toLowerCase()}&isSuccessful=${isSuccessful}&selectedValue=${selectedValue}`;
  console.log("url is -->",url);

  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  };
  
  return this.http.get<any>(url, options);
}

  updateSelectedTableData(data: any[]): void {
    this.selectedTableDataSubject.next(data);
  }

  getSelectedTableData(): Observable<any[]> {
    return this.selectedTableData$;
  }
 
}
