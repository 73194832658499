<form [formGroup]="secretForm">
  <app-card cardTitle="Secret Name">
    <div class="form-group row">
      <div class="col-lg-2">
        <label class="form-label">Customer Id</label>
        <input type="text" class="form-control" formControlName="customerId" placeholder="Enter Customer Id" (input)="generateSecret()" />
        <div *ngIf="secretForm.get('customerId')?.invalid && secretForm.get('customerId')?.touched" class="text-danger">
          Customer ID is required.
        </div>
      </div>
      <div class="col-lg-2">
        <label class="form-label">Application Name</label>
        <select class="form-select" formControlName="appName" title="Select App Name" (change)="generateSecret()">
          <option value="" disabled selected>Select Application Name</option>
          <option *ngFor="let app of appName" [value]="app.value">{{ app.key }}</option>
        </select>
        <div *ngIf="secretForm.get('appName')?.invalid && secretForm.get('appName')?.touched" class="text-danger">
          Application Name is required.
        </div>
      </div>
      <div class="col-lg-2">
        <label class="form-label">Application Type</label>
        <select class="form-select" formControlName="appType" title="Select App Type" (change)="generateSecret()">
          <option value="" disabled selected>Select Application Type</option>
          <option value="sync/read">Sync / Read</option>
          <option value="webhook">Webhook</option>
        </select>
        <div *ngIf="secretForm.get('appType')?.invalid && secretForm.get('appType')?.touched" class="text-danger">
          Application Type is required.
        </div>
      </div>
      <div class="col-lg-2">
        <label class="form-label">Environment</label>
        <select class="form-select" formControlName="environment" title="Select Environment" (change)="generateSecret()">
          <option value="" disabled selected>Select Environment</option>
          <option *ngFor="let env of environments" [value]="env.value">{{ env.key }}</option>
        </select>
        <div *ngIf="secretForm.get('environment')?.invalid && secretForm.get('environment')?.touched" class="text-danger">
          Environment is required.
        </div>
      </div>
      <div class="col-lg-2">
        <label class="form-label">Secret Name (Auto Generated)</label>
        <input type="text" class="form-control" formControlName="secretName" placeholder="Secret Name (Auto Generated)" disabled />
        <div *ngIf="secretForm.get('secretName')?.invalid && secretForm.get('secretName')?.touched" class="text-danger">
          Secret Name must be generated properly.
        </div>
      </div>
    </div>
  </app-card>
</form>
