// Angular import
import { AsyncPipe, DecimalPipe, CommonModule } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren, Renderer2, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { customer } from './customer-data.config/customer';
import { CustomerDataService } from './customer-data.config/customer-data.service';
import { NgbdSortableHeaderDirective, SortEvent } from '../../../../theme/shared/directive/sortable.directive';

// rxjs library
import { finalize, Observable } from 'rxjs';

// bootstrap import
import { NgbModal, NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { getAppName, getProcessedEnvironments, getUserRole } from 'src/app/theme/shared/utility';
import { ModalModule } from 'src/app/theme/shared/components';
import { Clipboard } from '@angular/cdk/clipboard';
import { DeleteCustomerService } from 'src/services/delete-customer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customer-list',
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    NgbdSortableHeaderDirective,
    AsyncPipe,
    NgbTypeaheadModule,
    NgbPaginationModule,
    CommonModule,
    NgbTooltipModule,
    SharedModule,
    ModalModule
  ],
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  providers: [CustomerDataService, DecimalPipe, DatePipe]
})
export class CustomerListComponent implements OnInit {
  customer$!: Observable<customer[]>;
  total$!: Observable<number>;

  @ViewChildren(NgbdSortableHeaderDirective) headers!: QueryList<NgbdSortableHeaderDirective>;

  closeResult!: string;
  selectedEnvironment: string = '';
  selectedAppName: string = '';
  selectedSecretName: string = '';
  selectedAppType: string = '';
  appNames!: any[];
  environments!: any[];
  isLoading$!: Observable<boolean>;
  userRole: any;
  private modalRef: any; // Holds the reference to the currently open modal

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public service: CustomerDataService,
    private router: Router,
    private datePipe: DatePipe,
    private clipboard: Clipboard,
    private deleteCustomerService: DeleteCustomerService,
    private modalService: NgbModal, // NgbModal service
    private toastr: ToastrService
  ) {
    this.customer$ = service.customer$;
    this.total$ = service.total$;
    this.isLoading$ = service.loading$;
  }

  ngOnInit(): void {
    this.environments = getProcessedEnvironments();
    this.appNames = getAppName();
    this.userRole = getUserRole();
    this.customer$ = this.service.customer$;
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  showSecretDetails(secretName: string, createdDate: string, applicationType: string): void {
    const formattedDate = this.datePipe.transform(createdDate, 'dd-MM-yyyy');
    this.router.navigate([
      '/customer/detail/',
      {
        secretName: secretName,
        createdDate: formattedDate,
        applicationType: applicationType
      }
    ]);
  }

  public parseSecret(secretName: string) {
    const parts = secretName.split('_');
    if (parts.length < 4) {
      console.error('Unexpected secret name format:', secretName);
      return {
        secretName: secretName,
        organizationName: '',
        customerId: '',
        appName: '',
        environment: '',
        createdDate: ''
      };
    }

    const organizationName = parts[0];
    const customerId = parts.slice(1, -2).join('_');
    const appName = parts[parts.length - 2];
    const environment = parts[parts.length - 1];

    return {
      secretName: secretName,
      organizationName,
      customerId,
      appName,
      environment
    };
  }

  openMyModal(content: any, secretName: string) {
    this.selectedSecretName = secretName;
    // if (!secretName) {
    //   console.error('Error: secretName is undefined');
    // }
    // document.querySelector('#modal-16')?.classList.add('md-show');
    this.modalRef = this.modalService.open(content, { centered: true });
  }

  onAddNewCustomer() {
    this.router.navigate(['/create/new/customer']);
  }

  confirmDelete(secretName: string, recoveryWindowInDays: string = '7'): void {
    this.deleteCustomerService.deleteSecret(secretName, recoveryWindowInDays).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.handleDeleteSuccess(secretName); // Handle successful deletion
        } else {
          this.toastr.error(response.message, 'Error');
        }
      },
      error: (err) => {
        this.toastr.error(err, 'Error');
      },
      complete: () => {}
    });
  }

  private handleDeleteSuccess(secretName: string): void {
    this.removeCustomerFromLocalStorage(secretName); // Remove from local storage
    this.service.searchTerm = '';
    this.service.refreshList(); // Refresh the customer list
    if (this.modalRef) {
      this.modalRef.close(); // Close the modal
    }
    this.toastr.success('Customer Secret Deleted Successfully.', 'Success'); // Show success message
  }

  private removeCustomerFromLocalStorage(secretName: string): void {
    const customerList = JSON.parse(localStorage.getItem('customer-list') || '[]');
    const updatedCustomerList = customerList.filter((customer: { name: string }) => customer.name !== secretName);
    localStorage.setItem('customer-list', JSON.stringify(updatedCustomerList));
  }

  refreshList(): void {
    this.service.refreshList();
  }

  onFilterChange(): void {
    if (this.selectedEnvironment == 'dev') {
      this.service.secretFilter = '_dev';
    }
    if (this.selectedEnvironment == 'test') {
      this.service.secretFilter = '_test';
    }
    if (this.selectedEnvironment == 'staging') {
      this.service.secretFilter = '_staging';
    }
    if (this.selectedEnvironment == 'prod') {
      this.service.secretFilter = '_prod';
    }

    this.service.appType = this.selectedAppType.toLowerCase();
    this.service.appName = this.selectedAppName.toLowerCase(); // assuming this is another dropdown
    this.service.environment = this.selectedEnvironment.toLowerCase(); // assuming this is another dropdown
    this.service.page = 1; // Reset to the first page
  }

  closeMyModal(event: {
    target: { parentElement: { parentElement: { parentElement: { classList: { remove: (arg0: string) => void } } } } };
  }) {
    event.target.parentElement.parentElement.parentElement.classList.remove('md-show');
  }

  copySecretName(secretName: string): void {
    this.clipboard.copy(secretName);
  }

  editSecretName(secretName: string): void {
    this.router.navigate([
      '/create/new/customer',
      {
        secretName: secretName
      }
    ]);
  }
}
