<nav class="">
  <div class="navbar-wrapper">
    <div class="m-header">
      <a (click)="returnHome()" class="b-brand">
        @if (themeMode === 'compact') {
          <img src="assets/images/logo/thynk-colour.svg" alt="small-menu-logo" class="logo logo-sm" />
        } @else {
          <img src="assets/images/logo/thynk-colour.svg" alt="logo" class="logo logo-lg" />
        }
      </a>
    </div>
    <app-nav-content
      (NavCollapsedMob)="navCollapseMob()"
      (SubmenuCollapse)="navSubmenuCollapse()"
      class="scroll-div w-100 compact"
    ></app-nav-content>
  </div>
</nav>
