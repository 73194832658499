import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({providedIn: 'root'})
export class AuthService
{
    serverUrl = environment.apiUrl;
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    set environment(env: string)
    {
        localStorage.setItem('environment', env);
    }

    get environment(): string
    {
        return localStorage.getItem('environment') ?? '';
    }

    set userRole(role: string)
    {
        localStorage.setItem('role', role);
    }

    get userRole(): string
    {
        return localStorage.getItem('role') ?? '';
    }

    set appName(app: string)
    {
        localStorage.setItem('appName', app);
    }

    get appName(): string
    {
        return localStorage.getItem('appName') ?? '';
    }

    set userName(user: string)
    {
        localStorage.setItem('userName', user);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { userName: string; password: string }): Observable<any>
    {
        const url = this.serverUrl + 'login'
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
        return this._httpClient.post(url, credentials).pipe(
            catchError((error: any) => {
                // Handle the error here (e.g., log it or show a user-friendly message)
                console.error('Login request failed:', error);
                return throwError('Login failed. Please try again later.');
            }),
            switchMap((response: any) =>
            {
                // Store the access token in the local storage
                this.accessToken = response.body;
                this.environment = response.environment;
                this.userRole = response.role;
                this.appName = response.app;
                this.userName = credentials.userName;

                // Set the authenticated flag to true
                this._authenticated = true;
               
                 // Check if any critical response values are null
                 if (!response.body || !response.environment || !response.role || !response.app) {
                    localStorage.clear();
                    this._authenticated = false;
                    return throwError('Invalid server response. Please try again later.');
                }

                // Store the user on the user service
                //  this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>
                
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
                {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if ( response.accessToken )
                        {
                            this.accessToken = response.body;
                        }
                        
                        // Set the authenticated flag to true
                        this._authenticated = true;
                        
                        // Store the user on the user service
                        this._userService.user = response.user;
                        
                        // Return true
                        return of(true);
                    }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('environment');
        localStorage.removeItem('role');
        localStorage.removeItem('appName');
        localStorage.removeItem('userName');
        localStorage.removeItem('logs');
        localStorage.removeItem('parsedLogDetails');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        //return this.signInUsingToken();
        return  of(true);
    }
}
