import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudwatchlogsService {
  serverUrl = environment.apiUrl;

  private apiUrl = this.serverUrl + 'logs';

  constructor(private http: HttpClient) {}

  getLogs(logGroupName: string, filterPattern: string, day: number): Observable<any> {
    const body = {
      logGroupName: logGroupName,
      filterPattern: filterPattern,
      day: day
    };
    return this.http.post(this.apiUrl, body);
  }
}
