import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LambdalogsService {
  serverUrl = environment.apiUrl;

  private apiUrl = this.serverUrl + 'lambdaLogs';

  constructor(private http: HttpClient) {}

  getLogs(logGroupName: string, filterPattern: string, filterPatternForMessage: string, day: number): Observable<any> {
    const body = {
      logGroupName: logGroupName,
      filterPattern: filterPattern,
      filterPatternForMessage: filterPatternForMessage,
      day: day
    };
    return this.http.post<any>(this.apiUrl, body);
  }

  createLogGroupName(url: string): string {
    const urlParts = url.split('/');
    const logGroupName = '/ecs-' + urlParts[urlParts.length - 1];
    return logGroupName;
  }
}
