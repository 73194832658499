<div class="auth-main">
  <div class="auth-wrapper v2">
    <div class="auth-form">
      <div class="logo">
        @if (themeMode === false) {
        <img src="assets/images/logo/thynk-colour.svg" alt="logo-img" style="width: 10%;" />
        } @else {
        <img src="assets/images/logo/thynk-colour.svg" alt="darkMode-logo" />
        }
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="auth-header">
                <h2 class="text-secondary"><b>Hi, Welcome Back</b></h2>
                <p class="f-16 mt-2">Enter your credentials to continue</p>
              </div>
            </div>
          </div>
          @if (error) {
            <div class="alert alert-danger mt-3 mb-3">{{ error }}</div>
            }
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-floating mb-3">
              <input type="userName" class="form-control" [(ngModel)]="usernameValue" formControlName="userName" id="floatingInputValue" placeholder=""
                [ngClass]="{ 'is-invalid': submitted && formValues?.['userName'].errors }" />
              <label for="floatingInputValue">Username</label>
              @if (submitted && formValues?.['userName'].errors) {
              <div class="invalid-feedback">
                @if (formValues?.['userName'].errors.required) {
                <div>Username is Required</div>
                }
              </div>
              }
            </div>
            <div class="form-floating mb-3">
              <input
                type="password"
                class="form-control"
                id="Password"
                placeholder=""
                formControlName="password"
                [(ngModel)]="userPassword"
                [ngClass]="{ 'is-invalid': submitted && formValues?.['password'].errors }"
              />
              <label for="Password">Password</label>
              <i class="ti ti-eye" id="togglePassword" style="cursor: pointer; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); font-size: 18px;"></i>
              @if (submitted && formValues?.['password'].errors) {
                <div class="invalid-feedback">
                  @if (formValues?.['password'].errors.required) {
                    <div>Password is Required</div>
                  }
                </div>
              }
            </div>
            <div class="d-grid mt-4">
              <button class="btn btn-secondary">Login</button>
            </div>
           
          </form>
        </div>
      </div>
    </div>
    <div class="auth-sideContent">
      <div class="p-3 px-lg-5 text-center">
        <ngb-carousel class="carousel-dark" [showNavigationArrows]="false" [showNavigationIndicators]="true">
          <ng-template ngbSlide>
            <h1><b>Welcome to Thynk Cloud</b></h1>
            <p class="f-12 mt-4">Thynk is a native multi-property, customer-centric, all-in-one open platform built by hoteliers for hoteliers to manage Sales & Catering with optimal efficiency.
            </p>
          </ng-template>
        </ngb-carousel>
        <img src="assets/images/authentication/login2.svg" alt="images" class="img-fluid mt-3 w-75" />
      </div>
    </div>
  </div>
</div>