// angular import
import { Injectable, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

// rxjs import
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, delay, finalize, switchMap, tap } from 'rxjs/operators';

// project import
import { customer } from './customer';
import { CUSTOMERS } from './customer-data';
import { SortDirection } from '../../../../../theme/shared/directive/sortable.directive';
import { FetchCustomerListService } from 'src/services/fetch-customer-list.service';
import { getAccessToken, getAppName, getProcessedEnvironments, getUserRole } from 'src/app/theme/shared/utility';

interface SearchResult {
  customer: customer[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
  appType: string;
  appName: string; // new field for appName
  environment: string;
  secretFilter: string;
}

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService {
  private _loading$ = new BehaviorSubject<boolean>(false);
  private _search$ = new Subject<void>();
  private _customers$ = new BehaviorSubject<customer[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  accessToken = getAccessToken();
  userRole: any;
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    appType: 'all',
    appName: 'all',
    environment: 'all',
    secretFilter: ''
  };

  // constructor
  constructor(
    private pipe: DecimalPipe,
    public customerListService: FetchCustomerListService
  ) {
    this.userRole = getUserRole();
    if (this.userRole == 'support') {
      this.secretFilter = '_staging';
    } else {
      this.secretFilter = '_dev';
    }
    this._search$
      .pipe(
        tap(() => this._setLoadingState(true)), // Start loading
        debounceTime(200),
        switchMap(() => this._search()),
        finalize(() => this._setLoadingState(false)) // End loading
      )
      .subscribe((result) => {
        this._customers$.next(result.customer);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get loadings$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  private _setLoadingState(isLoading: boolean): void {
    this._loading$.next(isLoading);
  }

  get customer$() {
    return this._customers$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  set page(page: number) {
    this._set({ page });
  }
  get pageSize() {
    return this._state.pageSize;
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  set appType(appType: string) {
    this._set({ appType: appType || 'all' });
  }

  set appName(appName: string) {
    this._set({ appName: appName || 'all' });
  }

  set environment(environment: string) {
    this._set({ environment: environment || 'all' });
  }

  get secretFilter() {
    return this._state.secretFilter;
  }
  set secretFilter(secretFilter: string) {
    this._set({ secretFilter });
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm, appType, appName, environment } = this._state;

    return this.fetchCustomers().pipe(
      tap(() => this._setLoadingState(true)),
      switchMap((result) => {
        let customers = result.customer;

        // 1. Sort
        customers = this.sortCustomers(customers, sortColumn, sortDirection);

        // 2. Filter by search term
        customers = this.filterCustomersBySearchTerm(customers, searchTerm);

        // 3. Filter by environment
        if (environment.toLowerCase() !== 'all') {
          this.fetchCustomers();
        }

        // 4. Additional filters (appName and appType)
        if (appName.toLowerCase() !== 'all') {
          customers = this.filterCustomersByAppName(customers, appName);
        }

        if (appType.toLowerCase() !== 'all') {
          customers = customers.filter((customer) => customer.applicationType.toLowerCase() === appType.toLowerCase());
        }

        const total = customers.length;

        // 5. Paginate
        customers = customers.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

        return of({ customer: customers, total });
      }),
      finalize(() => this._setLoadingState(false))
    );
  }

  private filterCustomersByEnvironment(customers: customer[], environment: string): customer[] {
    return customers.filter((customer) => new RegExp(`_${environment}$`, 'i').test(customer.name));
  }

  // Optional filter functions for appName and appType
  private filterCustomersByAppName(customers: customer[], appName: string): customer[] {
    return customers.filter((customer) => {
      const appData = customer.secretKeyAWS[appName.toLowerCase() as keyof typeof customer.secretKeyAWS];
      let parsedData: any[] = [];

      if (typeof appData === 'string' && appData.trim() !== '') {
        try {
          parsedData = JSON.parse(appData);
        } catch (error) {
          console.error(`Failed to parse ${appName} data:`, error);
        }
      } else if (Array.isArray(appData)) {
        parsedData = appData;
      }

      return Array.isArray(parsedData) && parsedData.length > 0;
    });
  }

  private filterCustomersByAppAndEnv(customers: customer[], appName: string, environment: string): customer[] {
    return customers.filter((customer) => {
      // Access app data dynamically based on appName if specified
      const appData =
        appName.toLowerCase() !== 'all' ? customer.secretKeyAWS[appName.toLowerCase() as keyof typeof customer.secretKeyAWS] : null;

      // Initialize parsedData as an empty array
      let parsedData: any[] = [];

      // Parse appData if it's a string and appName is specified
      if (appData && typeof appData === 'string' && appData.trim() !== '') {
        try {
          parsedData = JSON.parse(appData);
        } catch (error) {
          console.error(`Failed to parse ${appName} data:`, error);
        }
      } else if (Array.isArray(appData)) {
        parsedData = appData;
      }

      // Filter by environment if specified (not "all")
      const environmentMatches =
        environment.toLowerCase() === 'all' || new RegExp(`_${appName.toLowerCase()}_${environment}$`, 'i').test(customer.name);

      // Only return customers where environment matches and parsedData is non-null and non-empty
      return environmentMatches && (appName.toLowerCase() === 'all' || (Array.isArray(parsedData) && parsedData.length > 0));
    });
  }

  private sortCustomers(customers: customer[], sortColumn: string, sortDirection: string): customer[] {
    return sort(customers, sortColumn, sortDirection);
  }

  private filterCustomersBySearchTerm(customers: customer[], searchTerm: string): customer[] {
    return customers.filter((customer) => matches(customer, searchTerm, this.pipe));
  }

  private fetchCustomers(): Observable<SearchResult> {
    return new Observable<SearchResult>((observer) => {
      const envFilter = localStorage.getItem('envFilter');
      if (envFilter != this._state.environment) {
        localStorage.removeItem('customer-list');
        const localData = localStorage.getItem('customer-list');
        if (localData) {
          // If data exists in local storage, return it immediately
          const customerData: customer[] = JSON.parse(localData);
          observer.next({ customer: customerData, total: customerData.length });
          observer.complete();
        } else {
          // If no local data, fetch from API and wait for the result
          this.fetchCustomerFromAPI()
            .pipe(
              tap((customerData) => {
                // Store the data in localStorage after fetching from API
                localStorage.setItem('customer-list', JSON.stringify(customerData));
                localStorage.setItem('envFilter', this._state.environment);
              }),
              switchMap((customerData) => {
                // Emit the fetched data
                return of({ customer: customerData, total: customerData.length });
              }),
              catchError((error) => {
                console.error('Failed to fetch customer data from API:', error);
                observer.error(error); // Emit the error to the observer
                return of({ customer: [], total: 0 }); // Return an empty result on error
              })
            )
            .subscribe((result) => {
              observer.next(result);
              observer.complete();
            });
        }
      } else {
        const localData = localStorage.getItem('customer-list');
        if (localData) {
          // If data exists in local storage, return it immediately
          const customerData: customer[] = JSON.parse(localData);
          observer.next({ customer: customerData, total: customerData.length });
          observer.complete();
        }
      }
    });
  }

  refreshList() {
    this._search$.next();
  }

  // Modified fetchCustomerFromAPI to return an observable of customer array
  private fetchCustomerFromAPI(): Observable<customer[]> {
    const accessToken = this.accessToken; // Obtain the access token as needed

    // Check if accessToken is valid
    if (!accessToken) {
      console.error('Access token is null or undefined.');
      return of([]); // Return an empty array observable
    }

    // Fetch data from the API
    return this.customerListService.getSecrets(accessToken, this.secretFilter).pipe(
      switchMap((secrets) => {
        try {
          if (secrets && secrets.body) {
            // Map the incoming data to match the customer interface
            const customerData: customer[] = secrets.body.map((item: any) => ({
              name: item.name || '',
              createdDate: item.createdDate || '',
              applicationType: item.applicationType || '',
              secretKeyAWS: item.secretKeyAWS || ''
            }));
            return of(customerData); // Emit the mapped data as an observable
          } else {
            console.error('Response body is missing or empty.');
            return of([]); // Emit an empty array if the response is invalid
          }
        } catch (error) {
          console.error('Error occurred while processing response:', error);
          return of([]); // Emit an empty array if parsing fails
        }
      }),
      catchError((error) => {
        console.error('An error occurred while fetching secrets:', error);
        return of([]); // Emit an empty array if an error occurs
      })
    );
  }
}

// eslint-disable-next-line
function sort(customers: any, column: string, direction: string): customer[] {
  if (direction === '' || column === '') {
    return customers;
  } else {
    return [...customers].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(customer: customer, term: string, pipe: PipeTransform) {
  return (
    customer.name.toLowerCase().includes(term.toLowerCase()) ||
    customer.createdDate.toLowerCase().includes(term.toLowerCase()) ||
    customer.applicationType.toLowerCase().includes(term.toLowerCase())
    // customer.status.toLowerCase().includes(term.toLowerCase()) ||
    // customer.registered.toLowerCase().includes(term.toLowerCase()) ||
    // pipe.transform(customer.orders).includes(term)
  );
}
